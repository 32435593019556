import Box from 'components/Box';
import Button from 'components/Button';
import Logo from 'components/Logo';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onProceed: () => void;
}

const SplashScreen = ({ onProceed }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" height="full">
      <TopBar />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="large"
        paddingBottom="xlarge"
      >
        <Box paddingBottom="xlarge" width="full" display="flex" justifyContent="center">
          <Logo full />
        </Box>

        <Stack spacing="large" align="center">
          <Text color="white" fontSize="small" textAlign="center">
            {t('Welcome, are you ready to become the next Quiz Master?')}
          </Text>

          <Button onClick={onProceed}>{t('Start')}</Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default SplashScreen;
