import { RegistrationActions } from 'actions/registrationActions';
import { UserActions } from 'actions/userActions';
import { Reducer } from 'redux';

export interface RegistrationReducer {
  registrationDone: boolean;
  notVerified: boolean;
}

export const initialState: RegistrationReducer = {
  registrationDone: false,
  notVerified: false,
};

export const registrationReducer: Reducer<
  RegistrationReducer,
  RegistrationActions | UserActions
> = (state = initialState, action) => {
  switch (action.type) {
    case '@registration/SET_REGISTRATION_DONE':
      return {
        ...state,
        registrationDone: true,
      };

    case '@registration/SET_NOT_VERIFIED':
      return {
        ...state,
        notVerified: action.payload,
      };

    case '@user/CLEAR_USER':
      return initialState;

    default:
      return state;
  }
};
