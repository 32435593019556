import Box from 'components/Box';
import { cx } from 'cx';
import { PickStyleProps, StyleProps } from 'hooks/useStyles';
import React, { forwardRef } from 'react';
import './Card.css';

interface Props
  extends PickStyleProps<
    'display' | 'flexDirection' | 'alignItems' | 'justifyContent' | 'padding'
  > {
  backgroundColor?: StyleProps['backgroundColor'];
  children: React.ReactNode;
  className?: string;
  shadow?: 'small' | 'medium' | 'strong' | 'heavy';
  borderRadius?: 'medium' | 'large';
  onClick?: () => void;
}

const Card = forwardRef(
  (
    {
      children,
      backgroundColor = 'white',
      className,
      shadow = 'strong',
      borderRadius = 'large',
      padding = 'medium',
      ...restProps
    }: Props,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        className={cx(
          'Card',
          `shadow-${shadow}`,
          `borderRadius-${borderRadius}`,
          restProps.onClick && 'is-clickable',
          className,
        )}
        backgroundColor={backgroundColor}
        padding={padding}
        role={restProps.onClick ? 'button' : undefined}
        {...restProps}
      >
        {children}
      </Box>
    );
  },
);

Card.displayName = 'Card';

export default Card;
