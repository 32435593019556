import {
  setAudioSync,
  setMicrophoneEnabled,
  setMicrophoneEnabledErrored,
  startAudioSync,
  stopAudioSync,
} from 'actions/audioSyncActions';
import { signOutFacebook } from 'actions/bridgeActions';
import { setGlobalError } from 'actions/errorActions';
import { setStatus } from 'actions/gatekeeperActions';
import { loginOrRegisterByGoogle } from 'actions/interUserApiActions';
import { setReady, setSuspended, storeAuthenticationToken } from 'actions/nativeActions';
import { setPhotoData } from 'actions/photoActions';
import {
  clearSocialState,
  googleSignOut,
  hasFacebookPermission,
  setExternalUserId,
  setIsVerifying,
  setSocialErrored,
  setSocialSignInState,
  setSocialTriggered,
  signInSocialResponse,
  storeFriendIds,
} from 'actions/socialActions';
import { setSessionValidationState } from 'actions/userActions';
import { addExternalIdCredentials, updateProfile } from 'actions/usersApiActions';
import { AppStore } from 'config/store';
import { ThunkDispatch } from 'hooks/redux';
import { getIsVerifying } from 'selectors/socialSelectors';
import { isAuthenticated } from 'selectors/userSelectors';
import { Commands, Events, NativeWrapper } from 'types/bridge';
import { getErrorCode } from 'utils/error';
// import { trackEvent } from 'utils/analytics';

let commandId = 0;

// Dev only log utility
const log = (...args: any) => __DEV__ && console.log(...args);

declare global {
  interface Window {
    nativeWrapper?: NativeWrapper;
  }
}

const isNativeEnv = window.location.href.indexOf('wrapper=1') > -1;

const local: { store?: AppStore } = {};

export const connectBridge = async (store: AppStore) => {
  await bridgeReady();

  local.store = store;

  if (!isNativeEnv) {
    store.dispatch(setReady('web'));

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        store.dispatch(setSuspended(true));

        if (isAuthenticated(store.getState())) {
          store.dispatch(setSessionValidationState('SHOULD_VALIDATE'));
        }
      } else {
        store.dispatch(setSuspended(false));
      }
    });

    return;
  }

  // Let the native wrapper know we're ready.
  if (window.nativeWrapper) {
    sendMessage({ type: 'Event', subject: 'AppReady' });

    // Response of AppReady is unreliable, so when window.nativeWrapper exists, we're "ready"
    store.dispatch(setReady('native'));
  }
};

// Send a message to the wrapper.
export const sendMessageWeb = (message: Commands | Events) => {
  switch (message.subject) {
    case 'BrowserOpen':
      window.open(message.payload.url, '_blank');
      break;
    case 'FacebookShare':
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${message.payload.contentURL}`,
        '_blank',
      );
      break;
  }
};

// Track outgoing messages.
function trackMessage(message: Commands | Events) {
  switch (message.subject) {
    default:
      return;
    // case 'FacebookShare':
    //   trackEvent({
    //     category: 'bridge',
    //     action: 'facebook_share',
    //   });
    //   break;

    // case 'BrowserOpen':
    //   trackEvent({
    //     category: 'bridge',
    //     action: 'open_browser',
    //     label: message.payload.url,
    //   });
    //   break;
  }
}

export const sendMessage = (message: Commands | Events) => {
  if (window.nativeWrapper) {
    log(`Sending "${message.subject}"`, message);
    window.nativeWrapper.sendMessage(
      message.type === 'Command' ? { ...message, commandId: String(commandId++) } : message,
    );
  } else {
    sendMessageWeb(message);
  }

  trackMessage(message);
};

export const bridge = {
  sendMessage,
};

const onMessage: NativeWrapper['onMessage'] = (message) => {
  log(`Receiving "${message.subject}": `, message);

  if (!local.store) {
    log(`Bridge call "${message.subject}" while not yet ready.`);
    return;
  }

  const { store } = local;
  const dispatch: ThunkDispatch = store.dispatch;
  const handleSignInError = (errorCode?: string) => {
    dispatch(clearSocialState());
    dispatch(setSocialTriggered(null));

    if (errorCode) {
      dispatch(setSocialErrored(errorCode));
    }
  };

  switch (message.subject) {
    case 'AppPause':
      dispatch(setSuspended(true));

      if (isAuthenticated(store.getState())) {
        dispatch(setSessionValidationState('SHOULD_VALIDATE'));
      }
      return;
    case 'AppResume':
      dispatch(setSuspended(false));
      return;
    case 'AudioSync':
      dispatch(setAudioSync(message.payload));
      return;
    case 'GatekeeperReady':
      dispatch(setStatus('GOOD'));
      return;
    case 'GetBatteryLevel':
      return;
    case 'GetNetworkStatus':
      return;
    case 'RequestMicrophoneAccess':
    case 'RequestMicrophoneAccessDenied':
      if (message.error) {
        dispatch(setMicrophoneEnabledErrored(true));
        return dispatch(setGlobalError('RequestMicrophoneAccessFailed'));
      } else {
        dispatch(setMicrophoneEnabledErrored(false));
      }

      dispatch(setMicrophoneEnabled(message.error ? false : true));
      return;
    case 'StartAudioSync':
      dispatch(startAudioSync());
      return;
    case 'StopAudioSync':
      dispatch(stopAudioSync());
      return;
    case 'WrapperPropertyUpdated':
      // TODO check payload.name and update what's necessary.
      return;
    case 'FacebookLogin': {
      if (
        (message.payload?.declinedPermissions && message.payload?.declinedPermissions.length > 0) ||
        message.error
      ) {
        return handleSignInError();
      }

      if (message.payload?.isCancelled) {
        dispatch(signOutFacebook());
        handleSignInError();
        return;
      }

      if (!message.payload?.token) {
        return dispatch(setSocialSignInState('NO_USER_FOUND'));
      }

      const { token } = message.payload;

      dispatch(setSocialSignInState('GET_GRAPH'));
      return dispatch(signInSocialResponse({ token }));
    }
    case 'FacebookGraphRequest': {
      if (!message.payload) {
        return dispatch(setGlobalError('FacebookGraphRequestFailed'));
      }

      const { id, permissions, friends, first_name, last_name } = message.payload;

      if (permissions && friends) {
        if (!hasFacebookPermission({ permissions, permission: 'user_friends' })) {
          throw new Error('Missing friends permission');
        }

        if (friends) {
          const friendIds = friends.data.map((friend) => friend.id);
          dispatch(storeFriendIds(friendIds));
        }

        return;
      }

      if (id) {
        dispatch(setSocialSignInState('SIGN_IN'));
        dispatch(setExternalUserId(id));

        const {
          user: { profile },
        } = store.getState();

        if (profile) {
          return;
        }

        dispatch(
          updateProfile({
            firstName: first_name,
            lastName: last_name,
          }),
        );
      }

      return;
    }
    case 'GoogleSignIn': {
      if (message.error) {
        return handleSignInError(
          message.error.code !== 'SignInErrorCodeCanceled' ? message.error.code : undefined,
        );
      }

      const handleGoogleSignIn = async () => {
        try {
          const { userId, email, name } = message.payload;

          if (getIsVerifying(store.getState())) {
            dispatch(setIsVerifying(false));

            await dispatch(
              addExternalIdCredentials({
                type: 'GOOGLE',
                externalId: userId,
                displayName: name,
              }),
            );
          } else {
            await dispatch(loginOrRegisterByGoogle({ googleId: userId, email, displayName: name }));
          }
        } catch (error: any) {
          const code = getErrorCode(error);
          dispatch(googleSignOut());
          handleSignInError(code);
        }
      };

      handleGoogleSignIn();

      return;
    }
    case 'SelectPhoto': {
      const { base64 } = message.payload;

      dispatch(setPhotoData(base64));
      return;
    }
    case 'TakePhoto': {
      const { base64 } = message.payload;

      dispatch(setPhotoData(base64));
      return;
    }
    case 'StorageGetItem': {
      dispatch(storeAuthenticationToken(message.payload.value));
      return;
    }
    default:
      return log(`Unhandled message "${message.subject}"`);
  }
};

const bridgeReady = () =>
  new Promise<void>((resolve) => {
    if (!isNativeEnv) {
      resolve();
    }

    // Give bridge 6s to resolve
    const timeoutAt = Date.now() + 6000;

    const checkForNativeWrapper = () => {
      if (window.nativeWrapper) {
        window.nativeWrapper.onMessage = onMessage;
        return resolve();
      }

      if (Date.now() >= timeoutAt) {
        return resolve();
      }

      window.setTimeout(checkForNativeWrapper, 50);
    };

    checkForNativeWrapper();
  });
