import { addEmailCredentials } from 'actions/interUserApiActions';
import Box from 'components/Box';
import FlatButton from 'components/FlatButton';
import { Logins } from 'components/Logins';
import Panel from 'components/Panel';
import { SignInForm } from 'components/SignInForm';
import { SignInDialogProps } from 'components/SignInForm/SignInForm';
import SignOutDialog from 'components/SignOutDialog';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getShouldConfirmAuthenticationInformation,
  getStoredProfile,
} from 'selectors/userSelectors';
import { getErrorCode } from 'utils/error';

export const VerifyAccount: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [firstItemLocked, setFirstItemLocked] =
    useState<ReturnType<typeof getShouldConfirmAuthenticationInformation>>();
  const [screen, setScreen] = useState<'email' | 'default'>('default');
  const [dialog, setDialog] = useState<SignInDialogProps | false>(false);
  const [showSignOutDialog, setShowSignOutDialog] = useState(false);
  const profile = useSelector(getStoredProfile);
  const confirmAuthenticationFirstItem = useSelector(getShouldConfirmAuthenticationInformation);

  useEffect(() => {
    if (!firstItemLocked) {
      setFirstItemLocked(confirmAuthenticationFirstItem);
    }
  }, [confirmAuthenticationFirstItem]);

  const onSubmit = async (formData: any) => {
    try {
      await dispatch(addEmailCredentials(formData));
    } catch (error: any) {
      const code = getErrorCode(error);

      switch (code) {
        case 'EMAIL_NOT_UNIQUE': {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Email is already in use.'),
          });
        }
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }
  };

  return (
    <>
      <TopBar title={t('Verify Account')} />
      <Panel>
        <Box height="full" display="flex" flexDirection="column">
          <Stack spacing="xlarge" paddingX="gutter">
            <Stack spacing="small">
              <Text color="secondary" fontSize="xlarge" fontWeight="bold" textAlign="center">
                {screen === 'email' ? t('Confirm Email Information') : t('Action Required')}
              </Text>
              <Text fontSize="small" textAlign="center">
                {screen === 'email'
                  ? t('Please enter your password.')
                  : t('Please confirm your login information.')}
              </Text>
            </Stack>
            <Stack spacing="medium">
              {screen === 'email' ? (
                <SignInForm
                  email={profile.email || ''}
                  onSubmit={onSubmit}
                  dialog={dialog}
                  setDialog={setDialog}
                  isConfirm
                />
              ) : (
                firstItemLocked &&
                firstItemLocked !== 'confirmed' && (
                  <Logins firstItem={firstItemLocked} emailTrigger={() => setScreen('email')} />
                )
              )}
              <Stack align="center" spacing="large">
                {screen === 'email' ? (
                  <FlatButton onClick={() => setScreen('default')}>{t('Back')}</FlatButton>
                ) : (
                  <FlatButton onClick={() => setShowSignOutDialog(true)}>
                    {t('Sign Out')}
                  </FlatButton>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Panel>
      <SignOutDialog
        showSignOutDialog={showSignOutDialog}
        setShowSignOutDialog={setShowSignOutDialog}
        showWarning
      />
    </>
  );
};
