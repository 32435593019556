import { useReducer } from 'react';

const sessionStore = new Map();

/**
 * Clear session store (will run after tests)
 */
export function clearSessionStore() {
  sessionStore.clear();
}

/**
 * Persists state
 *
 * const [state, setState] = useSessionState('component-id', initialState);
 */
export function useSessionState<S>(key: string, initialState: S): [S, (state: S) => void] {
  const [, forceRender] = useReducer((s) => s + 1, 0);

  if (!sessionStore.has(key)) {
    sessionStore.set(key, initialState);
  }

  function setState(state: S) {
    sessionStore.set(key, state);
    forceRender();
  }

  return [sessionStore.get(key), setState];
}
