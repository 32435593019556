import { AppState } from 'reducers';
import { isIOSWrapper } from 'utils/platform';
import { getAuthenticationToken } from './userSelectors';

export const isNativeReady = (state: AppState) => state.native.ready;
export const getNativeEnv = (state: AppState) => state.native.env;
export const getNativeSuspended = (state: AppState) => state.native.suspended;
export const getStoredAuthenticationToken = (state: AppState) =>
  isIOSWrapper
    ? state.native.storedAuthenticationToken
    : ({ status: 'FETCHED' } as AppState['native']['storedAuthenticationToken']);
export const getParsedAuthenticationToken = (state: AppState) => {
  const authenticationToken = getAuthenticationToken(state);
  const storedAuthenticationToken = getStoredAuthenticationToken(state);
  return authenticationToken || storedAuthenticationToken.token;
};
