import Box from 'components/Box';
import Button from 'components/Button';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  playWithAverage: () => void;
  retry: () => void;
  tries: number;
}

const AudioSyncTimeoutScreen: FC<Props> = ({ playWithAverage, retry, tries }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="xlarge" align="center">
      <Box paddingX="gutter">
        <Text
          component="h1"
          color="secondary"
          fontWeight="bold"
          fontSize="xlarge"
          textAlign="center"
        >
          {t('Audio synchronization failed')}
        </Text>
      </Box>

      <Stack spacing="medium" align="center">
        <Text color="white" fontSize="large" fontWeight="semiBold">
          {t('Please try again.')}
        </Text>
        <Text component="p" color="white" fontSize="small" textAlign="center">
          {t(
            'If the synchronization does not work, click on the button “Play along without synchronization” (It can happen that the questions in the app are then delayed in relation to the TV signal.)',
          )}
        </Text>
      </Stack>

      <Stack spacing="medium" align="center">
        <Button onClick={retry}>{t('Synchronize')}</Button>
        {tries > 0 && (
          <FlatButton onClick={playWithAverage}>
            {t('Play along without synchronization')}
          </FlatButton>
        )}
      </Stack>
    </Stack>
  );
};

export default AudioSyncTimeoutScreen;
