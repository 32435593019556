import AppShell from 'components/AppShell';
import React from 'react';
import ReactDOM from 'react-dom';
import RootScreen from 'screens/Root';

const mountNode = document.getElementById('root');

ReactDOM.render(
  <AppShell>
    <RootScreen />
  </AppShell>,
  mountNode,
);

// Scroll to active input whenever viewport changes size
// This is most useful on mobile devices, input is not (always) visible after opening the virtual keyboard.
window.addEventListener('resize', () => {
  if (document.activeElement && document.activeElement.nodeName === 'INPUT') {
    document.activeElement.scrollIntoView({ block: 'center' });
  }
});
