import { Route } from '@blockle/router';
import React from 'react';
import ProfileOverviewDashboardScreen from './ProfileOverviewDashboardScreen';
import ProfileOverviewEditScreen from './ProfileOverviewEditScreen';

const StatisticsScreen = () => (
  <>
    <Route path="/" render={(match) => match && <ProfileOverviewDashboardScreen />} exact />
    <Route path="/edit" render={(match) => match && <ProfileOverviewEditScreen />} exact />
  </>
);

export default StatisticsScreen;
