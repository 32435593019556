import { useRaf } from 'hooks/useRaf';
import React, { FC, useRef } from 'react';
import { getClientTime } from 'utils/clientTime';
import './NextQuestionTimeBar.css';

const DEVICE_RATIO = window.devicePixelRatio || 1;

interface UpdateCanvasOptions {
  ref: React.RefObject<HTMLCanvasElement>;
  startTime: number;
  endTime: number;
  position: number;
}

function updateCanvas({ ref, startTime, endTime, position }: UpdateCanvasOptions) {
  const width = 208 * DEVICE_RATIO;
  const height = width;
  const circleWidth = 8 * DEVICE_RATIO;
  const radius = width / 2 - circleWidth / 2;
  // const soundBars = [0.1, 0.3, 0.5, 0.4, 0.9, 0.8, 0.5, 0.4, 0.3, 0.2];

  const now = getClientTime() + position;
  const passedTime = now - startTime;
  const progress = passedTime / (endTime - startTime);

  if (!ref.current) {
    return;
  }

  const canvas = ref.current;
  const context = canvas.getContext('2d');

  if (!context) {
    return;
  }

  context.clearRect(0, 0, width, height);

  // Circle background
  context.beginPath();
  context.lineWidth = circleWidth;
  context.strokeStyle = '#C7C3D9';
  context.arc(width / 2, height / 2, radius, -0.5 * Math.PI, 1.5 * Math.PI);
  context.stroke();
  context.closePath();

  // Circle progress
  const r = -0.5 + 2 * progress;

  context.beginPath();
  context.lineWidth = circleWidth;
  context.strokeStyle = '#e75a6b';
  context.lineCap = 'round';
  // Start at `-0.5 * PI` ends at `1.5 * PI`
  context.arc(width / 2, height / 2, radius, -0.5 * Math.PI, r * Math.PI);
  context.stroke();
  context.closePath();
}

interface Props {
  startTime: number;
  endTime: number;
  position: number;
}

const NextQuestionTimeBar: FC<Props> = ({ startTime, endTime, position }) => {
  const ref = useRef<HTMLCanvasElement>(null);

  // "Scale" canvas to device pixel ratio
  const width = 208 * DEVICE_RATIO;
  const height = width;

  const clientTime = getClientTime();

  const startTimeStamp = clientTime + startTime;
  const endTimeStamp = clientTime + endTime;
  const positionTimeStamp = clientTime - position;

  useRaf(() =>
    updateCanvas({
      ref,
      startTime: startTimeStamp,
      endTime: endTimeStamp,
      position: positionTimeStamp,
    }),
  );

  return <canvas ref={ref} className="NextQuestionTimeBar" width={width} height={height} />;
};

export default NextQuestionTimeBar;
