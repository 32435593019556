import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AgeGroups } from 'types/user';

interface Props {
  ageGroup: AgeGroups | undefined;
}

const AgeTranslate: FC<Props> = ({ ageGroup }) => {
  const { t } = useTranslation();

  const AGE_TRANSLATE_MAP = {
    '0TO12': t('under 13'),
    '13TO19': t('13 to 19 years'),
    '20TO39': t('20 to 39 years'),
    '40TO59': t('40 to 59 years'),
    '60PLUS': t('60 or older'),
  };

  if (!ageGroup || !AGE_TRANSLATE_MAP[ageGroup]) {
    return null;
  }

  return <>{AGE_TRANSLATE_MAP[ageGroup]}</>;
};

export default AgeTranslate;
