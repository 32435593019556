import { OnboardActions } from 'actions/onboardActions';
import { Reducer } from 'redux';

export interface OnboardReducer {
  onboardViewed: boolean;
}

export const initialState: OnboardReducer = {
  onboardViewed: false,
};

export const onboardReducer: Reducer<OnboardReducer, OnboardActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@onboard/SET_ONBOARD_VIEWED':
      return {
        ...state,
        onboardViewed: true,
      };
    default:
      return state;
  }
};
