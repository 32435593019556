import Box from 'components/Box';
import Stack from 'components/Stack';
import Text from 'components/Text';
import { cx } from 'cx';
import React, { FC } from 'react';
import './TextOverlay.css';

interface Props {
  text: string;
  title: string;
  isClosable: boolean;
  leave: boolean;
}

const TextOverlay: FC<Props> = ({ text, title, leave }) => (
  <Box component="dialog" open className={cx('TextOverlay-dialog', leave && 'is-leave')}>
    <Stack spacing="medium">
      <Text component="h2" color="secondary" fontSize="xlarge" textAlign="center">
        {title}
      </Text>
      <Text component="h2" color="white" fontSize="large" textAlign="center" fontWeight="medium">
        {text}
      </Text>
    </Stack>
  </Box>
);

export default TextOverlay;
