import { requestMicrophoneAccess } from 'actions/bridgeActions';
import Button from 'components/Button';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isIOS } from 'utils/platform';

interface Props {
  playWithAverage: () => void;
}

const AudioSyncDisabledScreen: FC<Props> = ({ playWithAverage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Stack spacing="xlarge" align="center">
      <Text component="h1" color="secondary" fontWeight="bold" fontSize="xlarge" textAlign="center">
        {t('The app will now try to synchronize with your TV!')}
      </Text>

      <Stack spacing="medium" align="center">
        <Text color="white" fontSize="large" fontWeight="semiBold">
          {t('Permission required')}
        </Text>
        <Text component="p" color="white" fontSize="small" textAlign="center">
          {t('In order to play along with your TV, we need your permission to record sound.')}
        </Text>
        {isIOS && (
          <Text component="p" color="white" fontSize="small" textAlign="center">
            {t(
              'Have you declined the permission before? Then go to Settings > Microphone > QuizMaster ... and give access to the microphone',
            )}
          </Text>
        )}
      </Stack>

      <Stack spacing="medium" align="center">
        <Button onClick={() => dispatch(requestMicrophoneAccess())}>{t('Give permission')}</Button>
        <FlatButton onClick={playWithAverage}>{t('Play along without audio sync')}</FlatButton>
      </Stack>
    </Stack>
  );
};

export default AudioSyncDisabledScreen;
