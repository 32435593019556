import { EpisodeActions } from 'actions/episodeActions';
import { Reducer } from 'redux';
import { StudioPlayer } from 'types/episodes';
import { normalize } from 'utils/normalize';

export type StudioPlayersReducer = Record<string, StudioPlayer>;

export const initialState: StudioPlayersReducer = {};

export const studioPlayersReducer: Reducer<StudioPlayersReducer, EpisodeActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@episode/SET_EPISODE': {
      if (action.payload?.studioPlayers) {
        return normalize(action.payload.studioPlayers, 'id');
      }

      return state;
    }

    case '@episode/UPDATE_EPISODE': {
      if (action.payload?.studioPlayers) {
        return normalize(action.payload.studioPlayers, 'id');
      }

      return state;
    }

    default:
      return state;
  }
};
