import { resolvePendingAnswers } from 'actions/answerActions';
import { ScheduleActions } from 'actions/episodeScheduleActions';
import { Dispatch } from 'redux';
import { getEpisodeCode } from 'selectors/episodeSelectors';
import { findStudioPlayerEventByEpisodeCode, getEvent } from 'selectors/eventsSelectors';
import { ThunkMiddlewareAPI } from './@types/redux';

export default (api: ThunkMiddlewareAPI) => (next: Dispatch) => (action: ScheduleActions) => {
  switch (action.type) {
    case '@episodeSchedule/SET_SCHEDULE': {
      const { currentEventId, currentEventPhase } = action.payload;
      if (!currentEventId || !currentEventPhase) break;

      const appState = api.getState();
      const episodeCode = getEpisodeCode(appState);
      const event = getEvent(appState, currentEventId);

      if (!episodeCode) break;

      switch (currentEventPhase.name) {
        case 'RESULTS': {
          if (!event) break;

          api.dispatch(
            resolvePendingAnswers({
              episodeCode,
              eventType: event.type,
            }),
          );
        }
        case 'SHOW': {
          if (event && event.type === 'ENDOFSHOW') {
            const studioPlayerEvent = findStudioPlayerEventByEpisodeCode(appState, episodeCode);

            if (!studioPlayerEvent) break;

            api.dispatch(
              resolvePendingAnswers({
                episodeCode,
                eventType: 'STUDIOPLAYERSELECTION',
              }),
            );
          }
        }
      }
      break;
    }
  }

  next(action);
};
