import Box from 'components/Box';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React from 'react';
import './Warning.css';

interface Props {
  children: React.ReactNode | string;
  show?: boolean;
}

const Warning = ({ children }: Props) => {
  return (
    <Stack spacing="small" horizontal align="center" paddingX="xsmall">
      <Box
        className="Warning-icon"
        backgroundColor="warning"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon name="warn" size="xsmall" label="" color="white" />
      </Box>
      <Text color="warning" fontSize="xsmall">
        {children}
      </Text>
    </Stack>
  );
};

export default Warning;
