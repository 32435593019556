import Button from 'components/Button';
import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  showDialog: boolean;
  onDialogClose: () => void;
  title?: string;
  showIcon?: boolean;
  message?: string;
  showCancel?: boolean;
  promptText: string;
  onPromptTrigger: () => void;
}

const PromptDialog = ({
  showDialog,
  onDialogClose,
  title,
  showIcon,
  message,
  showCancel,
  promptText,
  onPromptTrigger,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={showDialog}
      onRequestClose={onDialogClose}
      render={() => (
        <Stack spacing="large" align="center">
          {title && (
            <Text
              component="h1"
              color="secondary"
              fontSize="large"
              fontWeight="medium"
              textAlign="center"
            >
              {title}
            </Text>
          )}
          {message && (
            <Stack spacing="xsmall" horizontal>
              {showIcon && <Icon name="warn" size="large" color="incorrect" />}
              <Text color="black" fontSize="small" textAlign="center">
                {message}
              </Text>
            </Stack>
          )}
          <Stack spacing="xsmall" align="center">
            <Button onClick={onPromptTrigger}>{promptText}</Button>
            {showCancel && <FlatButton onClick={onDialogClose}>{t('Cancel')}</FlatButton>}
          </Stack>
        </Stack>
      )}
    />
  );
};

export default PromptDialog;
