import { pause, unpause } from 'actions/practiceScheduleActions';
import Box from 'components/Box';
import Button from 'components/Button';
import CategoryIcon from 'components/CategoryIcon';
import Coin from 'components/Coin';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import TopBar from 'components/TopBar';
import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getCategoryById } from 'selectors/categorySelectors';
import {
  getCategoryByEventId,
  getEvent,
  getQuestionIndexByEventId,
} from 'selectors/eventsSelectors';
import { getCategoryPointsByEventId } from 'selectors/practiceAnswerSelectors';

interface Props {
  episodeCode: string;
  eventId: string;
}

const NextQuestionInteraction: FC<Props> = ({ eventId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const points = useSelector((state: AppState) => getCategoryPointsByEventId(state, eventId));
  const event = useSelector((state: AppState) => getEvent(state, eventId));
  const { index, total } = useSelector((state: AppState) =>
    getQuestionIndexByEventId(state, eventId),
  );
  const category = useSelector((state: AppState) => getCategoryByEventId(state, eventId, true));
  const categoryFallback = useSelector((state: AppState) =>
    getCategoryById(state, category?.categoryId as string),
  );

  useEffect(() => {
    dispatch(pause());
  }, []);

  if (!event || !category) {
    return null;
  }

  return (
    <>
      <TopBar logo left={<MenuToggle />} right={<Points episodeCode="practice" />} />

      <Spacer spacing="small" />

      <Panel>
        <Stack spacing="xlarge" align="center">
          <Stack spacing="xsmall">
            <Text component="h1" textAlign="center" color="secondary" fontSize="xlarge">
              {t('Get ready')}
            </Text>
            <Text textAlign="center" fontSize="small">
              {t('Question {{index}} / {{total}}', { index, total })}
            </Text>
            <Box display="flex" justifyContent="center">
              <Tooltip>
                <Stack spacing="xsmall" horizontal align="center">
                  <Text fontSize="medium" color="black">
                    {t('Every correct answer brings')}
                  </Text>
                  <Coin size="medium" value={category.answerScore} />
                </Stack>
              </Tooltip>
            </Box>
          </Stack>

          <Stack spacing="xsmall" align="center">
            <CategoryIcon
              url={category.imageUrl || categoryFallback.imageUrl}
              title={category.text}
              size="large"
              color="red"
            />
            <Text color="secondary" fontSize="large">
              {category.text}
            </Text>
            <Text fontSize="medium">
              <Trans>You have {{ points }} points in this category</Trans>
            </Text>
          </Stack>

          <Button onClick={() => dispatch(unpause())}>Play</Button>
        </Stack>
      </Panel>
    </>
  );
};

export default NextQuestionInteraction;
