import Box from 'components/Box';
import { cx } from 'cx';
import { PickStyleProps } from 'hooks/useStyles';
import React from 'react';
import './Panel.css';

interface Props extends PickStyleProps<'flexGrow'> {
  children: React.ReactNode;
  className?: string;
  paddingTop?: boolean;
  variant?: 'white' | 'grey' | 'primary';
}

const Panel = ({
  children,
  className,
  paddingTop = true,
  variant = 'white',
  flexGrow = 1,
}: Props) => {
  return (
    <Box
      className={cx('Panel', `variant-${variant}`, className)}
      backgroundColor="white"
      flexGrow={flexGrow}
      paddingTop={paddingTop ? 'gutter' : undefined}
      overflow="hidden"
      display="flex"
      alignItems="stretch"
      width="full"
    >
      <Box className="Panel-content" overflow="auto" paddingBottom="gutter" width="full">
        {children}
      </Box>
    </Box>
  );
};

export default Panel;
