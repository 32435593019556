import { openBrowser, shareFacebook } from 'actions/bridgeActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  message: string;
}

const ShareButton: FC<Props> = ({ message }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(false);
  const contentUrl = 'http://www.servustv.com/quizmaster';

  return (
    <>
      <Button
        variant="white"
        icon={<Icon name="share" color="primary" />}
        onClick={() => setDialog(true)}
      >
        {t('Share')}
      </Button>
      <Dialog
        open={dialog}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack spacing="small">
              <Button
                variant="facebook"
                icon={<Icon name="facebook" label="" color="white" size="medium" />}
                width="full"
                onClick={() =>
                  dispatch(
                    shareFacebook({
                      appLinkURL: 'https://fb.me/1161819867188487',
                      contentDescription: message,
                      contentURL: 'http://www.servustv.com/quizmaster',
                    }),
                  )
                }
              >
                {t('Facebook')}
              </Button>
              <Button
                variant="twitter"
                icon={<Icon name="twitter" label="" color="white" size="medium" />}
                width="full"
                onClick={() =>
                  dispatch(
                    openBrowser(
                      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                        contentUrl,
                      )}&text=${encodeURIComponent(message)}`,
                    ),
                  )
                }
              >
                {t('Twitter')}
              </Button>
              <Button
                variant="whatsapp"
                icon={<Icon name="whatsapp" label="" size="medium" color="none" />}
                width="full"
                onClick={() =>
                  dispatch(
                    openBrowser(
                      `whatsapp://send?text=${encodeURIComponent(`${message} ${contentUrl}`)}`,
                    ),
                  )
                }
              >
                {t('Whatsapp')}
              </Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default ShareButton;
