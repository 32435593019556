import StudioPlayerInteraction from 'components/Interactions/StudioPlayerInteraction';
import React from 'react';
import { PhaseNames, StudioPlayerSelectionEvent } from 'types/events';

interface Props {
  episodeCode?: string;
  event?: StudioPlayerSelectionEvent;
  previewPhase: PhaseNames;
}

const PreviewStudioPlayerScreen = ({ episodeCode, event, previewPhase }: Props) => {
  if (!event || !episodeCode) return null;

  return (
    <StudioPlayerInteraction
      event={event}
      eventPhase={previewPhase}
      startTime={Date.now()}
      episodeCode={episodeCode}
    />
  );
};

export default PreviewStudioPlayerScreen;
