import { back, push } from 'actions/historyActions';
import { toggleMenu as toggleMenuAction } from 'actions/menuActions';
import IconButton from 'components/IconButton';
import React, { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';

type ToggleType = 'open-menu' | 'close-menu' | 'back';

type Props =
  | { toggleType?: 'open-menu' | 'close-menu'; pushLocation?: never }
  | { toggleType: 'back'; pushLocation?: string };

interface IconProps {
  iconName: ComponentProps<typeof IconButton>['name'];
  iconLabel: string;
  iconOnClick: () => void;
}

const MenuToggle = ({ toggleType = 'open-menu', pushLocation }: Props) => {
  const dispatch = useDispatch();
  const toggleMenu = () => dispatch(toggleMenuAction());
  const onBack = () => dispatch(back());
  const onPush = () => dispatch(push(pushLocation || '/'));

  const iconProps: { [key in ToggleType]: IconProps } = {
    'open-menu': {
      iconName: 'hamburger',
      iconLabel: 'Open menu',
      iconOnClick: toggleMenu,
    },
    'close-menu': {
      iconName: 'close',
      iconLabel: 'Close menu',
      iconOnClick: toggleMenu,
    },
    back: {
      iconName: 'arrow-back',
      iconLabel: 'Back',
      iconOnClick: pushLocation ? onPush : onBack,
    },
  };

  return (
    <IconButton
      name={iconProps[toggleType].iconName}
      label={iconProps[toggleType].iconLabel}
      color="white"
      onClick={iconProps[toggleType].iconOnClick}
    />
  );
};

export default MenuToggle;
