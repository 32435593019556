import { EpisodeActions } from 'actions/episodeActions';
import { Reducer } from 'redux';
import { Episode } from 'types/episodes';

export type EpisodeReducer = Episode | null;

export const initialState: EpisodeReducer = null;

export const episodeReducer: Reducer<EpisodeReducer, EpisodeActions> = (
  state = initialState,
  action,
): EpisodeReducer => {
  switch (action.type) {
    case '@episode/CLEAR_EPISODE':
      return initialState;

    case '@episode/SET_EPISODE':
      return action.payload;

    case '@episode/UPDATE_EPISODE':
      return state ? { ...state, ...action.payload } : state;

    case '@episode/UPDATE_BLOCK': {
      const newBlocks = state?.blocks.map((block) => {
        if (action.payload && block.blockIndex === action.payload.blockIndex) {
          return action.payload;
        }

        return block;
      });

      return state && newBlocks
        ? {
            ...state,
            blocks: newBlocks,
          }
        : state;
    }

    default:
      return state;
  }
};
