import { MenuActions } from 'actions/menuActions';
import { Reducer } from 'redux';

export interface MenuReducer {
  open: boolean;
}

export const initialState: MenuReducer = {
  open: false,
};

export const menuReducer: Reducer<MenuReducer, MenuActions> = (state = initialState, action) => {
  switch (action.type) {
    case '@menu/TOGGLE':
      return {
        ...state,
        open: !state.open,
      };

    default:
      return state;
  }
};
