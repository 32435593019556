import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Panel from 'components/Panel';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getParticipatedEpisode } from 'selectors/userSelectors';
import './RankPlayerScore.css';

interface Props {
  episodeId: string;
}

const RankPlayerScore: FC<Props> = ({ episodeId }) => {
  const { t } = useTranslation();

  const participatedEpisode = useSelector((state: AppState) =>
    getParticipatedEpisode(state, episodeId),
  );

  if (!participatedEpisode) {
    return null;
  }

  const { firstName, lastName, score, avatarUrl } = participatedEpisode;

  return (
    <Box className={'RankPlayerScore'} data-testid="rank-player-score">
      <Panel className={'RankPlayerScore-Panel'} variant="primary">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack spacing="medium" paddingLeft="medium" horizontal align="center">
            <Avatar src={avatarUrl} size="small" />
            <Text color="black" fontWeight="medium">
              {firstName} {lastName}
            </Text>
          </Stack>
          <Stack spacing="xsmall" paddingRight="medium" horizontal>
            <Text color="black" fontWeight="semiBold">
              {t('{{points}} pts', { points: score })}
            </Text>
          </Stack>
        </Box>
      </Panel>
    </Box>
  );
};

export default RankPlayerScore;
