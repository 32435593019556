import { setGlobalError } from 'actions/errorActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isGlobalError } from 'selectors/errorSelectors';

interface Dialog {
  dialogTitle: string;
  dialogContent: string;
}

const GlobalErrorDialog: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<Dialog | false>(false);

  const globalError = useSelector(isGlobalError);

  useEffect(() => {
    if (!globalError) {
      return;
    }

    switch (globalError) {
      case 'FacebookGraphRequestFailed': {
        setDialog({
          dialogTitle: t('Facebook Failed'),
          dialogContent: t('Facebook Sign In failed, please try again.'),
        });
        break;
      }
      case 'RequestMicrophoneAccessFailed': {
        setDialog({
          dialogTitle: t('Microphone Access Denied'),
          dialogContent: t(
            'Please enable microphone access in the settings to synchronize with your television.',
          ),
        });
        break;
      }
      case 'SignInFailed': {
        setDialog({
          dialogTitle: t('Sign In Failed'),
          dialogContent: t('Please try again.'),
        });
        break;
      }
    }

    dispatch(setGlobalError(undefined));
  }, [globalError]);

  if (!globalError && !dialog) {
    return null;
  }

  return (
    <Dialog
      open={Boolean(dialog)}
      onRequestClose={() => setDialog(false)}
      render={() => (
        <Box paddingY="gutter">
          <Stack align="center" spacing="large">
            <Stack align="center" spacing="medium">
              <Text color="secondary" fontSize="large" fontWeight="medium">
                {dialog && dialog.dialogTitle}
              </Text>
              <Text color="black" fontSize="small">
                {dialog && dialog.dialogContent}
              </Text>
            </Stack>
            <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
          </Stack>
        </Box>
      )}
    />
  );
};

export default GlobalErrorDialog;
