import { useEffect, useState } from 'react';
import { Phase } from 'types/events';

type UseBreakState = boolean;

export const useBreakState = (
  breakState: boolean,
  currentEventId?: string,
  currentEventPhase?: Phase,
): UseBreakState => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (breakState && !state) {
      setState(true);
    }

    if (!breakState && currentEventId && currentEventPhase) {
      setState(false);
    }
  }, [breakState, currentEventId, currentEventPhase]);

  return state;
};
