import Box from 'components/Box';
import React, { FC } from 'react';
import './CategoryIcon.css';

interface Props {
  url: string;
  title: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  color?: 'blue' | 'red' | 'gray';
}

const CategoryIcon: FC<Props> = ({ url, title, size = 'medium', color = 'blue' }) => {
  return (
    <Box display="inline-block" className={`CategoryIcon size-${size} color-${color}`}>
      <img src={url} title={title} />
    </Box>
  );
};

export default CategoryIcon;
