import { GoodscoreActions } from 'actions/goodscoreActions';
import { Reducer } from 'redux';

export type GoodscoreReducer = {
  goodscoreSubmittedCode?: string;
  showJackpot?: string;
};

export const initialState: GoodscoreReducer = {};

export const goodscoreReducer: Reducer<GoodscoreReducer, GoodscoreActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@goodscore/SET_SUBMITTED': {
      return {
        ...state,
        goodscoreSubmittedCode: action.payload,
      };
    }

    case '@episode/TRIGGER_JACKPOT_OVERLAY': {
      return {
        ...state,
        showJackpot: action.payload,
      };
    }

    default:
      return state;
  }
};
