import React from 'react';
import './PieChart.css';

interface Props {
  values: [number, number];
  variant?: 'chart-primary' | 'chart-secondary';
}

const PieChart = ({ values, variant = 'chart-primary' }: Props) => {
  const total = values[0] + values[1];
  const firstCircle = (values[0] / total) * 100;
  const secondCircle = (values[1] / total) * 100;

  return (
    <svg viewBox="0 0 42 42" className="PieChart">
      <circle
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="#d2d3d4"
        strokeWidth="3"
      />

      <circle
        className={`PieChart-variant-${variant}`}
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        strokeWidth="3"
        strokeDasharray={`${firstCircle} ${100 - firstCircle}`}
        strokeDashoffset="25"
      />

      <circle
        className={`PieChart-variant-${variant}`}
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="#eff0f3"
        strokeWidth="3"
        opacity="0.33"
        strokeDasharray={`${secondCircle} ${100 - secondCircle}`}
        strokeDashoffset={secondCircle + 25}
      />
    </svg>
  );
};

export default PieChart;
