import { AudioSyncActions } from 'actions/audioSyncActions';
import { GameActions } from 'actions/gameActions';
import { Reducer } from 'redux';

export type AudioSyncReducer = {
  microphoneEnabled: boolean;
  microphoneAccessDisabled?: boolean;
  confidence: number;
  contentId: string;
  timestamp: number;
  isEnabled: boolean;
  isSyncing: boolean;
  isSkipped: boolean;
  hasFailed: boolean;
  isSynced: boolean;
  wasSynced: boolean;
};

export const initialState: AudioSyncReducer = {
  microphoneEnabled: false,
  confidence: 0,
  contentId: '',
  timestamp: 0,
  isEnabled: false,
  isSyncing: false,
  isSkipped: false,
  hasFailed: false,
  isSynced: false,
  wasSynced: false,
};

export const audioSyncReducer: Reducer<AudioSyncReducer, AudioSyncActions | GameActions> = (
  state = initialState,
  action,
): AudioSyncReducer => {
  switch (action.type) {
    case '@audioSync/SET_AUDIO_SYNC': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case '@audioSync/START_AUDIO_SYNC':
      return {
        ...state,
        isEnabled: true,
        isSyncing: true,
        hasFailed: false,
        isSkipped: false,
      };

    case '@audioSync/STOP_AUDIO_SYNC':
      return {
        ...state,
        isEnabled: false,
        isSyncing: false,
        isSkipped: false,
        hasFailed: false,
        isSynced: false,
        wasSynced: false,
      };

    case '@audioSync/SET_MICROPHONE_ENABLED':
      return {
        ...state,
        microphoneEnabled: action.payload,
      };

    case '@audioSync/SET_MICROPHONE_ENABLED_ERRORED': {
      return {
        ...state,
        microphoneAccessDisabled: action.payload,
      };
    }

    case '@game/LEAVE_GAME':
      return initialState;

    default:
      return state;
  }
};
