import { Form } from '@blockle/form';
import { push } from 'actions/historyActions';
import { updateProfile } from 'actions/usersApiActions';
import AvatarSelect from 'components/AvatarSelect';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import FlatButton from 'components/FlatButton';
import { AgeGroupDropdown, RegionDropdown } from 'components/FormElements';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import RadioButton from 'components/RadioButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import TopBar from 'components/TopBar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmailUser } from 'selectors/socialSelectors';
import { getStoredProfile } from 'selectors/userSelectors';
import { AgeGroups, Genders, RegionNamesInGerman } from 'types/user';
import { getErrorCode } from 'utils/error';

interface FormData {
  ageGroup: AgeGroups;
  email?: string;
  firstName: string;
  gender: Genders;
  lastName?: string;
  location: RegionNamesInGerman;
  password?: string;
  phone?: string;
  avatar?: unknown;
}

type Dialog = {
  dialogTitle: string;
  dialogContent: string;
};

const ProfileOverviewEditScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<Dialog | false>(false);

  const userProfile = useSelector(getStoredProfile);
  const emailUser = useSelector(isEmailUser);

  const { gender, phone, firstName, lastName, email, ageGroup, avatarUrl, location } = userProfile;

  return (
    <>
      <TopBar title={t('Edit profile')} left={<MenuToggle toggleType="back" />} />

      <Panel>
        <Form
          onSubmit={async (data: FormData) => {
            // Delete password and email. Not needed, and do not change in the submit
            delete data.password;
            delete data.email;
            delete data.avatar;

            try {
              await dispatch(updateProfile(data));
              dispatch(push('/profileoverview'));
            } catch (error: any) {
              const code = getErrorCode(error);

              switch (code) {
                case 'uimapi.profile.update.form.validation.failed': {
                  return setDialog({
                    dialogTitle: t('Error'),
                    dialogContent: t(
                      'Bitte eine gültige Telefonnummer (beginnend mit +49 oder +43) eingeben.',
                    ),
                  });
                }

                default: {
                  return setDialog({
                    dialogTitle: t('Error'),
                    dialogContent: t('Something went wrong'),
                  });
                }
              }
            }
          }}
          render={({ submitting }) => (
            <Stack spacing="medium">
              <Box paddingX="gutter">
                <Stack spacing="large">
                  <Box display="flex" justifyContent="center">
                    <AvatarSelect name="avatar" avatarUrl={avatarUrl} />
                  </Box>

                  <Stack spacing="medium">
                    <TextField
                      name="email"
                      type="email"
                      label={firstName ? firstName : 'email'}
                      placeholder={t('quizmaster@servustv.com')}
                      errorMessages={{
                        required: t('REQUIRED'),
                      }}
                      value={email}
                      disabled
                    />
                    {emailUser && (
                      <TextField
                        name="password"
                        type="password"
                        label={t('Password')}
                        placeholder={t('Minimum of 6 characters')}
                        value="*******"
                        disabled
                      />
                    )}

                    <TextField
                      name="phone"
                      type="tel"
                      label={t('Phone Number')}
                      placeholder={t('ie. +43123456789')}
                      value={phone}
                    />
                    <TextField
                      name="lastName"
                      label={t('Last Name')}
                      placeholder={t('Enter your last name')}
                      value={lastName}
                    />
                  </Stack>
                </Stack>
              </Box>

              <Divider />

              <Box paddingX="gutter">
                <Stack spacing="large">
                  <Stack spacing="xsmall">
                    <Text fontSize="medium" fontWeight="medium">
                      {t('Other information')}
                    </Text>
                    <Text fontSize="small">
                      {t('This information will help us display more meaningful statistics')}
                    </Text>
                  </Stack>

                  <TextField
                    name="firstName"
                    label={t('First Name')}
                    placeholder={t('Enter your first name')}
                    required
                    errorMessages={{
                      required: t('REQUIRED'),
                    }}
                    value={firstName}
                  />
                  <AgeGroupDropdown value={ageGroup} />
                  <RegionDropdown value={location} />

                  <Stack spacing="large" horizontal role="radiogroup">
                    <RadioButton
                      name="gender"
                      value="MALE"
                      label={t('Male')}
                      checked={gender === 'MALE'}
                      initialValue={gender}
                    />
                    <RadioButton
                      name="gender"
                      value="FEMALE"
                      label={t('Female')}
                      checked={gender === 'FEMALE'}
                      initialValue={gender}
                    />
                  </Stack>
                </Stack>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                paddingTop="medium"
              >
                <Button type="submit" disabled={submitting}>
                  {t('Submit Profile')}
                </Button>
                {emailUser && (
                  <FlatButton to="/forgot-password"> {t('Change Password')}</FlatButton>
                )}
              </Box>
            </Stack>
          )}
        />
      </Panel>
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="large">
              <Stack align="center" spacing="medium">
                <Text color="secondary" fontSize="large" fontWeight="medium">
                  {dialog && dialog.dialogTitle}
                </Text>
                <Text color="black" fontSize="small">
                  {dialog && dialog.dialogContent}
                </Text>
              </Stack>
              <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default ProfileOverviewEditScreen;
