import { playWithAverageOffset, setSchedule } from 'actions/episodeScheduleActions';
import { submitGoodScore } from 'actions/goodscoreActions';
import { AppState } from 'reducers';
import { Store } from 'redux';
import { getSchedule } from 'selectors/episodeScheduleSelectors';
import { getEpisode } from 'selectors/episodeSelectors';
import { getEventsByEpisodeCode, getRoundIndex } from 'selectors/eventsSelectors';
import { Block } from 'types/episodes';
import { Event } from 'types/events';
import { getClientTime } from 'utils/clientTime';
import Cron from 'utils/cron';

const getBlockEndOffset = (blocks: Block[], blockIndex: number) => {
  if (blockIndex >= blocks.length) {
    throw new Error('Block out of boundry ${blockIndex} - ${blocks.length}');
  }

  let offset = 0;

  for (let i = 0; i <= blockIndex; i++) {
    if (!blocks[i]) {
      console.log(`Block not found - ${i}`, JSON.stringify(blocks));
    }

    offset += blocks[i].duration;
  }

  return offset;
};

const mapEventsToBlocks = (events: Event[], blocks: Block[]) => {
  let startOffset = 0;

  return blocks.map((block) => {
    const blockEndOffset = startOffset + block.duration;
    const blockEvents = events.filter(
      (event) => event.offset >= startOffset && event.offset < blockEndOffset,
    );

    startOffset += block.duration;

    return blockEvents;
  });
};

export const findActiveEventAndPhase = (events: Event[], offset: number) => {
  const currentEvent = events.find((event) => {
    if (event.offset > offset) {
      return false;
    }

    const finishedPhase = event.phases.find((phase) => phase.name === 'FINISHED');

    return finishedPhase ? finishedPhase.offset > offset : false;
  });

  if (currentEvent) {
    const phase = currentEvent.phases
      .filter((phase) => phase.offset <= offset)
      .reduce((max, phase) => (phase.offset > max.offset ? phase : max));

    return {
      eventId: currentEvent.eventId,
      phase,
    };
  }

  return {
    eventId: undefined,
    phase: undefined,
  };
};

export const findNextEventId = (events: Event[], offset: number) => {
  const nextEvent = events.find((event) => event.offset > offset);

  if (nextEvent) {
    return nextEvent.eventId;
  }

  return undefined;
};

export const connectEpisodeScheduler = async (store: Store<AppState, any>) => {
  let isScheduled = false;
  let offsetVersion: number | undefined;

  const cron = new Cron();

  const clearSchedule = () => {
    cron.clearAll();
    isScheduled = false;
  };

  async function scheduleBlock(blockIndex: number) {
    const state = store.getState();
    const episode = getEpisode(state);
    let schedule = getSchedule(state);

    // Clear previous scheduled events
    clearSchedule();

    if (!episode) {
      return;
    }

    // Auto sync average after commercials
    if (schedule.syncType === 'average' && schedule.offset === undefined) {
      await store.dispatch(playWithAverageOffset(episode.episodeCode));
      schedule = getSchedule(store.getState());
    }

    // Wait for syncType
    if (!schedule.syncType || !schedule.offset || !schedule.syncTime) {
      console.log('No syncType | offset | syncTime');
      return;
    }

    const { episodeCode, blocks } = episode;
    const events = getEventsByEpisodeCode(state, episodeCode);
    const blocksWithEvents = mapEventsToBlocks(events, blocks);
    const block = blocksWithEvents[blockIndex];

    const now = getClientTime();
    const offset = schedule.offset + (now - schedule.syncTime);
    const tZero = now - offset;
    const startTime = now - offset;
    const endTime = startTime + getBlockEndOffset(blocks, blockIndex);

    const getNextEventId = (event: Event) => {
      const eventIndex = events.indexOf(event);
      const nextEvent = events[eventIndex + 1];

      return nextEvent ? nextEvent.eventId : undefined;
    };

    // Schedule block-start
    cron.schedule(
      `block-start-${blockIndex}`,
      startTime + getBlockEndOffset(blocks, blockIndex - 1),
      () => {
        store.dispatch(
          setSchedule({
            nextEventId: block[0].eventId,
            blockEnded: false,
            blockIndex,
          }),
        );
      },
    );

    // Schedule block-end
    cron.schedule(`block-end-${blockIndex}`, endTime, () => {
      store.dispatch(
        setSchedule({
          nextEventId: getNextEventId(block[block.length - 1]),
          blockEnded: true,
          offset: undefined,
          offsetVersion: undefined,
          syncTime: undefined,
        }),
      );
    });

    cron.schedule(`block-end-${blockIndex}-remove-sync-type`, endTime + 3000, () => {
      store.dispatch(
        setSchedule({
          syncType: schedule.syncType === 'audioSync' ? undefined : schedule.syncType,
        }),
      );
    });

    // Schedule events
    const blockJobs = block
      .reduce((jobs, event) => {
        const phases = event.phases.map((phase) => {
          const time = startTime + phase.offset;

          return {
            event,
            phase,
            time,
          };
        });

        return [...jobs, ...phases];
      }, [] as any[])
      .sort((a, b) => {
        if (a.time > b.time) return 1;
        if (a.time < b.time) return -1;
        return 0;
      });

    const nextBlockIndex = blockJobs.findIndex((job) => job.time > now);
    const prevBlock = nextBlockIndex > -1 ? nextBlockIndex - 1 : blockJobs.length - 1;

    blockJobs.splice(prevBlock > -1 ? prevBlock : 0).forEach((job) => {
      const cronId = `${episodeCode}-${job.event.eventId}`;

      cron.schedule(`${cronId}-${job.phase.name}`, job.time, () => {
        // after the fourth round or at the first finaltrivia, submit a score greater than X on a one-time basis
        // this is done regardless of episode type, live or pre-recorded.
        const canSubmitScore =
          job.event.type === 'FINALTRIVIA' || getRoundIndex(store.getState(), job.event) >= 4;
        if (canSubmitScore) {
          store.dispatch(submitGoodScore(tZero));
        }

        store.dispatch(
          setSchedule({
            currentEventId: job.event.eventId,
            currentEventPhase: job.phase,
            nextEventId: getNextEventId(job.event),
          }),
        );
      });
    });

    isScheduled = true;
  }

  // Redux subscription
  store.subscribe(() => {
    const { episode, episodeSchedule } = store.getState();

    if (!episode) {
      clearSchedule();
      return;
    }

    const reschedule = () => {
      offsetVersion = episodeSchedule.offsetVersion;
      scheduleBlock(episode.blockIndex);
    };

    if (offsetVersion !== episodeSchedule.offsetVersion) {
      if (isScheduled || offsetVersion !== undefined) {
        reschedule();
      }
    }

    if (
      episode &&
      episode.episodeCode !== 'practice' &&
      episodeSchedule.blockEnded &&
      episodeSchedule.syncType &&
      episodeSchedule.blockIndex !== episode.blockIndex
    ) {
      reschedule();
    }
  });
};
