import { useDispatch as useReduxDispatch } from 'react-redux';
import { AppState } from 'reducers';
import { Action } from 'redux';
import { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';

export { useSelector, useStore } from 'react-redux';

/**
 * Wrap useDispatch with typings that allow the return types of
 * thunk actions to carry over correctly from the action to dispatch
 */
export type ThunkDispatch = ReduxThunkDispatch<AppState, any, Action>;

export const useDispatch = () => useReduxDispatch<ThunkDispatch>();
