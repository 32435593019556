import Box from 'components/Box';
import Card from 'components/Card';
import React, { useLayoutEffect, useRef } from 'react';
import Panel from './Panel';
import './PanelFloatingCard.css';

interface Props {
  children?: React.ReactNode;
  card: React.ReactNode;
  cardIcon?: React.ReactNode;
  onCardClick?: () => void;
}

const PanelFloatingCard = ({ children, card, cardIcon, onCardClick }: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!cardRef.current || !rootRef.current) {
      return;
    }

    const cardRect = cardRef.current.getBoundingClientRect();

    rootRef.current.style.setProperty('--card-height', `${cardRect.height}px`);
  }, [card]);

  return (
    <Box
      ref={rootRef}
      className="PanelFloatingCard"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <Box className="PanelFloatingCard-card" display="flex" justifyContent="center">
        <Card ref={cardRef} onClick={onCardClick}>
          {card}
        </Card>
        {cardIcon}
      </Box>
      <Panel className="PanelFloatingCard-panel">{children}</Panel>
    </Box>
  );
};

export default PanelFloatingCard;
