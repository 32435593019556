import Box from 'components/Box';
import GoogleSVG from 'public/icons/google.svg';
import React, { FC } from 'react';
import './GoogleLogo.css';

const GoogleLogo: FC = () => (
  <Box className="GoogleLogo">
    <GoogleSVG />
  </Box>
);

export default GoogleLogo;
