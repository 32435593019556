import { FieldProps, useField } from '@blockle/form';
import Icon from 'components/Icon';
import { cx } from 'cx';
import React from 'react';
import './Checkbox.css';

type Props = FieldProps<string> & {
  onChange?: (value: string) => void;
  checked?: boolean;
  required?: boolean;
};

const Checkbox = ({ name, checked = false, required }: Props) => {
  const field = useField(name, {
    value: checked,
    validate: (value: boolean) => {
      return required && !value ? 'required' : null;
    },
  });
  const invalid = field.invalid && (field.dirty || field.touched);

  return (
    <div
      data-testid="checkbox"
      className={cx('Checkbox', invalid && 'is-invalid')}
      role="checkbox"
      aria-checked={field.value}
      onClick={() => {
        field.setTouched();
        field.setValue(!field.value);
      }}
      tabIndex={0}
    >
      <Icon name="check" color="white" label="checked" size="large" />
    </div>
  );
};

export default Checkbox;
