import Box from 'components/Box';
import { StyleProps } from 'hooks/useStyles';
import React, { FC } from 'react';

interface Props {
  spacing: StyleProps['paddingTop'];
}

const Spacer: FC<Props> = ({ spacing }) => {
  return <Box paddingTop={spacing} />;
};

export default Spacer;
