import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Icon, { IconNames } from 'components/Icon';
import React, { FC } from 'react';
import './AvatarWithIcon.css';

interface Props {
  src?: string;
  iconName: IconNames;
}

const AvatarWithIcon: FC<Props> = ({ src, iconName }) => {
  return (
    <Box position="relative">
      <Avatar size="large" src={src} />
      <Box
        className="AvatarWithIcon-avatarIcon"
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon name={iconName} size="xlarge" color="white" />
      </Box>
    </Box>
  );
};

export default AvatarWithIcon;
