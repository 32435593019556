import { GameActions } from 'actions/gameActions';
import { PracticeActions } from 'actions/practiceScheduleActions';
import { Reducer } from 'redux';
import { Phase } from 'types/events';

export interface PracticeScheduleReducer {
  currentEventId?: string;
  currentEventPhase?: Phase;
  nextEventId?: string;
  startTime: number;
  paused: boolean;
  setNumber: number;
}

export const initialState: PracticeScheduleReducer = {
  currentEventId: undefined,
  currentEventPhase: undefined,
  nextEventId: undefined,
  startTime: 0,
  paused: false,
  setNumber: 1,
};

export const practiceScheduleReducer: Reducer<
  PracticeScheduleReducer,
  PracticeActions | GameActions
> = (state = initialState, action) => {
  switch (action.type) {
    case '@practiceSchedule/START':
      return {
        ...state,
        startTime: action.payload,
      };

    case '@practiceSchedule/RESET':
      return {
        ...initialState,
        setNumber: state.setNumber,
      };

    case '@practiceSchedule/SET_SCHEDULE':
      return {
        ...state,
        ...action.payload,
      };

    case '@practiceSchedule/PAUSE':
      return {
        ...state,
        paused: true,
      };

    case '@practiceSchedule/UNPAUSE_AT':
      return {
        ...state,
        paused: false,
        startTime: action.payload,
      };

    default:
      return state;
  }
};
