import { setSchedule } from 'actions/practiceScheduleActions';
import { AppStore } from 'config/store';
import { getEventsByEpisodeCode } from 'selectors/eventsSelectors';
import { getPracticeSchedule } from 'selectors/practiceScheduleSelectors';
import { getClientTime } from 'utils/clientTime';
import { findActiveEventAndPhase, findNextEventId } from './connectEpisodeScheduler';

export const connectPracticeScheduler = (store: AppStore) => {
  let timer: number;

  const update = () => {
    const state = store.getState();
    const schedule = getPracticeSchedule(state);
    const events = getEventsByEpisodeCode(state, 'practice');

    clearTimeout(timer);

    if (!schedule.startTime || schedule.paused || !events.length) {
      return;
    }

    const offset = getClientTime() - schedule.startTime;
    const { eventId, phase } = findActiveEventAndPhase(events, offset);
    const nextEventId = findNextEventId(events, offset);

    if (
      schedule.currentEventId !== eventId ||
      schedule.currentEventPhase !== phase ||
      schedule.nextEventId !== nextEventId
    ) {
      store.dispatch(
        setSchedule({
          currentEventId: eventId,
          currentEventPhase: phase,
          nextEventId,
        }),
      );
    }

    timer = window.setTimeout(update, 100);
  };

  let prevStartTime = 0;
  store.subscribe(() => {
    const schedule = getPracticeSchedule(store.getState());

    if (schedule.startTime !== prevStartTime) {
      prevStartTime = schedule.startTime;
      update();
    }
  });
};
