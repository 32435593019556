import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import React, { FC } from 'react';
import './GenderRepresentation.css';

interface Props {
  color: 'black' | 'secondary';
  icon: 'female' | 'male';
  percentageOfGender: number;
}

const GenderRepresentation: FC<Props> = ({ color, icon, percentageOfGender }) => {
  return (
    <Box
      data-testid="gender-representation"
      className={`GenderRepresentation variant-${color}`}
      display="flex"
      justifyContent="center"
      paddingLeft="xsmall"
      paddingRight="xsmall"
    >
      <Icon name={icon} size="medium" color={color} />
      <Text fontSize="small" color={color}>
        {percentageOfGender}%
      </Text>
    </Box>
  );
};

export default GenderRepresentation;
