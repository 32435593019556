import { push } from 'actions/historyActions';
import Box from 'components/Box';
import Icon from 'components/Icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import './AvatarSelectIcon.css';

interface Props {
  to: string;
}

const AvatarSelectIcon: FC<Props> = ({ to }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        data-testid="avatar-selection-icon"
        className="AvatarSelectIcon"
        role="button"
        position="absolute"
        onClick={() => dispatch(push(to))}
      >
        <Icon name="edit" color="white" label={t('Change avatar')} size="large" />
      </Box>
    </>
  );
};

export default AvatarSelectIcon;
