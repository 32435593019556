import { AppState } from 'reducers';

export const getPracticeSchedule = (state: AppState) => state.practiceSchedule;

export const hasOpenPracticeGame = (state: AppState) => {
  const { startTime } = getPracticeSchedule(state);

  return startTime !== 0;
};

export const getSetNumber = (state: AppState) => state.practiceSchedule.setNumber;
