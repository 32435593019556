import { trackPageView } from 'actions/analyticsActions';
import CategoryInteraction from 'components/Interactions/CategoryInteraction';
import EndOfShowInteraction from 'components/Interactions/EndOfShowInteraction';
import InterstitialInteraction from 'components/Interactions/InterstitialInteraction';
import PracticeQuestionInteraction from 'components/Interactions/PracticeQuestionInteraction';
import RoundInteraction from 'components/Interactions/RoundInteraction';
import StudioPlayerInteraction from 'components/Interactions/StudioPlayerInteraction';
import TriviaInteraction from 'components/Interactions/TriviaInteraction';
import WaitingForLeaderboard from 'components/Interactions/WaitingForLeaderboard';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getEvent } from 'selectors/eventsSelectors';
import { PhaseNames } from 'types/events';

type Props = {
  eventId: string;
  eventPhase: PhaseNames;
  startTime: number;
  episodeCode: string;
};

const InteractionScreen = ({ eventId, eventPhase, startTime, episodeCode }: Props) => {
  const dispatch = useDispatch();
  const event = useSelector((state: AppState) => getEvent(state, eventId));

  useEffect(() => {
    if (event) {
      dispatch(trackPageView(`${event.type}.${eventPhase}`));
    }
  }, [event, eventPhase]);

  if (!event) {
    // TODO Log me
    return null;
  }

  switch (event.type) {
    case 'ROUND':
      return <RoundInteraction episodeCode={episodeCode} />;
    case 'CATEGORYSTART':
      return <CategoryInteraction episodeCode={episodeCode} event={event} startTime={startTime} />;
    case 'TRIVIA':
      if (eventPhase === 'FINISHED') {
        return (
          <CategoryInteraction
            episodeCode={episodeCode}
            event={event}
            startTime={startTime}
            questionFinished
          />
        );
      }

      return (
        <TriviaInteraction
          episodeCode={episodeCode}
          event={event}
          eventPhase={eventPhase}
          startTime={startTime}
        />
      );
    case 'STUDIOPLAYERSELECTION':
      return (
        <StudioPlayerInteraction
          episodeCode={episodeCode}
          event={event}
          eventPhase={eventPhase}
          startTime={startTime}
        />
      );
    case 'PRACTICEQUESTION':
      return (
        <PracticeQuestionInteraction
          episodeCode={episodeCode}
          event={event}
          eventPhase={eventPhase}
          startTime={startTime}
        />
      );
    case 'ENDOFSHOW':
      return (
        <EndOfShowInteraction
          episodeCode={episodeCode}
          event={event}
          eventPhase={eventPhase}
          startTime={startTime}
        />
      );
    case 'WAITINGFORLEADERBOARD':
      return (
        <WaitingForLeaderboard episodeCode={episodeCode} event={event} eventPhase={eventPhase} />
      );
    case 'INTERSTITIAL':
      return <InterstitialInteraction episodeCode={episodeCode} event={event} />;
    default:
      console.log(`Unknown event type: ${(event as any).type}`);
      return null;
  }
};

export default InteractionScreen;
