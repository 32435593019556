import CategoryInteraction from 'components/Interactions/CategoryInteraction';
import React from 'react';
import { CategoryStartEvent } from 'types/events';

interface Props {
  episodeCode?: string;
  event?: CategoryStartEvent;
}

const PreviewCategoryScreen = ({ episodeCode, event }: Props) => {
  if (!event || !episodeCode) return null;

  return <CategoryInteraction event={event} episodeCode={episodeCode} />;
};

export default PreviewCategoryScreen;
