import { cx } from 'cx';
import LogoFullSVG from 'public/icons/logo-full.svg';
import LogoSVG from 'public/icons/logo.svg';
import React from 'react';
import './Logo.css';

interface Props {
  full?: boolean;
}

const Logo = ({ full }: Props) => {
  return (
    <div data-testid="logo" className={cx('Logo', full && 'is-full')}>
      {full ? <LogoFullSVG /> : <LogoSVG />}
    </div>
  );
};

export default Logo;
