import AnswerButton from 'components/AnswerButton';
import Box from 'components/Box';
import LogoProgress from 'components/LogoProgress';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import PointsOverlay from 'components/PointsOverlay';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import { useFlip } from 'hooks/useFlip';
import { useSessionState } from 'hooks/useSessionState';
import { useSubmitAnswer } from 'hooks/useSubmitAnswer';
import React, { FC } from 'react';
import IdleScreen from 'screens/Game/IdleScreen';
import { PhaseNames, TriviaEvent } from 'types/events';
import './TriviaInteraction.css';

/**
 * Helper to get answer value (not_set | correct | incorrect)
 */
export const getAnswerValue = (
  selectedAnswers: string[],
  correctAnswer: string,
): 'correct' | 'incorrect' | 'not_set' => {
  const answers = correctAnswer.split(',');

  if (selectedAnswers.length === 0) {
    return 'not_set';
  }

  if (answers.length !== selectedAnswers.length) {
    return 'incorrect';
  }

  const isCorrect = answers.every((code) => selectedAnswers.includes(code));

  return isCorrect ? 'correct' : 'incorrect';
};

interface Props {
  episodeCode: string;
  event: TriviaEvent;
  eventPhase: PhaseNames;
  startTime: number;
}

const TriviaInteraction: FC<Props> = ({ event, eventPhase, startTime, episodeCode }) => {
  const { setRef } = useFlip();
  const [selectedAnswers, setSelected] = useSessionState<string[]>(event.eventId, []);
  const { text, answers, correctAnswer = '' } = event.content;

  const showQuestion = eventPhase !== 'SHOW';
  const startPhase = event.phases.find(({ name }) => name === 'START');
  const stopPhase = event.phases.find(({ name }) => name === 'STOP');
  const showResult = eventPhase === 'RESULTS' || eventPhase === 'FINISHED';
  const isCorrect = getAnswerValue(selectedAnswers, correctAnswer) === 'correct';
  const singleAnswer = true;
  const lockAnswer = true;
  const disabled = eventPhase !== 'START';

  useSubmitAnswer({
    showResult,
    eventPhase,
    episodeCode,
    eventId: event.eventId,
    value: getAnswerValue(selectedAnswers, correctAnswer),
    raw: selectedAnswers.join(','),
  });

  if (!startPhase || !stopPhase) {
    return <IdleScreen />;
  }

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Box
        className="TriviaInteraction-questionArea"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX="xlarge"
        paddingY="large"
      >
        <Stack spacing="medium" align="center">
          <Box display="inline-block" ref={setRef('logo')}>
            <LogoProgress
              startTime={startTime + startPhase.offset}
              endTime={startTime + stopPhase.offset}
            />
          </Box>

          {showQuestion && (
            <Text
              ref={setRef('questionText')}
              color="white"
              textAlign="center"
              fontSize="large"
              fontWeight="bold"
            >
              {text}
            </Text>
          )}
        </Stack>
      </Box>

      <Panel className="TriviaInteraction-panel">
        <Box paddingX="gutter" paddingTop="large">
          <Stack spacing="medium">
            {answers.map(({ code, text }) => {
              const isSelected = selectedAnswers.includes(code);
              const isCorrect = correctAnswer.includes(code);
              const toggleAnswer = () => {
                if (singleAnswer) {
                  setSelected([code]);
                  return;
                }

                if (isSelected) {
                  setSelected(selectedAnswers.filter((answerCode) => answerCode !== code));
                } else {
                  setSelected([...selectedAnswers, code]);
                }
              };
              const disableClick = showResult || (lockAnswer && selectedAnswers.length > 0);

              return (
                <AnswerButton
                  key={code}
                  disabled={disabled}
                  letter={code}
                  selected={isSelected}
                  correct={showResult && isCorrect}
                  incorrect={showResult && isSelected && !isCorrect}
                  onClick={disableClick ? undefined : toggleAnswer}
                >
                  {text}
                </AnswerButton>
              );
            })}
          </Stack>
        </Box>
      </Panel>

      <PointsOverlay open={showResult && isCorrect} points={event.content.score} />
    </>
  );
};

export default TriviaInteraction;
