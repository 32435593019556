import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Stack from 'components/Stack';
import Text from 'components/Text';
import { useSelector } from 'hooks/redux';
import JackPot from 'public/images/jackpot.png';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getJackpot } from 'selectors/goodscoreSelectors';

interface Dialog {
  dialogTitle: string;
  dialogContent: string;
}

const JackpotDialog: FC = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<boolean>(false);

  const showJackpot = useSelector(getJackpot);

  useEffect(() => {
    if (!showJackpot) {
      return;
    }

    setDialog(true);
  }, [showJackpot]);

  return (
    <Dialog
      open={Boolean(dialog)}
      onRequestClose={() => setDialog(false)}
      render={() => (
        <Box paddingY="gutter">
          <Stack align="center" spacing="large">
            <Stack align="center" spacing="medium">
              <Text color="secondary" fontSize="xlarge" fontWeight="medium">
                {t('Gratulation!')}
              </Text>
              <Text color="black" fontSize="medium" textAlign="center">
                {t(
                  'Sie haben es heute in die Ziehung zum App Jackpot geschafft. Der Sieger wird am Ende der Sendung im TV live bekannt gegeben.',
                )}
              </Text>
              <img src={JackPot} />
            </Stack>
            <Button onClick={() => setDialog(false)}>{t('Schließen')}</Button>
          </Stack>
        </Box>
      )}
    />
  );
};

export default JackpotDialog;
