interface RenderProps {
  next: () => void;
  prev: () => void;
}

interface Props {
  render: (renderProps: RenderProps) => JSX.Element;
}

const BarStepperItem = ({}: Props) => null;

export default BarStepperItem;
