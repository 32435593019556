import { openBrowser } from 'actions/bridgeActions';
import { logout } from 'actions/usersApiActions';
import Box from 'components/Box';
import Link from 'components/Link';
import SignOutDialog from 'components/SignOutDialog';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEpisodeCode } from 'selectors/episodeSelectors';
import { getIsLoggedIn } from 'selectors/userSelectors';

interface Props {
  onClick: () => void;
}

const ApplicationMenuScreen = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSignOutDialog, setShowSignOutDialog] = useState(false);

  const episodeCode = useSelector(getEpisodeCode);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const { guidelines } = window.emconfig.settings;

  return (
    <>
      <Box
        height="full"
        role="navigation"
        paddingTop="xlarge"
        paddingLeft="xlarge"
        paddingBottom="xlarge"
        onClick={onClick}
      >
        <Stack spacing="xlarge">
          <Stack spacing="large">
            <Link to="/" fontSize="large">
              {t('Home')}
            </Link>
            <Link to="/statistics" fontSize="large">
              {t('Statistics')}
            </Link>
            {isLoggedIn && (
              <Link to="/profileoverview" fontSize="large">
                {t('My Profile')}
              </Link>
            )}
            {isLoggedIn ? (
              <Link
                fontSize="large"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowSignOutDialog(true);
                }}
              >
                {t('Sign Out')}
              </Link>
            ) : (
              <Link
                fontSize="large"
                onClick={() => {
                  dispatch(logout(true));
                }}
              >
                {t('Profil Erstellen')}
              </Link>
            )}
          </Stack>

          <Stack spacing="medium">
            <Link
              onClick={() => dispatch(openBrowser(guidelines.participation))}
              fontSize="xsmall"
              color="darkGray"
            >
              {t('Conditions of participation')}
            </Link>
            <Link
              onClick={() => dispatch(openBrowser(guidelines.terms))}
              fontSize="xsmall"
              color="darkGray"
            >
              {t('Terms of Use')}
            </Link>
            <Link
              onClick={() => dispatch(openBrowser(guidelines.imprint))}
              fontSize="xsmall"
              color="darkGray"
            >
              {t('Imprint')}
            </Link>
            <Link
              onClick={() => dispatch(openBrowser(guidelines.privacy))}
              fontSize="xsmall"
              color="darkGray"
            >
              {t('Privacy policy')}
            </Link>
            {__DEV__ && (
              <Link to="/styleguide" fontSize="xsmall" color="darkGray">
                Style guide
              </Link>
            )}
            {__DEV__ && episodeCode && (
              <Link to="/preview" fontSize="xsmall" color="darkGray">
                Preview
              </Link>
            )}
            {__DEV__ && window.emconfig && window.emconfig.version && (
              <Text display="inline" fontSize="xsmall" color="white">
                Version: {window.emconfig.version}
              </Text>
            )}
          </Stack>
        </Stack>
      </Box>

      <SignOutDialog
        showSignOutDialog={showSignOutDialog}
        setShowSignOutDialog={setShowSignOutDialog}
      />
    </>
  );
};

export default ApplicationMenuScreen;
