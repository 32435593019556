import Box from 'components/Box';
import CategoryCard from 'components/CategoryCard';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getCategoryOverview } from 'selectors/answersSelectors';
import { getPracticeCategoryOverview } from 'selectors/practiceAnswerSelectors';

interface Props {
  episodeCode: string;
}

const RoundInteraction: FC<Props> = ({ episodeCode }) => {
  const isPracticeGame = episodeCode === 'practice';
  const { t } = useTranslation();
  const categories = useSelector((state: AppState) =>
    isPracticeGame ? getPracticeCategoryOverview(state) : getCategoryOverview(state, episodeCode),
  );

  return (
    <>
      <TopBar logo left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Spacer spacing="small" />

      <Panel>
        <Box paddingX="gutter">
          <Stack spacing="small">
            <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
              {t('Next round')}
            </Text>

            <Stack spacing="small">
              {categories.map((category) => (
                <CategoryCard key={category.categoryId} {...category} />
              ))}
            </Stack>
          </Stack>
        </Box>
      </Panel>
    </>
  );
};

export default RoundInteraction;
