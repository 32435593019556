import Dropdown from 'components/Dropdown';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
}

const RegionDropDown: FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      required
      name="ageGroup"
      label={t('Age Range')}
      placeholder={t('Select an Age Range')}
      errorMessages={{
        required: t('REQUIRED'),
      }}
      value={value}
    >
      <option data-test-id="option" value="0TO12">
        {t('under 13')}
      </option>
      <option value="13TO19">{t('13 to 19 years')}</option>
      <option value="20TO39">{t('20 to 39 years')}</option>
      <option value="40TO59">{t('40 to 59 years')}</option>
      <option value="60PLUS">{t('60 or older')}</option>
    </Dropdown>
  );
};

export default RegionDropDown;
