import AgeTranslate from 'components/AgeTranslate';
import AvatarWithIcon from 'components/AvatarWithIcon';
import Box from 'components/Box';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { AgeGroups, Genders, RegionNamesInGerman } from 'types/user';
import './PlayerCard.css';

interface Props {
  avatarUrl?: string;
  gender?: Genders;
  firstName?: string;
  lastName?: string;
  email?: string;
  ageGroup?: AgeGroups;
  location?: RegionNamesInGerman;
}

const PlayerCard: FC<Props> = ({
  avatarUrl,
  gender,
  firstName,
  lastName,
  email,
  ageGroup,
  location,
}) => {
  return (
    <Box overflow="hidden" flexGrow={1} display="flex">
      <AvatarWithIcon src={avatarUrl} iconName={gender === 'MALE' ? 'male' : 'female'} />
      <Stack spacing="small" className="PlayerCard-userFields" paddingLeft="medium">
        <Text color="primary" fontSize="large" fontWeight="medium" className="PlayerCard-userField">
          {firstName} {lastName}
        </Text>

        <Stack spacing="xsmall">
          <Text fontSize="small" className="PlayerCard-userField">
            {email}
          </Text>

          <Text fontSize="small">
            <AgeTranslate ageGroup={ageGroup as AgeGroups} />
          </Text>
          <Text fontSize="small">{location}</Text>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PlayerCard;
