import { SoundContext } from 'context/SoundProvider';
import { useContext, useEffect } from 'react';

export const useSound = (url: string) => {
  const context = useContext(SoundContext);

  useEffect(() => {
    context?.load(url);
  }, []);

  return {
    play: () => context?.play(url),
  };
};
