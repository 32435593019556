import Box from 'components/Box';
import Coin from 'components/Coin';
import { Paper } from 'components/Paper';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PointsOverlay.css';

interface Props {
  open: boolean;
  points?: number;
}

const PointsOverlay: FC<Props> = ({ open = false, points = 50 }) => {
  const { t } = useTranslation();

  const [openOverlay, setOpenOverlay] = useState(false);

  const timerRef = useRef(0);

  const startTimer = () => {
    timerRef.current = window.setTimeout(() => {
      setOpenOverlay(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (!open) return;

    setOpenOverlay(true);

    startTimer();
  }, [open]);

  return (
    <Paper fit open={openOverlay}>
      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="full"
        width="full"
        className="PointsOverlay"
      >
        <Stack spacing="medium" align="center">
          <Text color="white" fontSize="xlarge" fontWeight="extraBold">
            {t('CORRECT!')}
          </Text>
          <Text color="yellow" fontSize="xlarge" fontWeight="extraBold">
            {t('+{{points}} points', { points })}
          </Text>
          <Coin size="xlarge" glow value={points} />
        </Stack>
      </Box>
    </Paper>
  );
};

export default PointsOverlay;
