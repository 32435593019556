import { push } from 'actions/historyActions';
import Box from 'components/Box';
import { cx } from 'cx';
import { PickStyleProps } from 'hooks/useStyles';
import React from 'react';
import { useDispatch } from 'react-redux';
import './Button.css';

interface Props extends PickStyleProps<'width'> {
  children: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit';
  variant?: 'default' | 'white' | 'facebook' | 'apple' | 'google' | 'twitter' | 'whatsapp';
  to?: string;
}

const Button = ({
  children,
  icon,
  disabled,
  onClick,
  type = 'button',
  variant = 'default',
  to,
  width,
}: Props) => {
  const dispatch = useDispatch();
  const onAnchorClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (to) {
      dispatch(push(to));
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      component={to ? 'a' : 'button'}
      className={cx('Button', `variant-${variant}`, !!icon && 'has-icon')}
      fontSize="small"
      fontWeight="medium"
      disabled={disabled}
      onClick={to ? onAnchorClick : onClick}
      type={type}
      tabIndex={0}
      width={width}
    >
      {icon ? (
        <>
          {variant !== 'white' && icon}
          <Box flexGrow={variant === 'white' ? 0 : 1}>{children}</Box>
          {variant === 'white' && icon}
        </>
      ) : (
        children
      )}
    </Box>
  );
};

export default Button;
