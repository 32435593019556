import { Event } from '../types/events';
import { createActionWithPayload } from './helpers';

export const setEvent = (event: Event) => createActionWithPayload('@events/SET_EVENT', event);

export const setEvents = (events: Event[]) => createActionWithPayload('@event/SET_EVENTS', events);

export const addEventCorrectAnswers = (correctAnswers: Record<string, string>) =>
  createActionWithPayload('@event/ADD_CORRECT_ANSWERS', correctAnswers);

export type EventActions = ReturnType<
  typeof setEvents | typeof addEventCorrectAnswers | typeof setEvent
>;
