import { AppStore } from 'config/store';
import { AppState } from 'reducers';

const local: { store?: AppStore } = {};

export const connectPttv = (store: AppStore) => {
  local.store = store;
};

export const getPttvDispatch = () => {
  if (!local.store) {
    throw new Error('pttv is not connected');
  }

  return local.store.dispatch;
};

export const getPttvState = () => {
  if (!local.store) {
    throw new Error('pttv is not connected');
  }

  return local.store.getState();
};

export const getPttvSub = (callback: (getState: () => AppState) => void) => {
  if (!local.store) {
    throw new Error('pttv is not connected');
  }

  return local.store.subscribe(() => {
    if (!local.store) {
      throw new Error('pttv is not connected');
    }

    return callback(local.store.getState);
  });
};
