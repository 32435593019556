import { openBrowser } from 'actions/bridgeActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import FormWarning from 'components/FormWarning/FormWarning';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  submitting: boolean;
  values: Partial<OptinVersions>;
  submitText: string;
  hideValidFields?: boolean;
  hideDisclaimer?: boolean;
}

const PolicyFields: FC<Props> = ({
  submitting,
  values,
  submitText,
  hideValidFields = false,
  hideDisclaimer = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { guidelines, optinVersions } = window.emconfig.settings;

  const fields = useMemo(() => {
    const fieldContent: Record<keyof OptinVersions, JSX.Element> = {
      terms: (
        <Trans>
          I agree to the{' '}
          <a onClick={openInBrowser} href={guidelines.terms} target="_blank" rel="noreferrer">
            Terms & Conditions
          </a>
        </Trans>
      ),
      participation: (
        <Trans>
          I agree to the{' '}
          <a
            onClick={openInBrowser}
            href={guidelines.participation}
            target="_blank"
            rel="noreferrer"
          >
            Conditions of Participation
          </a>
        </Trans>
      ),
      privacy: (
        <Trans>
          I agree to the{' '}
          <a onClick={openInBrowser} href={guidelines.privacy} target="_blank" rel="noreferrer">
            Privacy
          </a>
        </Trans>
      ),
    };

    return Object.keys(optinVersions).map((name) => {
      const optinName = name as keyof OptinVersions;

      return (
        (!hideValidFields || values[optinName] !== optinVersions[optinName]) && (
          <Stack spacing="small" horizontal align="center" key={name}>
            <Checkbox
              name={optinName}
              required
              checked={values[optinName] === optinVersions[optinName]}
            />
            <Text color="black" fontSize="small">
              {fieldContent[optinName]}
            </Text>
          </Stack>
        )
      );
    });
  }, [hideValidFields, JSON.stringify(values)]);

  const openInBrowser = (event: React.MouseEvent) => {
    event.preventDefault();

    const { target } = event;
    const link = (target as HTMLElement).closest('a');

    if (!link) {
      return;
    }

    dispatch(openBrowser(link.href));
  };

  return (
    <Box padding="gutter">
      <Stack spacing="medium">
        {fields}

        {/* TODO How to exclude empty elements from stack? */}
        <FormWarning inputName="terms">
          {t('You need to agree to the terms and conditions')}
        </FormWarning>
        <FormWarning inputName="participation">
          {t('You need to agree to the conditions of participation')}
        </FormWarning>
        <FormWarning inputName="privacy">{t('You need to agree to the privacy')}</FormWarning>

        {!hideDisclaimer && (
          <Text fontSize="xsmall" color="darkGray">
            <Trans>
              Durch die Eröffnung eines Benutzerkontos stimme ich zu, dass meine persönlichen Daten
              wie in der{' '}
              <a onClick={openInBrowser} href={guidelines.privacy} target="_blank" rel="noreferrer">
                Datenschutzrichtlinie
              </a>{' '}
              beschrieben verarbeitet bzw. verwendet werden.
            </Trans>
          </Text>
        )}

        <Box display="flex" justifyContent="center" paddingTop="medium">
          <Button type="submit" disabled={submitting}>
            {submitText}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default PolicyFields;
