import { Form } from '@blockle/form';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SignInDialogProps {
  dialogTitle: string;
  dialogContent: string;
  dialogSignInSuccessful?: boolean;
}

interface Props {
  onSubmit: (formData: any) => Promise<void>;
  dialog: SignInDialogProps | false;
  setDialog: React.Dispatch<React.SetStateAction<false | SignInDialogProps>>;
  isConfirm?: boolean;
  email: string;
}

export const SignInForm: FC<Props> = ({ onSubmit, dialog, setDialog, isConfirm, email }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ submitting }) => (
          <Stack spacing="xlarge" paddingX="gutter">
            {!isConfirm && (
              <Text
                component="h1"
                fontSize="xlarge"
                fontWeight="bold"
                color="secondary"
                textAlign="center"
              >
                {t('Profile login')}
              </Text>
            )}
            <Stack spacing="large">
              <Stack spacing="medium">
                <TextField
                  name="email"
                  type="email"
                  label={t('Email')}
                  placeholder={t('Enter your email')}
                  required
                  errorMessages={{
                    required: t('REQUIRED'),
                  }}
                  value={email}
                />
                <TextField
                  name="password"
                  type="password"
                  label={t('Password')}
                  placeholder={t('Enter your password')}
                  required
                  errorMessages={{
                    required: t('REQUIRED'),
                  }}
                />
              </Stack>
              <Stack spacing="small" align="center">
                <Button type="submit" disabled={submitting}>
                  {isConfirm ? t('Submit') : t('Log in')}
                </Button>
                {!isConfirm && (
                  <FlatButton to="/forgot-password">{t('Forgot Password?')}</FlatButton>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      />
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => {
          if (!dialog) return null;

          const { dialogTitle, dialogContent, dialogSignInSuccessful } = dialog;

          return (
            <Box paddingY="gutter">
              <Stack align="center" spacing="large">
                <Stack align="center" spacing="medium">
                  <Text color="secondary" fontSize="large" fontWeight="medium">
                    {dialogTitle}
                  </Text>
                  <Text color="black" fontSize="small">
                    {dialogContent}
                  </Text>
                </Stack>
                {!dialogSignInSuccessful ? (
                  <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
                ) : (
                  <Button onClick={() => setDialog(false)} to="/">
                    {t('Confirm')}
                  </Button>
                )}
              </Stack>
            </Box>
          );
        }}
      />
    </>
  );
};
