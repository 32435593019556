import Box from 'components/Box';
import Stack from 'components/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CountdownItem from './CountdownItem';

function pad(value: number): string {
  if (value < 10) {
    return `0${value}`;
  }

  return String(value);
}

interface Props {
  timeLeft: number;
}

const Countdown = ({ timeLeft }: Props) => {
  const { t } = useTranslation();

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor((timeLeft % 3600) % 60);
  const days = Math.floor(hours / 24);

  return (
    <Box display="flex" justifyContent="center" paddingTop="small">
      {days > 0 ? (
        <CountdownItem value={`${days} ${days > 1 ? t('days') : t('day')}`} />
      ) : (
        <Stack spacing="medium" horizontal>
          <CountdownItem value={pad(hours)} label={t('Hours')} />
          <CountdownItem value={pad(minutes)} label={t('Minutes')} />
          <CountdownItem value={pad(seconds)} label={t('Seconds')} />
        </Stack>
      )}
    </Box>
  );
};

export default Countdown;
