import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const StatisticsNotFound: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopBar title={t('Statistics not found')} left={<MenuToggle toggleType="back" />} />
      <Panel>
        <Text textAlign="center">{t('Episode Results not available yet, come back soon')}</Text>
      </Panel>
    </>
  );
};

export default StatisticsNotFound;
