import { Route } from '@blockle/router';
import { trackPageView } from 'actions/analyticsActions';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Strip "#/" and search query
function getRoute(path: string) {
  const cleanPath = path.replace(/^\#|\?.*$/g, '');

  return cleanPath;
}

const TrackRoute = () => {
  const dispatch = useDispatch();
  const prevRoute = useRef(getRoute(location.hash));

  // Track initial page
  useEffect(() => {
    dispatch(trackPageView(getRoute(location.hash)));
  }, []);

  return (
    <Route
      path="/*"
      render={() => {
        const route = getRoute(location.hash);

        // Make sure we only track "newly navigated" pages
        if (route !== prevRoute.current) {
          prevRoute.current = route;

          dispatch(trackPageView(route));
        }

        return null;
      }}
    />
  );
};

export default TrackRoute;
