import { AppState } from '../reducers';

export const getJoinedGameType = (state: AppState) => state.game.gameType;

export const joinedPracticeGame = (state: AppState) => getJoinedGameType(state) === 'practice';

export const hasUpdatedTerms = (state: AppState) =>
  !!state.user.profile &&
  !!state.game.termsVersion &&
  state.user.profile.terms !== state.game.termsVersion;

export const getTermsVersion = (state: AppState) => state.game.termsVersion;

export const getDebugOverlayVisible = (state: AppState) => state.game.debugOverlayVisible;
