import { EpisodeActions } from 'actions/episodeActions';
import { ScheduleActions } from 'actions/episodeScheduleActions';
import { GameActions } from 'actions/gameActions';
import { SocialActions } from 'actions/socialActions';
import { UserActions } from 'actions/userActions';
import { AppState } from 'reducers';
import { Reducer } from 'redux';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
import { getRegistrationFields } from 'selectors/userSelectors';
import { Profile, UserStatistics } from 'types/user';
import { normalize } from 'utils/normalize';

export interface UserReducer {
  authenticationToken?: string;
  pttvSession?: string;
  clientIp?: string;
  userId?: string;
  loginCredentialStatus?: {
    userId: string;
    externalIds: any;
    authenticationToken: string;
    email?: string;
    emailInvalidForAuthentication: boolean;
  };
  profile?: Profile;
  sessionValidationState: 'SHOULD_VALIDATE' | 'VALIDATING' | 'VALIDATED';
  profileProperties: Record<string, string>;
  privateProperties: Record<string, string>;
  profileStatistics: UserStatistics;
  passwordResetToken?: string;
  episodeJoined?: string;
  isLoggedIn: boolean;
  isLoggingOut: boolean;
  registrationProfile?: Record<string, string | undefined>;
  emailFieldStorage?: string;
}

export const initialUserState: UserReducer = {
  sessionValidationState: 'SHOULD_VALIDATE',
  profileProperties: {},
  privateProperties: {},
  profileStatistics: {},
  isLoggedIn: false,
  isLoggingOut: false,
};

export const userReducer: Reducer<
  UserReducer,
  UserActions | SocialActions | ScheduleActions | EpisodeActions | GameActions | RehydrateAction
> = (state = initialUserState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const user = action.payload && (action.payload as AppState).user;

      return {
        ...state,
        ...user,
        sessionValidationState: 'SHOULD_VALIDATE',
        isLoggingOut: false,
      };

    case '@user/CLEAR_USER':
      return {
        ...initialUserState,
        isLoggingOut: state.isLoggingOut,
      };

    case '@user/STORE_USER':
      return {
        ...state,
        ...action.payload,
        profile: {
          ...state.profile,
          email: action.payload.loginCredentialStatus?.email || state.profile?.email,
          ...action.payload.profile,
          ...action.payload.profile?.privateProperties,
        },
      };

    case '@user/STORE_USER_PROFILE':
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
          ...action.payload?.privateProperties,
        },
      };

    case '@user/SET_VALIDATION_STATE':
      return {
        ...state,
        sessionValidationState: action.payload,
      };

    case '@user/STORE_USER_PROFILE_STATISTICS':
      return {
        ...state,
        profileStatistics: normalize(action.payload, 'category'),
      };

    case '@user/STORE_PASSWORD_RESET_TOKEN':
      return {
        ...state,
        passwordResetToken: action.payload,
      };

    case '@user/EPISODE_JOINED': {
      return {
        ...state,
        episodeJoined: action.payload,
      };
    }

    case '@game/LEAVE_GAME':
    case '@episodeSchedule/CLEAR':
    case '@episode/CLEAR_EPISODE':
      return {
        ...state,
        episodeJoined: undefined,
      };

    case '@user/SET_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: action.payload,
      };

    case '@user/SET_LOGGING_OUT':
      return {
        ...state,
        isLoggingOut: action.payload,
      };

    case '@user/UPDATE_REGISTRATION_PROFILE':
      return {
        ...state,
        registrationProfile: getRegistrationFields(action.payload),
      };

    case '@user/UPDATE_EMAIL_FIELD_STORAGE':
      return {
        ...state,
        emailFieldStorage: action.payload,
      };

    default:
      return state;
  }
};
