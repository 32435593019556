import { Api } from 'api/pttv';
import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkAction as ReduxThunkAction } from 'redux-thunk';
import { Bridge } from 'types/bridge';

/**
 * Action helpers
 */

export interface Action<Type extends string> {
  type: Type;
}

export interface ActionWithPayload<Type extends string, Payload> extends Action<Type> {
  type: Type;
  payload: Payload;
}

export const createAction = <Type extends string>(type: Type): Action<Type> => ({
  type,
});

export const createActionWithPayload = <Type extends string, Payload>(
  type: Type,
  payload: Payload,
): ActionWithPayload<Type, Payload> => ({
  type,
  payload,
});

export type ThunkExtraArgument = {
  api: Api;
  bridge: Bridge;
};

export type ThunkAction<R = void> = ReduxThunkAction<R, AppState, ThunkExtraArgument, AnyAction>;
