import { Route } from '@blockle/router';
import React from 'react';
import DashboardScreen from 'screens/Dashboard';
import ForgotPasswordScreen from 'screens/ForgotPassword';
import MissingRouteScreen from 'screens/MissingRoute';
import PracticeGameScreen from 'screens/PracticeGame';
import PreviewScreen from 'screens/Preview';
import ProfileOverviewScreen from 'screens/ProfileOverview';
import SignUpScreen from 'screens/SignUp';
import StatisticsScreen from 'screens/Statistics';
import StyleGuideScreen from 'screens/StyleGuide';

const ApplicationRoutesScreen = () => {
  return (
    <>
      <Route path="/" exact render={(match) => match && <DashboardScreen />} />
      <Route path="/practice" render={(match) => match && <PracticeGameScreen />} />
      <Route path="/profileoverview" render={(match) => match && <ProfileOverviewScreen />} />
      <Route path="/statistics" render={(match) => match && <StatisticsScreen />} />
      <Route path="/sign-up" render={(match) => match && <SignUpScreen />} />
      <Route
        path="/forgot-password"
        render={(match) => match && <ForgotPasswordScreen showMenuToggle />}
      />

      {__DEV__ && (
        <>
          <Route path="/styleguide" exact render={(match) => match && <StyleGuideScreen />} />
          <Route path="/preview" exact render={(match) => match && <PreviewScreen />} />
        </>
      )}

      <Route noMatch render={(match) => match && <MissingRouteScreen />} />
    </>
  );
};

export default React.memo(ApplicationRoutesScreen);
