import Box from 'components/Box';
import { cx } from 'cx';
import React, { FC } from 'react';
import './Coin.css';

interface Props {
  glow?: boolean;
  size?: 'medium' | 'large' | 'xlarge';
  value: number;
}

const Coin: FC<Props> = ({ glow = false, size = 'medium', value }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={cx('Coin', `size-${size}`, glow && `is-glow`)}
    >
      <span className={cx('Coin-text', `points-size-${value < 1000 ? 'default' : 'small'}`)}>
        {value}
      </span>
    </Box>
  );
};

export default Coin;
