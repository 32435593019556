import { useRaf } from 'hooks/useRaf';
import React, { FC, useRef } from 'react';
import { getClientTime } from 'utils/clientTime';
import LogoBackground from '../../public/images/logo-background.png';
import './LogoProgress.css';

interface UpdateOptions {
  ref: React.RefObject<HTMLDivElement>;
  startTime: number;
  endTime: number;
  onDone: () => void;
}

const image = new Image();
image.src = LogoBackground;

function update({ ref, startTime, endTime, onDone: done }: UpdateOptions) {
  const now = getClientTime();
  const duration = endTime - startTime;
  const passedTime = now - startTime;
  const progressCalculation = passedTime / duration;
  const progress = progressCalculation >= 1 ? 1 : progressCalculation;

  if (!ref.current || progress < 0) {
    return;
  }

  const cols = 12;
  const rows = 11;
  const width = (1404 * 0.769230769) / cols;
  const height = (1265 * 0.769230769) / rows;
  const frames = cols * rows - 7;

  const frame = Math.floor(progress * frames);
  const row = Math.floor(frame / cols);
  const col = Math.floor(frame % cols);

  const position = `${-col * width}px ${-row * height}px`;

  ref.current.style.backgroundPosition = position;

  // Animation done
  if (progress >= 1) {
    done();
  }
}

interface Props {
  startTime: number;
  endTime: number;
}

const LogoProgress: FC<Props> = ({ startTime, endTime }) => {
  const ref = useRef<HTMLDivElement>(null);

  useRaf((time, done) => update({ ref, startTime, endTime, onDone: done }));

  return <div ref={ref} className="LogoProgress" />;
};

export default LogoProgress;
