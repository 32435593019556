import { trackPageView } from 'actions/analyticsActions';
import { emptyCurrentScheduleEvent } from 'actions/episodeScheduleActions';
import Box from 'components/Box';
import Countdown from 'components/Countdown';
import Heading from 'components/Heading/Heading';
import MenuToggle from 'components/MenuToggle';
import Stack from 'components/Stack';
import TopBar from 'components/TopBar';
import InterstitialImage from 'public/images/interstitial.jpg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCommercialTime } from 'selectors/episodeSelectors';
import { getClientTime } from 'utils/clientTime';

function getTimeLeft(date: number | null) {
  if (!date) {
    return 0;
  }

  const now = getClientTime();

  return (date - now) / 1000;
}

const CommercialBreakScreen = () => {
  useEffect(() => {
    dispatch(trackPageView('commercial'));
    dispatch(emptyCurrentScheduleEvent());
  }, []);

  const commercialTime = useSelector(getCommercialTime);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<number>(commercialTime || 0);

  useEffect(() => {
    if (!commercialTime) {
      return;
    }

    const timeLeft = getTimeLeft(commercialTime);
    setTimeLeft(timeLeft);

    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(commercialTime));
    }, 1000);

    return () => clearTimeout(timer);
  }, [commercialTime]);

  return (
    <>
      <TopBar logo left={<MenuToggle />} />
      <Box paddingY="large">
        <Stack spacing="xlarge">
          <Heading title={t('Commercial break')} subTitle={t('The show will continue shortly.')} />

          {timeLeft > 0 && <Countdown timeLeft={timeLeft} />}

          <img
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
            src={InterstitialImage}
          />
        </Stack>
      </Box>
    </>
  );
};

export default CommercialBreakScreen;
