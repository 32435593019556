import Box from 'components/Box';
import { cx } from 'cx';
import { useAnimationState } from 'hooks/useAnimationState';
import { useLayer } from 'hooks/useLayer';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './Dialog.css';

type Props = {
  onRequestClose: () => void;
  open?: boolean;
  render: () => JSX.Element | string | null;
};

const Dialog = ({ onRequestClose, open = false, render }: Props) => {
  const [state, close] = useAnimationState(open);
  const layer = useLayer();

  useEffect(() => {
    if (!state.open) {
      return;
    }

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    };

    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [state.open]);

  if (!state.open) {
    return null;
  }

  const onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === 'DialogBack-Leave') {
      close();
    }
  };

  const dialog = (
    <div className="DialogContainer">
      <div
        className={cx('DialogBack', state.leave && 'is-leave')}
        onClick={onRequestClose}
        onAnimationEnd={onAnimationEnd}
      />
      <Box
        component="dialog"
        open
        className={cx('Dialog', state.leave && 'is-leave')}
        position="relative"
        paddingX="medium"
        paddingY="small"
      >
        {render()}
      </Box>
    </div>
  );

  return createPortal(dialog, layer()) as JSX.Element;
};

export default Dialog;
