import {
  resumePracticeGame,
  startNextPracticeGame,
  startPracticeGame,
} from 'actions/practiceScheduleActions';
import Button from 'components/Button';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hasOpenPracticeGame } from 'selectors/practiceScheduleSelectors';

interface Props {
  onBack: () => void;
}

// TODO PracticeGameDialogScreen
const PracticeGameStartScreen: FC<Props> = ({ onBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canContinuePreviousGame = useSelector(hasOpenPracticeGame);

  return (
    <Stack spacing="medium">
      <Text
        component="h2"
        color="secondary"
        fontSize="large"
        fontWeight="medium"
        textAlign="center"
      >
        {t('Trainingsmodus')}
      </Text>

      {canContinuePreviousGame ? (
        <Stack spacing="medium">
          <Text color="black" fontSize="small" textAlign="center">
            {t('As with the Quizmaster broadcast, you answer questions 5 subject areas.')}
          </Text>
          <Text color="black" fontSize="small" textAlign="center">
            {t('The points correspond to the prize money of the real TV show!')}
          </Text>

          <Stack spacing="xsmall" align="center">
            <Button onClick={() => dispatch(resumePracticeGame())}>{t('Resume game')}</Button>
            <FlatButton onClick={() => dispatch(startNextPracticeGame())}>
              {t('Start a new game')}
            </FlatButton>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing="medium">
          <Text color="black" fontSize="small" textAlign="center">
            {t('As with the Quizmaster broadcast, you answer questions 5 subject areas.')}
          </Text>
          <Text color="black" fontSize="small" textAlign="center">
            {t('The points correspond to the prize money of the real TV show!')}
          </Text>

          <Stack spacing="xsmall" align="center">
            <Button onClick={() => dispatch(startPracticeGame())}>{t('Start')}</Button>
            <FlatButton onClick={onBack}>{t('Back')}</FlatButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PracticeGameStartScreen;
