import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getSessionValidationState,
  getShouldConfirmAuthenticationInformation,
} from 'selectors/userSelectors';

export const useConfirmAuthenticationInformation = () => {
  const confirmAuthenticationFirstItem = useSelector(getShouldConfirmAuthenticationInformation);
  const sessionValidationState = useSelector(getSessionValidationState);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    if (!confirmAuthenticationFirstItem || sessionValidationState !== 'VALIDATED') {
      return;
    }

    if (confirmAuthenticationFirstItem === 'confirmed') {
      setShowVerify(false);
      return;
    }

    setShowVerify(true);
  }, [confirmAuthenticationFirstItem, sessionValidationState]);

  return {
    verifyChecked:
      sessionValidationState === 'VALIDATED' && confirmAuthenticationFirstItem ? true : false,
    showVerify,
  };
};
