import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';

interface Props {
  title: string;
  subTitle?: string;
}

const Heading: FC<Props> = ({ title, subTitle }) => (
  <Stack spacing="small" align="center" paddingX="gutter">
    <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
      {title}
    </Text>
    {!!subTitle && (
      <Text component="p" color="white" fontSize="large" textAlign="center">
        {subTitle}
      </Text>
    )}
  </Stack>
);

export default Heading;
