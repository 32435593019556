import { AppState } from 'reducers';

export const getGoodscoreSubmitted = (state: AppState) =>
  state.episode &&
  state.goodscore.goodscoreSubmittedCode &&
  state.goodscore.goodscoreSubmittedCode === state.episode.episodeCode;

export const getJackpot = (state: AppState) =>
  state.episode &&
  state.goodscore.showJackpot &&
  state.goodscore.showJackpot === state.episode.episodeCode;
