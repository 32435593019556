import Box from 'components/Box';
import CategoryCard from 'components/CategoryCard';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStoredProfileStatistics } from 'selectors/userSelectors';

const ProfileOverviewBestCategories = () => {
  const { t } = useTranslation();

  const profileStatistics = useSelector(getStoredProfileStatistics);
  const profileStatisticsEntries = Object.keys(profileStatistics);

  return (
    <Box>
      {profileStatisticsEntries.length ? (
        <Stack spacing="medium" paddingTop="large" paddingX="large">
          <Text
            textAlign="center"
            fontSize="xsmall"
            textTransform="uppercase"
            color="darkGray"
            fontWeight="extraBold"
          >
            {t('Your best categories')}
          </Text>
          <Stack spacing="medium">
            {profileStatisticsEntries.map((key) => {
              const { category, points, categoryImageUrl, percentage } = profileStatistics[key];

              return (
                <CategoryCard
                  key={key}
                  categoryId={category}
                  title={category}
                  percentage={percentage}
                  points={points}
                  imageUrl={categoryImageUrl}
                />
              );
            })}
          </Stack>
        </Stack>
      ) : (
        <Stack spacing="xsmall" align="center" paddingY="xlarge">
          <Text color="darkGray" fontWeight="extraBold" fontSize="xsmall" textTransform="uppercase">
            {t("You don't have the best category yet")}
          </Text>
          <Text color="secondary" fontSize="xlarge" fontWeight="bold">
            {t('Continue to play!')}
          </Text>
        </Stack>
      )}
    </Box>
  );
};

export default ProfileOverviewBestCategories;
