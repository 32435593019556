import { NativeReducer } from 'reducers/native';
import { storageGetItem, storageRemoveItem, storageSetItem } from './bridgeActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';

export const setReady = (env: NativeReducer['env']) =>
  createActionWithPayload('@native/READY', env);

export const setSuspended = (suspended: boolean) =>
  createActionWithPayload('@native/SUSPENDED', suspended);

export const setStoredAuthenticationToken = (token: string) =>
  storageSetItem({ key: 'authenticationToken', value: token });

export const fetchStoredAuthenticationToken = (): ThunkAction<void> => (dispatch) => {
  dispatch(setAuthenticationTokenFetch());
  dispatch(storageGetItem({ key: 'authenticationToken' }));
};

export const removeStoredAuthenticationToken = (): ThunkAction<void> => (dispatch) => {
  dispatch(storageRemoveItem({ key: 'authenticationToken' }));
  dispatch(storeAuthenticationToken(undefined));
};

export const storeAuthenticationToken = (token: string | undefined) =>
  createActionWithPayload('@native/STORE_AUTHENTICATION_TOKEN', token);

export const setAuthenticationTokenFetch = () =>
  createAction('@native/SET_AUTHENTICATION_TOKEN_FETCH');

export type NativeActions = ReturnType<
  | typeof setReady
  | typeof setSuspended
  | typeof storeAuthenticationToken
  | typeof setAuthenticationTokenFetch
>;
