import Box from 'components/Box';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import { cx } from 'cx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  imageUrl: string;
  isClosable: boolean;
  leave: boolean;
  onClose: () => void;
}

const PromoOverlay: FC<Props> = ({ imageUrl, isClosable, leave, onClose }) => {
  const { t } = useTranslation();

  const baseClass = 'Overlay';

  return (
    <Box
      component="dialog"
      open
      className={cx(`${baseClass}-dialog`, leave && 'is-leave')}
      position="relative"
    >
      <img src={imageUrl} />

      {isClosable && (
        <Box
          component="button"
          className={`${baseClass}-close`}
          role="button"
          display="flex"
          padding="small"
          paddingRight="large"
          onClick={onClose}
        >
          <Stack spacing="xsmall" horizontal align="center">
            <Text color="white" fontSize="small">
              {t('Close')}
            </Text>
            <Icon name="close" label="Close" color="white" size="medium" />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default PromoOverlay;
