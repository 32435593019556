import { GameActions } from 'actions/gameActions';
import { Reducer } from 'redux';
import { GameReducer } from 'types/game';

export const initialState: GameReducer = {
  debugOverlayVisible: false,
  termsVersion: undefined,
  gameType: undefined,
};

export const gameReducer: Reducer<GameReducer, GameActions> = (state = initialState, action) => {
  switch (action.type) {
    case '@game/JOIN_GAME_TYPE':
      return {
        ...state,
        gameType: action.payload,
      };

    case '@game/LEAVE_GAME':
      return {
        ...state,
        gameType: undefined,
      };

    case '@game/SET_TERMS_VERSION':
      return {
        ...state,
        termsVersion: action.payload,
      };

    case '@game/TOGGLE_DEBUG_OVERLAY':
      return {
        ...state,
        debugOverlayVisible: !state.debugOverlayVisible,
      };

    default:
      return state;
  }
};
