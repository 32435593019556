import { trackPageView } from 'actions/analyticsActions';
import Box from 'components/Box';
import Coin from 'components/Coin';
import MenuToggle from 'components/MenuToggle';
import NextQuestionLoader from 'components/NextQuestionLoader';
import Panel from 'components/Panel';
import Points from 'components/Points';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import TopBar from 'components/TopBar';
import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getCategoryPointsByEventId } from 'selectors/answersSelectors';
import { getCategoryById } from 'selectors/categorySelectors';
import { getCategory, getQuestionIndexByEventId } from 'selectors/eventsSelectors';
import { getCategoryPointsByEventId as getPracticeCategoryPointsByEventId } from 'selectors/practiceAnswerSelectors';
import { CategoryStartEvent, PracticeQuestionEvent, TriviaEvent } from 'types/events';

interface Props {
  episodeCode: string;
  event: CategoryStartEvent | PracticeQuestionEvent | TriviaEvent;
  questionFinished?: boolean;
  startTime?: number;
}

const CategoryInteraction: FC<Props> = ({ event, episodeCode, startTime, questionFinished }) => {
  const isPracticeGame = episodeCode === 'practice';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackPageView('idle'));
  }, []);

  const category = useSelector((state: AppState) =>
    getCategory(state, event.content.categoryId, isPracticeGame),
  );
  const categoryFallback = useSelector((state: AppState) =>
    getCategoryById(state, category?.categoryId as string),
  );
  const points = useSelector((state: AppState) =>
    isPracticeGame
      ? getPracticeCategoryPointsByEventId(state, event.eventId)
      : getCategoryPointsByEventId(state, event.eventId),
  );
  const { index, total } = useSelector((state: AppState) =>
    getQuestionIndexByEventId(state, event.eventId),
  );

  if (!category) {
    return null;
  }

  const isLastQuestion = index === total;
  const regularHeaderText = questionFinished
    ? t('This was question {{question}}', { question: index })
    : t('Get ready');

  return (
    <>
      <TopBar logo left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Spacer spacing="small" />

      <Panel>
        <Stack spacing="xlarge">
          <Stack spacing="xsmall" paddingX="large">
            <Text component="h1" textAlign="center" color="secondary" fontSize="xlarge">
              {!isLastQuestion ? regularHeaderText : t('This was the final question of the show')}
            </Text>
            <Text textAlign="center" fontSize="small">
              {t('Question {{index}} / {{total}}', { index, total })}
            </Text>
            {!isLastQuestion && (
              <Box display="flex" justifyContent="center">
                <Tooltip>
                  <Stack spacing="xsmall" horizontal align="center">
                    <Text fontSize="medium" color="black">
                      {t('Every correct answer brings')}
                    </Text>
                    <Coin size="medium" value={category.answerScore} />
                  </Stack>
                </Tooltip>
              </Box>
            )}
          </Stack>

          <Stack spacing="xsmall" align="center">
            <NextQuestionLoader
              categoryText={category.text}
              categoryUrl={category.imageUrl || categoryFallback.imageUrl}
              startTime={startTime}
            />
            <Text color="secondary" fontSize="large">
              {category.text}
            </Text>
            <Text fontSize="medium">
              <Trans>
                You have <strong>{{ points }} points</strong> in this category
              </Trans>
            </Text>
          </Stack>
        </Stack>
      </Panel>
    </>
  );
};

export default CategoryInteraction;
