import { push } from 'actions/historyActions';
import PromptDialog from 'components/PromptDialog';
import React, { Component, ErrorInfo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  children: React.ReactNode;
}

interface State {
  error: boolean;
}

const ErrorBoundaryContent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleReload = () => {
    dispatch(push('/'));
    window.location.reload();
  };

  return (
    <PromptDialog
      showDialog
      showIcon
      title={t('Error')}
      message={t('Something went wrong')}
      onDialogClose={handleReload}
      onPromptTrigger={handleReload}
      promptText={t('Reload')}
    />
  );
};

class ErrorBoundary extends Component<Props, State> {
  state = {
    error: false,
  };

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(error, errorInfo);
      return;
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorBoundaryContent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
