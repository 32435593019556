import { createAction, createActionWithPayload } from './helpers';

export const setPhotoData = (data: string) => createActionWithPayload('@photo/SET_DATA', data);

export const setLoading = (loading: boolean) =>
  createActionWithPayload('@photo/SET_LOADING', loading);

export const clear = () => createAction('@photo/CLEAR');

export type PhotoActions = ReturnType<typeof setPhotoData | typeof setLoading | typeof clear>;
