import Box from 'components/Box';
import Icon, { IconNames } from 'components/Icon';
import { StyleProps } from 'hooks/useStyles';
import React from 'react';
import './IconButton.css';

interface Props {
  name: IconNames;
  label: string;
  onClick?: () => void;
  color?: StyleProps['color'];
}

const IconButton = ({ name, label, onClick, color = 'white' }: Props) => {
  return (
    <Box
      aria-label={label}
      component="button"
      className="IconButton"
      padding="xsmall"
      onClick={onClick}
      type="button"
    >
      <Icon name={name} label={label} color={color} size="large" />
    </Box>
  );
};

export default IconButton;
