import { requestDeletion } from 'actions/interUserApiActions';
import FlatButton from 'components/FlatButton';
import PromptDialog from 'components/PromptDialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface DialogProps {
  dialogTitle: string;
  dialogContent: string;
  dialogButton: string;
}

const DeleteAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<DialogProps | false>(false);

  const onSubmit = async () => {
    try {
      await dispatch(requestDeletion());
    } catch (error: any) {
      setDialog({
        dialogTitle: t('Error'),
        dialogContent: t('Something went wrong'),
        dialogButton: t('Confirm'),
      });
    }
  };

  const onClose = () => {
    setDialog(false);
  };

  return (
    <>
      <FlatButton
        onClick={() =>
          setDialog({
            dialogTitle: t('Delete account?'),
            dialogContent: t(
              'All data retained about you will be deleted. This includes your points.',
            ),
            dialogButton: t('Delete Account'),
          })
        }
      >
        {' '}
        {t('Delete Account')}
      </FlatButton>
      <PromptDialog
        promptText={dialog ? dialog.dialogButton : ''}
        onPromptTrigger={dialog && dialog.dialogTitle !== t('Error') ? onSubmit : onClose}
        showCancel={dialog && dialog.dialogTitle !== t('Error')}
        showDialog={!!dialog}
        onDialogClose={onClose}
        title={dialog ? dialog.dialogTitle : ''}
        message={dialog ? dialog.dialogContent : ''}
      />
    </>
  );
};

export default DeleteAccount;
