import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './TopTierPlayer.css';

interface Props {
  avatarUrl?: string;
  displayName: string;
  rank?: number;
  score: number;
}

const TopTierPlayer: FC<Props> = ({ avatarUrl, displayName, rank, score }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="xsmall" padding="small" data-testid="top-tier-player" align="center">
      <Box className="TopTierPlayer" paddingBottom="medium">
        {rank !== undefined && (
          <Box className="TopTierPlayer-badge" textAlign="center">
            {rank}
          </Box>
        )}
        <Avatar src={avatarUrl} />
      </Box>
      <Text color="secondary" fontSize="small" textAlign="center">
        {displayName}
      </Text>
      <Text color="black" fontSize="small" textAlign="center">
        {t('{{score}} pts', { score })}
      </Text>
    </Stack>
  );
};

export default TopTierPlayer;
