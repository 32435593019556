import { PhotoActions } from 'actions/photoActions';
import { Reducer } from 'redux';

export interface PhotoReducer {
  loading: boolean;
  imageData: null | string; // base64 string
}

export const initialState: PhotoReducer = {
  loading: false,
  imageData: null,
};

export const photoReducer: Reducer<PhotoReducer, PhotoActions> = (
  state = initialState,
  action,
): PhotoReducer => {
  switch (action.type) {
    case '@photo/SET_DATA':
      return {
        ...state,
        imageData: action.payload,
        loading: false,
      };

    case '@photo/SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case '@photo/CLEAR':
      return initialState;

    default:
      return state;
  }
};
