import { EpisodeActions } from 'actions/episodeActions';
import { Reducer } from 'redux';
import { Category } from 'types/category';
import { normalize } from 'utils/normalize';

export type CategoriesReducer = Record<string, Category>;

export const initialState: CategoriesReducer = {};

export const categoriesReducer: Reducer<CategoriesReducer, EpisodeActions> = (
  state = initialState,
  action,
): CategoriesReducer => {
  switch (action.type) {
    case '@episode/SET_EPISODE':
      return {
        ...state,
        ...normalize(action.payload?.eventCategories, 'id'),
      };

    default:
      return state;
  }
};
