import { push } from 'actions/historyActions';
import Box from 'components/Box';
import { cx } from 'cx';
import { StyleProps } from 'hooks/useStyles';
import React from 'react';
import { useDispatch } from 'react-redux';
import './Link.css';

interface Props {
  children: React.ReactNode;
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  fontSize?: StyleProps['fontSize'];
  color?: StyleProps['color'];
  underline?: boolean;
}

const Link = ({
  children,
  to,
  onClick,
  fontSize = 'small',
  color = 'white',
  underline = false,
}: Props) => {
  const dispatch = useDispatch();
  const onElementClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (to) {
      dispatch(push(to));
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box
      component={to ? 'a' : 'button'}
      href={`#${to}`}
      onClick={onElementClick}
      className={cx('Link', underline && 'has-underline')}
      color={color}
      fontSize={fontSize}
    >
      {children}
    </Box>
  );
};

export default Link;
