import { updateShowFilter } from 'actions/statisticsActions';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ToggleButton from 'components/ToggleButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StatisticsShowFilterLabels } from 'types/statistics';

interface Props {
  rankFilterName: StatisticsShowFilterLabels;
  active: boolean;
}

const StatisticsShowFilterItem: FC<Props> = ({ rankFilterName, active }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const FILTER_MAP = {
    MALE: t('Men'),
    FEMALE: t('Women'),
    FRIENDS: t('Friends'),
  };

  const updateFilter = () => {
    const newShowFilterValue = active ? null : rankFilterName;

    dispatch(updateShowFilter(newShowFilterValue));
  };

  if (!(rankFilterName in FILTER_MAP)) {
    throw new Error(`Show filter "${rankFilterName}" not found`);
  }

  return (
    <Box paddingRight="small" paddingBottom="small" data-testid="statistics-show-filter-item">
      <ToggleButton
        checked={active}
        onClick={updateFilter}
        icon={rankFilterName === 'FRIENDS' && <Icon name="facebook" color="white" size="medium" />}
      >
        {FILTER_MAP[rankFilterName]}
      </ToggleButton>
    </Box>
  );
};

export default StatisticsShowFilterItem;
