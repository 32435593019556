import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import PointsOverlay from 'components/PointsOverlay';
import Spacer from 'components/Spacer';
import TopBar from 'components/TopBar';
import React, { FC } from 'react';

const PreviewPointsOverlayScreen: FC = () => {
  return (
    <>
      <TopBar logo left={<MenuToggle />} />

      <Spacer spacing="small" />

      <Panel>
        <PointsOverlay open={true} points={1000} />
      </Panel>
    </>
  );
};

export default PreviewPointsOverlayScreen;
