import InterstitialInteraction from 'components/Interactions/InterstitialInteraction';
import React from 'react';
import { InterstitialEvent } from 'types/events';

interface Props {
  episodeCode?: string;
  event?: InterstitialEvent;
}

const PreviewRoundScreen = ({ episodeCode, event }: Props) => {
  if (!event || !episodeCode) return null;

  return <InterstitialInteraction episodeCode={episodeCode} event={event} />;
};

export default PreviewRoundScreen;
