import { getServerSync } from 'api/pttv';

export const getClientTime = () => {
  const now = Date.now();

  try {
    return getServerSync().toServerTime(now) || now;
  } catch (error) {}

  return now;
};
