import { toggleMenu as toggleMenuAction } from 'actions/menuActions';
import { fetchOverlay } from 'actions/overlayActions';
import Box from 'components/Box';
import MenuToggle from 'components/MenuToggle';
import Overlay from 'components/Overlay';
import { usePanelScroll } from 'components/Panel/usePanelScroll';
import TopBar from 'components/TopBar';
import { cx } from 'cx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJoinedGameType } from 'selectors/gameSelectors';
import { isMenuOpen } from 'selectors/menuSelectors';
import ApplicationMenuScreen from './ApplicationMenuScreen';
import ApplicationRoutesScreen from './ApplicationRoutesScreen';
import './ApplicationScreen.css';

const ApplicationScreen = () => {
  const dispatch = useDispatch();
  const toggleMenu = () => dispatch(toggleMenuAction());
  const toggleMenuWithDelay = () => requestAnimationFrame(toggleMenu);
  const joinedGameType = useSelector(getJoinedGameType);
  const menuOpen = useSelector(isMenuOpen);

  usePanelScroll(menuOpen);

  useEffect(() => {
    dispatch(fetchOverlay());
  }, []);

  return (
    <>
      <Box
        width="full"
        height="full"
        position="relative"
        overflow={menuOpen ? 'hidden' : undefined}
      >
        <TopBar left={<MenuToggle toggleType="close-menu" />} title="Menu" right={null} />
        <ApplicationMenuScreen onClick={toggleMenuWithDelay} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        className={cx(
          'ApplicationScreen-overlay',
          menuOpen && 'is-hidden',
          joinedGameType && 'is-game-active', // Game related screens change background
        )}
      >
        <ApplicationRoutesScreen />
      </Box>
      <Overlay />
    </>
  );
};

export default ApplicationScreen;
