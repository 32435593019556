import { Router } from '@blockle/router';
import ErrorBoundary from 'components/ErrorBoundary';
import { history } from 'config/history';
import i18n from 'config/i18next';
import { configureStore } from 'config/store';
import { SoundProvider } from 'context/SoundProvider';
import React, { FC, StrictMode, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configureStore();

interface Props {
  children: React.ReactNode;
}

const AppShell: FC<Props> = ({ children }) => {
  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <SoundProvider>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <Suspense fallback={null}>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </Suspense>
              </Router>
            </PersistGate>
          </SoundProvider>
        </Provider>
      </I18nextProvider>
    </StrictMode>
  );
};

export default AppShell;
