import Box from 'components/Box';
import Divider from 'components/Divider';
import { cx } from 'cx';
import { PickStyleProps, ResponsiveStyleProp, StyleProps } from 'hooks/useStyles';
import React, { Children, Fragment, ReactNode } from 'react';
import './Stack.css';

interface BaseProps
  extends PickStyleProps<
    | 'padding'
    | 'paddingX'
    | 'paddingY'
    | 'paddingTop'
    | 'paddingRight'
    | 'paddingBottom'
    | 'paddingLeft'
    | 'flexGrow'
  > {
  children: ReactNode;
  component?: 'div' | 'ol' | 'ul';
  horizontal?: boolean;
  spacing: StyleProps['padding'];
  className?: string;
  role?: string;
  divider?: boolean;
  style?: React.CSSProperties;
}

interface WithFlexProps extends BaseProps {
  align?: never;
  alignItems?: StyleProps['alignItems'];
  justifyContent?: StyleProps['justifyContent'];
}

interface WithoutFlexProps extends BaseProps {
  align: ResponsiveStyleProp<'flex-start' | 'center' | 'flex-end'>;
  alignItems?: never;
  justifyContent?: never;
}

type Props = WithFlexProps | WithoutFlexProps;

const Stack = ({
  align,
  children,
  component = 'div',
  spacing,
  horizontal = false,
  className,
  divider = false,
  ...props
}: Props) => {
  const items = Children.toArray(children);
  const itemsLength = items.length - 1;

  if (divider && horizontal) {
    throw new Error('<Stack> - divider not supported for horizontal layout');
  }

  let justifyContent = props.justifyContent;
  let alignItems = props.alignItems;

  if (align) {
    if (!horizontal) justifyContent = align;
    alignItems = align;
  }

  return (
    <Box
      component={component}
      className={cx('Stack', className)}
      display="flex"
      flexDirection={horizontal ? 'row' : 'column'}
      justifyContent={justifyContent}
      alignItems={alignItems}
      negativeMarginTop={horizontal ? undefined : spacing}
      negativeMarginLeft={horizontal ? spacing : undefined}
      {...props}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          <Box
            paddingTop={horizontal ? undefined : spacing}
            paddingLeft={horizontal ? spacing : undefined}
          >
            {item}
          </Box>
          {divider && !horizontal && itemsLength !== index && (
            <Box paddingTop={spacing}>
              <Divider />
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Stack;
