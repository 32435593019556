import { PracticeAnswerActions } from 'actions/practiceAnswerActions';
import { PracticeActions } from 'actions/practiceScheduleActions';
import { Reducer } from 'redux';

export interface PracticeAnswer {
  eventId: string;
  episodeCode: string;
  answerCode: string;
}

export type PracticeAnswersReducer = Record<string, PracticeAnswer>;

export const initialState: PracticeAnswersReducer = {};

export const practiceAnswersReducer: Reducer<
  PracticeAnswersReducer,
  PracticeAnswerActions | PracticeActions
> = (state = initialState, action) => {
  switch (action.type) {
    case '@practiceSchedule/RESET':
      return initialState;

    case '@practiceAnswer/set':
      return {
        ...state,
        [action.payload.eventId]: action.payload,
      };

    default:
      return state;
  }
};
