import { getClientTime } from './clientTime';

type Callback = () => void;
type QueueItem = { time: number; callback: Callback };
type Queue = Record<string, QueueItem>;
type Unsubscribe = () => void;

export default class Cron {
  private queue: Queue = {};
  private running = false;

  public schedule(id: string, time: number, callback: Callback): Unsubscribe {
    this.queue[id] = { time, callback };

    if (!this.running) {
      this.check();
    }

    return () => {
      delete this.queue[id];
    };
  }

  public clearAll() {
    this.queue = {};
  }

  private remove(id: string) {
    delete this.queue[id];
  }

  private check = () => {
    const keys = Object.keys(this.queue);
    const now = getClientTime();

    this.running = true;

    keys.forEach((id) => {
      if (this.queue[id]) {
        const { time, callback } = this.queue[id];

        if (now >= time) {
          this.remove(id);

          callback();
        }
      }
    });

    if (!Object.keys(this.queue).length) {
      this.running = false;
      return;
    }

    setTimeout(this.check, 100);
  };
}
