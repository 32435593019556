import { useHistory } from '@blockle/router';
import { getProfileStatistics } from 'actions/qzmProfileApiActions';
import AvatarSelectIcon from 'components/AvatarSelectIcon';
import Button from 'components/Button';
import DeleteAccount from 'components/DeleteAccount';
import MenuToggle from 'components/MenuToggle';
import PanelFloatingCard from 'components/Panel/PanelFloatingCard';
import PlayerCard from 'components/PlayerCard';
import SignOutDialog from 'components/SignOutDialog';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import TopBar from 'components/TopBar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getStoredProfile } from 'selectors/userSelectors';
import ProfileOverviewBestCategories from './ProfileOverviewBestCategories';

const ProfileOverviewDashboardScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [showSignOutDialog, setShowSignOutDialog] = useState(false);

  useEffect(() => {
    dispatch(getProfileStatistics());
  }, []);

  const userProfile = useSelector(getStoredProfile);

  if (!userProfile) return null;

  const { gender, firstName, lastName, email, ageGroup, avatarUrl, location } = userProfile;

  return (
    <>
      <TopBar title={t('MyProfile')} left={<MenuToggle />} />

      <Spacer spacing="small" />

      <PanelFloatingCard
        card={
          <PlayerCard
            avatarUrl={avatarUrl}
            ageGroup={ageGroup}
            email={email}
            gender={gender}
            firstName={firstName}
            lastName={lastName}
            location={location}
          />
        }
        onCardClick={() => push('/profileoverview/edit')}
        cardIcon={<AvatarSelectIcon to="/profileoverview/edit" />}
      >
        <ProfileOverviewBestCategories />
        <Stack spacing="xsmall" align="center" paddingTop="xlarge">
          <Button onClick={() => setShowSignOutDialog(true)}>{t('Sign Out')}</Button>
          <DeleteAccount />
        </Stack>
      </PanelFloatingCard>
      <SignOutDialog
        showSignOutDialog={showSignOutDialog}
        setShowSignOutDialog={setShowSignOutDialog}
      />
    </>
  );
};

export default ProfileOverviewDashboardScreen;
