import { SocialActions } from 'actions/socialActions';
import { UserActions } from 'actions/userActions';
import { AppState } from 'reducers';
import { Reducer } from 'redux';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
import { QzmScoreUser } from 'types/api/episodeApi';
import { FriendsFetchState, SocialSignInState } from 'types/social';

export type SocialReducer = {
  token?: string;
  type?: SocialProviders;
  externalId?: string;
  socialSignInState: SocialSignInState;
  friendIds?: string[];
  friendsFetchStatus: FriendsFetchState;
  friendScoreEpisode?: string;
  friendScores?: QzmScoreUser[];
  error?: {
    type: string;
    message: string;
  };
  socialTriggered: SocialProviders | null;
  socialErrored: string | null;
  waitForApple?: boolean;
  isVerifying?: boolean;
};

export const initialState: SocialReducer = {
  socialSignInState: 'WAITING',
  friendsFetchStatus: 'WAITING',
  socialTriggered: null,
  socialErrored: null,
};

export const socialReducer: Reducer<
  SocialReducer,
  SocialActions | UserActions | RehydrateAction
> = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE: {
      const storedState = action.payload && (action.payload as AppState).social;
      if (!storedState) {
        return state;
      }
      const { socialSignInState } = storedState;
      if (socialSignInState === 'NO_USER_FOUND') {
        return {
          ...state,
          ...storedState,
          socialSignInState: 'WAITING',
          socialTriggered: initialState.socialTriggered,
          socialErrored: initialState.socialErrored,
          waitForApple: initialState.waitForApple,
          isVerifying: initialState.isVerifying,
        };
      }
      return {
        ...state,
        ...storedState,
        socialTriggered: initialState.socialTriggered,
        socialErrored: initialState.socialErrored,
        waitForApple: initialState.waitForApple,
        isVerifying: initialState.isVerifying,
      };
    }

    case '@social/CLEAR':
    case '@user/CLEAR_USER': {
      return initialState;
    }

    case '@social/ADD_SIGN_IN_TYPE': {
      return {
        ...state,
        type: action.payload,
      };
    }

    case '@social/ADD_SIGN_IN_RESPONSE': {
      const { token, userId } = action.payload;

      const newState = {
        ...state,
        token,
      };

      if (userId) {
        newState.externalId = userId;
      }

      return newState;
    }

    case '@social/SET_EXTERNAL_USER_ID': {
      return {
        ...state,
        externalId: action.payload,
      };
    }

    case '@social/SET_SOCIAL_SIGN_IN_STATE': {
      return {
        ...state,
        socialSignInState: action.payload,
      };
    }

    case '@social/STORE_FRIEND_IDS': {
      return {
        ...state,
        friendIds: action.payload,
        friendsFetchStatus: 'SUCCESSFUL',
      };
    }

    case '@social/STORE_FRIEND_EPISODE': {
      return {
        ...state,
        friendScoreEpisode: action.payload,
        friendsFetchStatus: 'WAITING',
      };
    }

    case '@social/SET_SOCIAL_TRIGGERED': {
      return {
        ...state,
        socialTriggered: action.payload,
      };
    }

    case '@social/SET_SOCIAL_ERRORED': {
      return {
        ...state,
        socialErrored: action.payload,
      };
    }

    case '@social/WAIT_FOR_APPLE': {
      return {
        ...state,
        waitForApple: action.payload,
      };
    }

    case '@social/SET_IS_VERIFYING': {
      return {
        ...state,
        isVerifying: action.payload,
      };
    }

    default:
      return state;
  }
};
