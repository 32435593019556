import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getTotalPoints } from 'selectors/answersSelectors';
import './Points.css';

interface Props {
  episodeCode: string;
}

const Points: FC<Props> = ({ episodeCode }) => {
  const { t } = useTranslation();
  const points = useSelector((state: AppState) => getTotalPoints(state, episodeCode));

  return (
    <div className="Points">
      <Text color="white" fontSize="medium" fontWeight="semiBold">
        {t('{{points}} points', { points })}
      </Text>
    </div>
  );
};

export default Points;
