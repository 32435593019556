import { AnswerActions } from 'actions/answerActions';
import { GameActions } from 'actions/gameActions';
import { AppState } from 'reducers';
import { Reducer } from 'redux';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
import { AnswerValue } from 'types/score';

type Answer = Record<string, AnswerValue>;

export type AnswersReducer = {
  resolvedAnswers: Record<string, Answer>;
  pendingAnswers: { [key: string]: Record<string, Answer> };
};

export const initialState: AnswersReducer = {
  resolvedAnswers: {},
  pendingAnswers: {},
};

export const answersReducer: Reducer<
  AnswersReducer,
  AnswerActions | GameActions | RehydrateAction
> = (state = initialState, action) => {
  switch (action.type) {
    // Port old answer reducer structure into new
    case REHYDRATE: {
      const storedState = action.payload && (action.payload as AppState).answers;

      if (!storedState || Object.keys(storedState).length === 0) {
        return {
          ...state,
        };
      }

      const { resolvedAnswers, pendingAnswers, ...oldAnswers } = storedState;

      if (Object.keys(oldAnswers).length > 0) {
        return {
          ...state,
          resolvedAnswers: {
            ...state.resolvedAnswers,
            ...resolvedAnswers,
            ...oldAnswers,
          },
          pendingAnswers: {
            ...state.pendingAnswers,
            ...pendingAnswers,
          },
        };
      }

      return storedState;
    }

    // Store pending answer before results appear
    case '@answers/STORE_PENDING_ANSWER': {
      const { episodeCode, value, eventId, eventType } = action.payload;
      if (
        state.pendingAnswers[episodeCode] &&
        state.pendingAnswers[episodeCode][eventType] &&
        state.pendingAnswers[episodeCode][eventType][eventId]
      ) {
        return state;
      }

      return {
        ...state,
        pendingAnswers: {
          ...state.pendingAnswers,
          [episodeCode]: {
            ...state.pendingAnswers[episodeCode],
            [eventType]: {
              ...(state.pendingAnswers[episodeCode]
                ? state.pendingAnswers[episodeCode][eventType]
                : {}),
              [eventId]: value,
            },
          },
        },
      };
    }

    // Move all pending answers of a type to resolved
    case '@answers/RESOLVE_PENDING_ANSWERS': {
      const { episodeCode, eventType } = action.payload;
      if (!(state.pendingAnswers[episodeCode] && state.pendingAnswers[episodeCode][eventType])) {
        return state;
      }

      const newResolvedAnswers = { ...state.pendingAnswers[episodeCode][eventType] };

      delete state.pendingAnswers[episodeCode][eventType];

      return {
        ...state,
        resolvedAnswers: {
          ...state.resolvedAnswers,
          [episodeCode]: {
            ...state.resolvedAnswers[episodeCode],
            ...newResolvedAnswers,
          },
        },
      };
    }

    default:
      return state;
  }
};
