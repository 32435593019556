import { joinEpisode } from 'actions/userActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import Dialog from 'components/Dialog';
import QuizMasterReveal from 'components/QuizMasterReveal';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PracticeGameStartScreen from 'screens/PracticeGame/PracticeGameStartScreen';
import { canJoinEpisode } from 'selectors/episodeSelectors';
import { getClientTime } from 'utils/clientTime';

const SHOW_TRAININGMODE_BUTTON = 60 * 5;
const HOUR = 60 * 60;
const DAY = 24 * HOUR;
const DAY_REFRESH_TIMEOUT = HOUR * 1000;
const QUIZMASTER_VISIBLE = 15 * HOUR;

function getTimeLeft(date: number | null) {
  if (!date) {
    return 0;
  }

  const now = getClientTime();

  return (date - now) / 1000;
}

interface Props {
  date: number | null;
}

const EpisodeCountdown: FC<Props> = ({ date }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const broadcastStarted = useSelector(canJoinEpisode);

  const [showPracticeDialog, setShowPracticeDialog] = useState(false);
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(date));

  useEffect(() => {
    const timeLeft = getTimeLeft(date);
    setTimeLeft(timeLeft);

    const timer = setInterval(
      () => {
        setTimeLeft(getTimeLeft(date));
      },
      timeLeft < DAY ? 1000 : DAY_REFRESH_TIMEOUT,
    );

    return () => clearTimeout(timer);
  }, [date]);

  return (
    <>
      <Box paddingTop="large" paddingBottom="medium">
        <Stack spacing="medium" align="center">
          {timeLeft > 0 && !broadcastStarted ? (
            <Stack spacing="small" align="center">
              <Text fontSize="medium" color="primary" textTransform="uppercase" fontWeight="bold">
                {t('The next tv show starts in:')}
              </Text>
              <Countdown timeLeft={timeLeft} />
            </Stack>
          ) : (
            <Stack spacing="xlarge" align="center" paddingBottom="small" paddingX="large">
              <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
                {!broadcastStarted
                  ? t('The show has started - tune in now!')
                  : t('The show has started - play along now!')}
              </Text>
            </Stack>
          )}
          {timeLeft >= SHOW_TRAININGMODE_BUTTON && !broadcastStarted && (
            <>
              <Button onClick={() => setShowPracticeDialog(true)}>{t('Trainingsmodus')}</Button>
              <Spacer spacing="small" />
            </>
          )}
        </Stack>
        {timeLeft < QUIZMASTER_VISIBLE && (
          <>
            <Spacer spacing="medium" />
            <QuizMasterReveal />
          </>
        )}
        {broadcastStarted && (
          <Stack spacing="xsmall" align="center" paddingTop="medium">
            <Button onClick={() => dispatch(joinEpisode())}>{t('Play now!')}</Button>
          </Stack>
        )}
      </Box>

      {/* Practice game dialog */}
      <Dialog
        open={showPracticeDialog}
        onRequestClose={() => setShowPracticeDialog(false)}
        render={() => <PracticeGameStartScreen onBack={() => setShowPracticeDialog(false)} />}
      />
    </>
  );
};

export default EpisodeCountdown;
