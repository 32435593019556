interface ErrorProp {
  data?: {
    error?: {
      title?: string;
      message?: string;
      code?: string;
      statusCode?: number;
      type?: string;
    };
  };
}

export const getErrorCode = (error?: ErrorProp) => error?.data?.error?.code;
