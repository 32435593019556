import { FieldProps, useField } from '@blockle/form';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import { cx } from 'cx';
import React, { useEffect, useRef } from 'react';
import './Dropdown.css';

type Props = FieldProps<string> & {
  children: React.ReactNode;
  placeholder?: string;
  onChange?: (value: string) => void;
  errorMessages?: { [key in ErrorCodes]?: React.ReactNode };
  label: string;
  disabled?: boolean;
} & ValidationOptions;

type ErrorCodes = 'required' | 'patternMismatch' | 'minLength' | 'maxLength';

type ValidationOptions = {
  required?: boolean;
};

const validate =
  ({ required }: ValidationOptions) =>
  (value: string) => {
    if (required && !value) {
      return 'required';
    }

    return null;
  };

const Dropdown = ({
  name,
  onChange,
  placeholder,
  required,
  value,
  errorMessages = {},
  children,
  label,
  disabled,
}: Props) => {
  const ref = useRef<HTMLSelectElement>(null);
  const field = useField(name, {
    value: value || '',
    validate: validate({ required }),
    onChange,
  });

  const invalid = field.invalid && (field.dirty || field.touched);

  useEffect(() => {
    if (ref.current) {
      const { value } = ref.current;

      if (value && value !== field.value) {
        field.setValue(value);
      }
    }
  }, [children]);

  return (
    <Stack spacing="xsmall">
      <Box
        component="label"
        display="block"
        htmlFor={`Dropdown-${name}`}
        className="Dropdown-label"
        fontSize="xsmall"
        fontWeight="extraBold"
        paddingX="medium"
      >
        {label}
      </Box>

      <Box position="relative">
        <Box
          ref={ref}
          component="select"
          disabled={disabled}
          className={cx(
            'Dropdown-select',
            !field.value && 'is-placeholder',
            invalid && 'is-invalid',
          )}
          name={name}
          id={`Dropdown-${name}`}
          value={field.value}
          onBlur={() => {
            field.setTouched();
          }}
          onChange={(event: React.FormEvent<HTMLSelectElement>) => {
            field.setValue(event.currentTarget.value);
          }}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {children}
        </Box>
        <Box className="Dropdown-icon" display="flex" alignItems="center" paddingRight="small">
          <Icon
            name="arrow-dropdown"
            label=""
            color={disabled ? 'darkGray' : 'black'}
            size="large"
          />
        </Box>
      </Box>

      {invalid && (
        <Box color="warning" paddingX="medium" fontSize="xsmall">
          {(field.validationMessage && errorMessages[field.validationMessage as ErrorCodes]) ||
            field.validationMessage}
        </Box>
      )}
    </Stack>
  );
};

export default Dropdown;
