import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getEpisodeQuizMaster } from 'selectors/episodeSelectors';
import './QuizMasterReveal.css';

const QuizMasterReveal = () => {
  const episodeQuizMaster = useSelector(getEpisodeQuizMaster);
  const { t } = useTranslation();

  const { imageUrl, name } = episodeQuizMaster || {};

  return (
    <Box className="QuizMasterReveal" paddingTop="small">
      <Stack spacing="xsmall" align="center">
        <Text color="white" fontSize="xlarge" textAlign="center" className="QuizMasterReveal-title">
          {t('Quizmaster today')}
        </Text>
        <Box>
          <Avatar src={imageUrl} size="quizmaster" className="QuizMasterReveal-avatar" />
          <Text color="white" fontSize="large" className="QuizMasterReveal-name" textAlign="center">
            {name}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default QuizMasterReveal;
