import { setRegistrationDone } from 'actions/registrationActions';
import Box from 'components/Box';
import Button from 'components/Button';
import FlatButton from 'components/FlatButton';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { Logins } from 'components/Logins';
import Panel from 'components/Panel';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn } from 'selectors/userSelectors';

const RegistrationScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setRegistrationDone());
    }
  }, [isLoggedIn]);

  return (
    <>
      <Box display="flex" flexDirection="column" height="full" position="relative">
        <TopBar title={t('PROFILE')} />
        <Panel>
          <Box height="full" display="flex" flexDirection="column">
            <Stack spacing="xlarge" paddingX="gutter">
              <Text color="secondary" fontSize="xlarge" fontWeight="bold" textAlign="center">
                {t('Create a profile')}
              </Text>
              <Stack spacing="medium">
                <Button
                  to="/sign-up"
                  width="full"
                  icon={<Icon name="edit" color="white" label="" />}
                >
                  {t('Create my profile')}
                </Button>
                <Logins />
                <Text fontSize="small" textAlign="center">
                  <Trans>
                    Already have an account?{' '}
                    <Link to="/sign-in" underline color="black">
                      Log in
                    </Link>
                  </Trans>
                </Text>
              </Stack>
            </Stack>
            <Box flexGrow={1} />
            <Box display="flex" justifyContent="center">
              <FlatButton onClick={() => dispatch(setRegistrationDone())}>
                {t('Skip and play for fun')}
              </FlatButton>
            </Box>
          </Box>
        </Panel>
      </Box>
    </>
  );
};

export default RegistrationScreen;
