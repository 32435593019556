import { FieldProps, useField } from '@blockle/form';
import Box from 'components/Box';
import Stack from 'components/Stack';
import { cx } from 'cx';
import React from 'react';
import './RadioButton.css';

type Props = FieldProps<string> & {
  label: string;
  onChange?: (value: string) => void;
  checked?: boolean;
  required?: boolean;
  initialValue?: string;
};

const RadioButton = ({ name, label, value, checked, required, initialValue }: Props) => {
  const field = useField(name, {
    value: checked ? value : initialValue,
    validate: (value) => {
      return required && !value ? 'required' : null;
    },
  });
  const invalid = field.invalid && (field.dirty || field.touched);

  return (
    <div
      className="RadioButton"
      onClick={() => {
        field.setTouched();
        field.setValue(value);
      }}
    >
      <Stack spacing="xsmall" horizontal align="center">
        <button
          type="button"
          id={`radiobutton-${label}`}
          className={cx('RadioButton-button', invalid && 'is-invalid')}
          role="radio"
          aria-checked={field.value === value}
          tabIndex={0}
        />
        <Box component="label" htmlFor={`radiobutton-${label}`} color="darkGray" fontSize="medium">
          {label}
        </Box>
      </Stack>
    </div>
  );
};

export default RadioButton;
