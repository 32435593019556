import { confirmAuthentication } from 'actions/interUserApiActions';
import {
  fetchStoredAuthenticationToken,
  setStoredAuthenticationToken,
} from 'actions/nativeActions';
import { continueSession, registerAsGuest } from 'actions/usersApiActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMigrationStatus } from 'selectors/migrationSelectors';
import {
  getNativeSuspended,
  getParsedAuthenticationToken,
  getStoredAuthenticationToken,
  isNativeReady,
} from 'selectors/nativeSelectors';
import { getIsLoggedIn, getIsLoggingOut, getSessionValidationState } from 'selectors/userSelectors';

export const useCheckSession = () => {
  const dispatch = useDispatch();
  const isSuspended = useSelector(getNativeSuspended);
  const sessionValidation = useSelector(getSessionValidationState);
  const storedAuthenticationToken = useSelector(getStoredAuthenticationToken);
  const token = useSelector(getParsedAuthenticationToken);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const migrationStatus = useSelector(getMigrationStatus);
  const nativeSuspended = useSelector(getNativeSuspended);
  const nativeReady = useSelector(isNativeReady);
  const isLoggingOut = useSelector(getIsLoggingOut);

  useEffect(() => {
    if (
      !nativeReady ||
      nativeSuspended ||
      sessionValidation !== 'SHOULD_VALIDATE' ||
      isLoggingOut
    ) {
      return;
    }

    dispatch(fetchStoredAuthenticationToken());
  }, [nativeSuspended, nativeReady, sessionValidation, isLoggingOut]);

  useEffect(() => {
    if (
      !token ||
      storedAuthenticationToken.status === 'NOT_FETCHED' ||
      storedAuthenticationToken.token === token ||
      isLoggingOut
    ) {
      return;
    }

    dispatch(setStoredAuthenticationToken(token));
  }, [token, storedAuthenticationToken.status, storedAuthenticationToken.token, isLoggingOut]);

  useEffect(() => {
    if (migrationStatus !== 'FINISHED' || storedAuthenticationToken.status === 'NOT_FETCHED') {
      return;
    }

    const checkSession = async () => {
      if (!isSuspended && sessionValidation === 'SHOULD_VALIDATE') {
        const fromApple = /apple\.com/.test(document.referrer);
        const isLoggedInSession =
          fromApple && !isLoggedIn ? await dispatch(confirmAuthentication()) : false;

        if (!isLoggedInSession) {
          if (!!token) {
            dispatch(continueSession(token));
          } else {
            dispatch(registerAsGuest());
          }
        }
      }
    };

    checkSession();
  }, [
    isSuspended,
    sessionValidation,
    token,
    isLoggedIn,
    migrationStatus,
    storedAuthenticationToken.status,
  ]);
};
