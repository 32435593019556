import { trackEvent } from 'actions/analyticsActions';
import { storePendingAnswer, submitAnswer } from 'actions/answerActions';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getEvent } from 'selectors/eventsSelectors';
import { PhaseNames } from 'types/events';
import { AnswerValue } from 'types/score';
import { useDispatch } from './redux';

interface Props {
  noSubmit?: boolean;
  showResult: boolean;
  eventPhase: PhaseNames;
  episodeCode: string;
  eventId: string;
  value?: AnswerValue;
  raw: string;
}

// Cannot use `useRef` in hook because component could get destroyed / re-created
// so use Map to store previous answer values
const prevAnswerValues = new Map<string, string>();

export const useSubmitAnswer = ({
  noSubmit,
  showResult,
  eventPhase,
  episodeCode,
  eventId,
  value,
  raw,
}: Props) => {
  const dispatch = useDispatch();
  const event = useSelector((state: AppState) => getEvent(state, eventId));
  const rawPrevious = prevAnswerValues.get(eventId);
  const timerRef = useRef(-1);

  useEffect(() => {
    // Do not submit when just storing the answer
    if (noSubmit) return;

    // Practice game ansers are not stored
    if (episodeCode === 'practice') return;

    // Do not send empty values
    if (!raw && !rawPrevious) return;

    // Do not submit when previous answer exists
    if (prevAnswerValues.get(eventId) === raw) return;

    window.clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      if (value && event) {
        dispatch(
          storePendingAnswer({
            episodeCode,
            eventId,
            value,
            eventType: event.type,
          }),
        );
      }

      dispatch(
        submitAnswer({
          episodeCode,
          eventId,
          raw,
          rawPrevious,
        }),
      );

      prevAnswerValues.set(eventId, raw);
    }, 300);
  }, [eventPhase, raw]);

  // Store value once we know the correct value
  useEffect(() => {
    if (!showResult || !value) {
      return;
    }

    dispatch(trackEvent('answer', 'click'));
  }, [eventPhase, value]);
};
