import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getNativeSuspended } from 'selectors/nativeSelectors';

import { uncachedFetch } from 'utils/uncachedFetch';

const useVersionCheck = () => {
  const isNativeSuspended = useSelector(getNativeSuspended);
  const storedVersion = useRef<string>();
  const checkInterval = useRef(0);

  const interval = (window.emconfig?.settings?.outdatedVersionInterval || 60000) as number;

  useEffect(() => {
    window.clearInterval(checkInterval.current);

    if (isNativeSuspended) {
      return;
    }

    fetchVersion();
    checkInterval.current = window.setInterval(fetchVersion, interval);

    return () => {
      window.clearInterval(checkInterval.current);
    };
  }, [isNativeSuspended]);

  const fetchVersion = () => {
    uncachedFetch('version.json').then((response) => {
      if (!response.ok) return;

      response.json().then((data) => {
        if (!data) return;

        if (storedVersion.current && storedVersion.current !== data) {
          window.location.reload();
        }

        storedVersion.current = data;
      });
    });
  };
};

export default useVersionCheck;
