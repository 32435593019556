import { getProfile } from 'actions/talpaUserApiActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Card from 'components/Card';
import Coin from 'components/Coin';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import PointsOverlay from 'components/PointsOverlay';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import { useDispatch, useSelector } from 'hooks/redux';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from 'reducers';
import { getAnswer, getTotalPoints } from 'selectors/answersSelectors';
import { getEpisodeWinner } from 'selectors/episodeSelectors';
import { findStudioPlayerEventByEpisodeCode } from 'selectors/eventsSelectors';
import { EndOfShowEvent, PhaseNames } from 'types/events';
import './StudioPlayerInteraction.css';

// Hardcoded client dates to inform user of next show
function getNextAirDate() {
  const date = new Date();
  const dateCheck = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 19, 35, 0, 0);
  const textMap = [
    // Week starts at sunday in JS
    'Montag, 19:35 Uhr',
    'Montag, 19:35 Uhr',
    'Dienstag, 19:35 Uhr',
    'Mittwoch, 19:35 Uhr',
    'Donnerstag, 19:35 Uhr',
    'Freitag, 19:35 Uhr',
    'Montag, 19:35 Uhr',
  ];

  if (date > dateCheck) {
    date.setDate(date.getDate() + 1);
  }

  const day = date.getDay();

  return textMap[day];
}

interface Props {
  episodeCode: string;
  event: EndOfShowEvent;
  eventPhase: PhaseNames;
  startTime: number;
}

const EndOfShowInteraction: FC<Props> = ({ episodeCode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const winner = useSelector(getEpisodeWinner);
  const studioPlayerEvent = useSelector((state: AppState) =>
    findStudioPlayerEventByEpisodeCode(state, episodeCode),
  );
  const points = useSelector((state: AppState) => getTotalPoints(state, episodeCode));
  const selectedAnswer = useSelector((state: AppState) =>
    getAnswer(state, episodeCode, studioPlayerEvent?.eventId),
  );

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" flexGrow={1} justifyContent="center" paddingTop="medium">
          <Box>
            <Stack spacing="xsmall" align="center" paddingBottom="large">
              <Stack spacing="large" align="center">
                <Text
                  component="h1"
                  color="secondary"
                  fontSize="xxlarge"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {t('The quiz master is')}
                </Text>
                <Avatar
                  className="StudioPlayerInteraction-quizmasterAvatar"
                  src={winner?.imageUrl}
                  size="xlarge"
                />
              </Stack>
              <Text color="white" fontSize="xlarge" textAlign="center">
                {winner?.name}
              </Text>
            </Stack>
            <Card className="StudioPlayerInteraction-playerPoints">
              <Stack spacing="small" align="center">
                <Text color="black" textAlign="center">
                  {t('You have a total of ')}
                </Text>
                <Coin value={points} size="large" />
                <Text color="black" textAlign="center">
                  {t('points earned')}
                </Text>
              </Stack>
            </Card>
          </Box>
        </Box>

        <Panel flexGrow={0}>
          <Stack spacing="xsmall" align="center">
            <Text color="secondary" fontSize="xsmall">
              {t('NEXT BROADCAST')}
            </Text>
            <Text color="secondary" fontSize="large">
              {getNextAirDate()}
            </Text>
          </Stack>
        </Panel>
      </Box>

      <PointsOverlay
        open={selectedAnswer === 'correct'}
        points={studioPlayerEvent?.content.score}
      />
    </>
  );
};

export default EndOfShowInteraction;
