import { OverlayActions } from 'actions/overlayActions';
import { Reducer } from 'redux';

export interface OverlayReducer {
  title: string;
  text: string;
  isClosable: boolean;
  startTime: number;
  endTime: number;
  version: number;
  imageUrl?: string;
  closed?: boolean;
}

export const initialState: OverlayReducer = {
  title: '',
  text: '',
  isClosable: true,
  startTime: -1,
  endTime: -1,
  version: 0,
  closed: false,
};

export const overlayReducer: Reducer<OverlayReducer, OverlayActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@overlay/SET':
      return {
        ...state,
        ...action.payload,
        imageUrl: action.payload.imageUrl || undefined,
        closed: false,
      };

    case '@overlay/CLOSE':
      return {
        ...state,
        closed: true,
      };

    default:
      return state;
  }
};
