import { Route, useHistory } from '@blockle/router';
import { setOnboardViewed } from 'actions/onboardActions';
import Illustration1 from 'public/images/explanation-1.png';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordScreen from 'screens/ForgotPassword';
import SignInScreen from 'screens/SignIn';
import SignUpScreen from 'screens/SignUp';
import { getEpisodeQuizMaster } from 'selectors/episodeSelectors';
import { getOnboardViewed } from 'selectors/onboardSelectors';
import { loadImage } from 'testUtils/loadImage';
import ExplanationScreen from './ExplanationScreen';
import QuizmasterScreen from './QuizmasterScreen';
import RegistrationScreen from './RegistrationScreen';
import SplashScreen from './SplashScreen';

const WelcomeScreen: FC = () => {
  const dispatch = useDispatch();
  const isOnboardViewed = useSelector(getOnboardViewed);
  const episodeQuizMaster = useSelector(getEpisodeQuizMaster);
  const [screen, setScreen] = useState<'splash' | 'intro' | 'quizmaster' | 'registration'>(
    isOnboardViewed ? 'registration' : 'splash',
  );
  const history = useHistory();

  const registrationScreens = ['/sign-in', '/sign-up'];
  const { imageUrl } = episodeQuizMaster || {};

  // Preload first image of ExplanationScreen
  useEffect(() => {
    loadImage(Illustration1);
  }, []);

  useEffect(() => {
    if (imageUrl) {
      loadImage(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    return history.listen((location) => {
      const { pathname } = location;

      if (registrationScreens.includes(pathname)) {
        setScreen('registration');
      }
    });
  }, [history]);

  useEffect(() => {
    const {
      location: { pathname },
    } = history;

    if (registrationScreens.includes(pathname)) {
      setScreen('registration');
    }
  }, []);

  switch (screen) {
    case 'splash':
      return <SplashScreen onProceed={() => setScreen('intro')} />;
    case 'intro':
      return <ExplanationScreen onProceed={() => setScreen('quizmaster')} />;
    case 'quizmaster':
      return (
        <QuizmasterScreen
          onProceed={() => {
            setScreen('registration');
            dispatch(setOnboardViewed());
          }}
        />
      );
    case 'registration':
      return (
        <>
          <Route path="/" exact>
            <RegistrationScreen />
          </Route>
          <Route path="/sign-up">
            <SignUpScreen />
          </Route>
          <Route path="/sign-in">
            <SignInScreen />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordScreen />
          </Route>
        </>
      );
  }
};

export default WelcomeScreen;
