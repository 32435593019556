import Box from 'components/Box';
import NotchSpacing from 'components/NotchSpacing';
import Stack from 'components/Stack';
import Stepper, { StepperItem } from 'components/Stepper';
import Text from 'components/Text';
import Illustration1 from 'public/images/explanation-1.png';
import Illustration2 from 'public/images/explanation-2.png';
import Illustration3 from 'public/images/explanation-3.png';
import Illustration4 from 'public/images/explanation-4.png';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { loadImage } from 'testUtils/loadImage';
import './ExplanationScreen.css';

interface Props {
  onProceed: () => void;
}

const ExplanationScreen = ({ onProceed }: Props) => {
  const { t } = useTranslation();

  useMemo(() => [loadImage(Illustration2), loadImage(Illustration3), loadImage(Illustration4)], []);

  const baseClass = 'ExplanationScreen';

  return (
    <>
      <NotchSpacing />
      <Stepper onSkip={onProceed}>
        <StepperItem
          render={() => (
            <>
              <Stack spacing="medium">
                <Text fontSize="xlarge" color="incorrect" fontWeight="bold" textAlign="center">
                  {t('5 rounds')}
                </Text>
                <Text fontSize="small" color="white" textAlign="center">
                  {t(
                    'Each round is a different category and the categories change on a regular basis.',
                  )}
                </Text>
              </Stack>

              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingY="gutter"
              >
                <img className={`${baseClass}-illustration`} src={Illustration1} />
              </Box>
            </>
          )}
        />
        <StepperItem
          render={() => (
            <>
              <Stack spacing="medium">
                <Text fontSize="xlarge" color="incorrect" fontWeight="bold" textAlign="center">
                  {t('5 questions per round')}
                </Text>
                <Text fontSize="small" color="white" textAlign="center">
                  {t('There is 25 questions total, 5 for each round.')}
                </Text>
              </Stack>

              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingY="gutter"
              >
                <img className="ExplanationScreen-illustration" src={Illustration2} />
              </Box>
            </>
          )}
        />
        <StepperItem
          render={() => (
            <>
              <Stack spacing="medium">
                <Text fontSize="xlarge" color="incorrect" fontWeight="bold" textAlign="center">
                  {t('Points increase')}
                </Text>
                <Text fontSize="small" color="white" textAlign="center">
                  {t('Each round the number of points per correct answer increases.')}
                </Text>
              </Stack>

              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingY="gutter"
              >
                <img className="ExplanationScreen-illustration" src={Illustration3} />
              </Box>
            </>
          )}
        />
        <StepperItem
          render={() => (
            <>
              <Stack spacing="medium">
                <Text fontSize="xlarge" color="incorrect" fontWeight="bold" textAlign="center">
                  {t('Predict the winner')}
                </Text>
                <Text fontSize="small" color="white" textAlign="center">
                  {t(
                    'During finals and win an extra 250 points to help you reach the top of the leaderboard',
                  )}
                </Text>
              </Stack>

              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingY="gutter"
              >
                <img className="ExplanationScreen-illustration" src={Illustration4} />
              </Box>
            </>
          )}
        />
      </Stepper>
    </>
  );
};

export default ExplanationScreen;
