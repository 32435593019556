import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

if (!__TEST__) {
  i18next.use(XHR);
}

const formatDayDate = (lng: string, value: Date) => {
  return `${new Intl.DateTimeFormat(lng, {
    weekday: 'long',
  }).format(value)} ${new Intl.DateTimeFormat(lng, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(value)}`;
};

i18next.use(initReactI18next).init({
  debug: false,
  saveMissing: process.env.NODE_ENV !== 'production',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  ns: ['general'],
  defaultNS: 'general',
  lng: __LOCAL__ ? 'en' : 'de-AT',
  fallbackLng: 'en',
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (value instanceof Date) {
        switch (format) {
          case 'long':
            const options: Intl.DateTimeFormatOptions = {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
            return new Intl.DateTimeFormat(lng, options).format(value);
          case 'day-date':
            return formatDayDate(lng || '', value);
          default:
            return new Intl.DateTimeFormat(lng).format(value);
        }
      }
      return value;
    },
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    nsMode: 'default',
  },
});

export default i18next;
