import { TermsResponse } from 'types/api/termsApi';
import { GameReducer } from 'types/game';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';

export const fetchTermsVersion =
  (): ThunkAction<Promise<void>> =>
  async (dispatch, _getState, { api }) => {
    try {
      const { version } = await api.get<TermsResponse>(`talpa/terms/0`);

      dispatch(setTermsVersion(String(version)));
    } catch (error) {
      throw error;
    }
  };

export const joinGameType = (type: Required<GameReducer>['gameType']) =>
  createActionWithPayload('@game/JOIN_GAME_TYPE', type);

export const leaveGame = () => createAction('@game/LEAVE_GAME');

export const setTermsVersion = (termsVersion: string) =>
  createActionWithPayload('@game/SET_TERMS_VERSION', termsVersion);

export const toggleDebugOverlay = () => createAction('@game/TOGGLE_DEBUG_OVERLAY');

export type GameActions = ReturnType<
  typeof joinGameType | typeof leaveGame | typeof setTermsVersion | typeof toggleDebugOverlay
>;
