import { MigrationStatus } from 'reducers/migration';
import { createActionWithPayload, ThunkAction } from './helpers';
import { storeUser } from './userActions';

export const migrateUser = (): ThunkAction<void> => async (dispatch) => {
  dispatch(setMigrationStatus('IN_PROGRESS'));

  try {
    const db = window.openDatabase('playtotv.storage.AsyncStorage', '1.0', '', 4980736);

    db.transaction((transaction) => {
      transaction.executeSql(
        'SELECT * FROM `keyvaluepairs` WHERE `key` = "qzm.user"',
        [],
        (transaction, results: any) => {
          const result = results.rows.item(0);

          if (!result) {
            // No data to migrate
            dispatch(setMigrationStatus('FINISHED'));
            return;
          }

          const user = JSON.parse(result.value);

          dispatch(
            storeUser({
              sessionValidationState: 'SHOULD_VALIDATE',
              authenticationToken: user.current.authenticationToken,
              pttvSession: user.current.pttvSession,
              userId: user.current.userId,
            }),
          );

          dispatch(setMigrationStatus('FINISHED'));
        },
        () => {
          // Failed to access db content, probably empty
          dispatch(setMigrationStatus('FINISHED'));

          return false;
        },
      );
    });
  } catch (e) {
    // WebSQL unavailable / deprecated
    // try localStorage
    try {
      const result = localStorage.getItem('qzm.user');

      // Nothing to migrate
      if (!result) {
        dispatch(setMigrationStatus('FINISHED'));
        return;
      }

      const user = JSON.parse(result);

      dispatch(
        storeUser({
          sessionValidationState: 'SHOULD_VALIDATE',
          authenticationToken: user.current.authenticationToken,
          pttvSession: user.current.pttvSession,
          userId: user.current.userId,
        }),
      );

      dispatch(setMigrationStatus('FINISHED'));
    } catch (e) {
      // Failed to access db content, probably empty
      dispatch(setMigrationStatus('FINISHED'));
    }
  }
};

export const setMigrationStatus = (status: MigrationStatus) =>
  createActionWithPayload('@migration/SET_STATUS', status);

export type MigrationActions = ReturnType<typeof setMigrationStatus>;
