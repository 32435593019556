import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Coin from 'components/Coin';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import PointsOverlay from 'components/PointsOverlay';
import Stack from 'components/Stack';
import StudioPlayerCard from 'components/StudioPlayerCard';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import TopBar from 'components/TopBar';
import { useSelector } from 'hooks/redux';
import { useSessionState } from 'hooks/useSessionState';
import { useSubmitAnswer } from 'hooks/useSubmitAnswer';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEpisodeWinner } from 'selectors/episodeSelectors';
import { getStudioPlayers } from 'selectors/studioPlayerSelectors';
import { PhaseNames, StudioPlayerSelectionEvent } from 'types/events';
import './StudioPlayerInteraction.css';

interface Props {
  episodeCode: string;
  event: StudioPlayerSelectionEvent;
  eventPhase: PhaseNames;
  startTime: number;
}

const StudioPlayerInteraction: FC<Props> = ({ episodeCode, event, eventPhase }) => {
  const { t } = useTranslation();
  const [showResult, setShowResult] = useState(false);
  const winner = useSelector(getEpisodeWinner);
  const studioPlayers = useSelector(getStudioPlayers);
  const [selectedAnswer, setSelectedAnswer] = useSessionState<string>(event.eventId, '');
  const selectedStudioPlayer = studioPlayers[selectedAnswer];
  const value = selectedStudioPlayer
    ? selectedStudioPlayer.episodeWinner
      ? 'correct'
      : 'incorrect'
    : 'not_set';
  const lockAnswer = true;

  useEffect(() => {
    if (eventPhase === 'RESULTS') setShowResult(true);
  }, [eventPhase]);

  useSubmitAnswer({
    showResult,
    eventPhase,
    episodeCode,
    eventId: event.eventId,
    value,
    raw: selectedAnswer,
  });

  const handleSelectAnswer = (code: string) => {
    if (lockAnswer && selectedAnswer) return;
    setSelectedAnswer(code);
  };

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      {showResult ? (
        <>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Stack spacing="xlarge" align="center">
                <Text
                  component="h1"
                  color="secondary"
                  fontSize="xlarge"
                  fontWeight="semiBold"
                  textAlign="center"
                >
                  {t('The quiz master is')}
                </Text>
                <Avatar
                  className="StudioPlayerInteraction-quizmasterAvatar"
                  src={winner?.imageUrl}
                  size="xlarge"
                />
                <Text color="white" fontSize="large">
                  {winner?.name}
                </Text>
              </Stack>
            </Box>

            <Panel flexGrow={0}>
              <Stack spacing="xsmall" align="center">
                <Text color="secondary" fontSize="xsmall">
                  {t('NEXT BROADCAST')}
                </Text>
                <Text color="secondary" fontSize="large">
                  {t('Thursday 7:35 p.m.')}
                </Text>
              </Stack>
            </Panel>
          </Box>
          <PointsOverlay open={showResult && value === 'correct'} points={event.content.score} />
        </>
      ) : (
        <Panel>
          <Box paddingX="gutter" height="full" display="flex" flexDirection="column">
            <Stack spacing="xsmall" align="center" paddingX="large">
              <Text color="secondary" fontSize="xlarge" fontWeight="bold" textAlign="center">
                {t('Who will be today’s Quizmaster?')}
              </Text>
              <Tooltip>
                <Stack spacing="xsmall" horizontal align="center">
                  <Text fontSize="small" color="black">
                    {t('Predict correctly and win ')}
                  </Text>
                  <Coin size="medium" value={event.content.score} />
                </Stack>
              </Tooltip>
            </Stack>

            <Box
              className="StudioPlayerInteraction-background"
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing="large" horizontal>
                {event.content.answers
                  .sort((studioPlayer) => {
                    if (studioPlayer.text === 'Quizmaster') {
                      return 1;
                    }

                    return -1;
                  })
                  .map(({ code }) => (
                    <StudioPlayerCard
                      key={code}
                      playerId={code}
                      selected={selectedAnswer === code}
                      onClick={() => handleSelectAnswer(code)}
                    />
                  ))}
              </Stack>
            </Box>
          </Box>
        </Panel>
      )}
    </>
  );
};

export default StudioPlayerInteraction;
