import EndOfShowInteraction from 'components/Interactions/EndOfShowInteraction';
import React, { FC } from 'react';
import { EndOfShowEvent, PhaseNames } from 'types/events';

interface Props {
  episodeCode?: string;
  event?: EndOfShowEvent;
  previewPhase: PhaseNames;
}

const PreviewEndOfShowScreen: FC<Props> = ({ episodeCode, event, previewPhase }: Props) => {
  if (!event || !episodeCode) return null;

  return (
    <EndOfShowInteraction
      episodeCode={episodeCode}
      event={event}
      eventPhase={previewPhase}
      startTime={Date.now()}
    />
  );
};

export default PreviewEndOfShowScreen;
