import { useEffect } from 'react';
import Cron from 'utils/cron';

const cron = new Cron();

/**
 * Schedule a task in the future
 *
 * Note: uses one instance of cron
 *
 * @param name unique cronjob name
 * @param time when to trigger
 * @param callback
 */
export function useGlobalCron(name: string, time: number, callback: () => any) {
  useEffect(() => {
    const abort = cron.schedule(name, time, callback);

    return abort;
  }, [name, time]);
}
