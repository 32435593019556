import classnames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getEpisodeCode } from 'selectors/episodeSelectors';
import { getEvents, getEventsByType, isQuestion } from 'selectors/eventsSelectors';
import {
  CategoryStartEvent,
  EndOfShowEvent,
  InterstitialEvent,
  PhaseNames,
  StudioPlayerSelectionEvent,
  TriviaEvent,
} from 'types/events';
import {
  PreviewCategoryScreen,
  PreviewIdleScreen,
  PreviewInterstitialScreen,
  PreviewPointsOverlayScreen,
  PreviewRoundScreen,
  PreviewStudioPlayerScreen,
  PreviewTriviaScreen,
} from '.';
import PreviewCommercialBreakScreen from './PreviewCommercialBreakScreen';
import PreviewEndOfShowScreen from './PreviewEndOfShowScreen';
import './PreviewScreen.css';

type PreviewScreens = {
  [key: string]: {
    component: JSX.Element;
    hideFields?: string[];
    previewQuestionEvents?: boolean;
  };
};

interface PreviewFieldProps {
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  fieldName: string;
  options: string[];
  value: string;
  hideFields?: string[];
}

const PreviewField = ({ onChange, fieldName, options, value, hideFields }: PreviewFieldProps) => {
  if (hideFields && hideFields.indexOf(fieldName) !== -1) return null;

  const baseClass = 'preview-field';

  return (
    <div className={baseClass}>
      <label htmlFor={`${baseClass}-${fieldName}`}>{fieldName}:</label>
      <select id={`${baseClass}-${fieldName}`} onChange={onChange} value={value}>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

interface PreviewMenuProps {
  previewType: string;
  previewEventId: string;
  previewPhase: string;
  previewScreens: PreviewScreens;
  setPreviewType: (previewType: string) => void;
  setPreviewEventId: (previewEventId: string) => void;
  setPreviewPhase: (previewPhase: PhaseNames) => void;
}

const PreviewMenu = ({
  previewType,
  previewEventId,
  previewPhase,
  previewScreens,
  setPreviewType,
  setPreviewEventId,
  setPreviewPhase,
}: PreviewMenuProps) => {
  const { hideFields, previewQuestionEvents } = previewScreens[previewType];
  const [hidePreview, setHidePreview] = useState(true);
  const events = useSelector((state: AppState) =>
    previewQuestionEvents
      ? getEvents(state).filter((event) => isQuestion(event.type))
      : getEventsByType(state, previewType),
  );
  const event = events.find((event) => event.eventId === previewEventId);
  const phases = previewType && event ? event.phases.map((phase) => phase.name) : null;

  const baseClass = 'preview-menu';

  return (
    <div
      className={classnames(baseClass, {
        [`${baseClass}--is-hidden`]: hidePreview,
      })}
    >
      <div className={`${baseClass}-hide`}>
        <div className={`${baseClass}-content`}>
          <PreviewField
            fieldName="Type"
            onChange={(e) => setPreviewType(e.target.value)}
            options={Object.keys(previewScreens)}
            value={previewType}
            hideFields={hideFields}
          />
          <PreviewField
            fieldName="Interaction"
            onChange={(e) => setPreviewEventId(e.target.value)}
            options={events.map((event) => event.eventId)}
            value={previewEventId}
            hideFields={previewScreens[previewType].hideFields}
          />
          <PreviewField
            fieldName="Phase"
            onChange={(e) => setPreviewPhase(e.target.value as PhaseNames)}
            options={phases || []}
            value={previewPhase}
            hideFields={previewScreens[previewType].hideFields}
          />
          <button className={`${baseClass}-reset`} onClick={() => window.location.reload()}>
            Reset
          </button>
        </div>
      </div>
      <button className={`${baseClass}-toggle`} onClick={() => setHidePreview(!hidePreview)}>
        Preview
      </button>
    </div>
  );
};

const PreviewScreen = () => {
  const startingPhase = 'SHOW';

  const [previewType, setPreviewType] = useState('ROUND');
  const [previewEventId, setPreviewEventId] = useState('');
  const [previewPhase, setPreviewPhase] = useState<PhaseNames>(startingPhase);

  const events = useSelector((state: AppState) => getEventsByType(state, previewType));
  const episodeCode = useSelector(getEpisodeCode);
  const previewEvent = events
    ? events.find((event) => event.eventId === previewEventId)
    : undefined;

  const previewScreens: PreviewScreens = {
    ROUND: {
      component: <PreviewRoundScreen episodeCode={episodeCode} />,
      hideFields: ['Interaction', 'Phase'],
    },
    CATEGORYSTART: {
      component: (
        <PreviewCategoryScreen
          episodeCode={episodeCode}
          event={previewEvent as CategoryStartEvent}
        />
      ),
    },
    TRIVIA: {
      component: (
        <PreviewTriviaScreen
          episodeCode={episodeCode}
          event={previewEvent as TriviaEvent}
          previewPhase={previewPhase}
        />
      ),
    },
    STUDIOPLAYERSELECTION: {
      component: (
        <PreviewStudioPlayerScreen
          episodeCode={episodeCode}
          event={previewEvent as StudioPlayerSelectionEvent}
          previewPhase={previewPhase}
        />
      ),
    },
    IDLE: {
      component: <PreviewIdleScreen />,
      hideFields: ['Phase'],
      previewQuestionEvents: true,
    },
    INTERSTITIAL: {
      component: (
        <PreviewInterstitialScreen
          episodeCode={episodeCode}
          event={previewEvent as InterstitialEvent}
        />
      ),
    },
    COMMERCIALBREAK: {
      component: <PreviewCommercialBreakScreen />,
      hideFields: ['Interaction', 'Phase'],
    },
    ENDOFSHOW: {
      component: (
        <PreviewEndOfShowScreen
          episodeCode={episodeCode}
          event={previewEvent as EndOfShowEvent}
          previewPhase={previewPhase}
        />
      ),
    },
    POINTSOVERLAY: {
      component: <PreviewPointsOverlayScreen />,
      hideFields: ['Interaction', 'Phase'],
    },
  };

  useEffect(() => {
    if (events && events[0]) setPreviewEventId(events[0].eventId);
    setPreviewPhase(startingPhase);
  }, [previewType]);

  useEffect(() => {
    if (!previewEventId && events && events[0]) setPreviewEventId(events[0].eventId);
  }, [events]);

  return (
    <>
      <PreviewMenu
        previewType={previewType}
        previewEventId={previewEventId}
        previewPhase={previewPhase}
        previewScreens={previewScreens}
        setPreviewType={setPreviewType}
        setPreviewEventId={setPreviewEventId}
        setPreviewPhase={setPreviewPhase}
      />
      {previewType && previewScreens[previewType] && previewScreens[previewType].component}
    </>
  );
};

export default PreviewScreen;
