import RoundInteraction from 'components/Interactions/RoundInteraction';
import React from 'react';

interface Props {
  episodeCode?: string;
}

const PreviewRoundScreen = ({ episodeCode }: Props) => {
  if (!episodeCode) return null;

  return <RoundInteraction episodeCode={episodeCode} />;
};

export default PreviewRoundScreen;
