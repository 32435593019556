import { hasEpisodes } from 'selectors/statisticsSelectors';
import { getIsLoggedIn } from 'selectors/userSelectors';
import {
  QzmEpisodeHighlights,
  QzmEpisodeLeaderboard,
  QzmEpisodeLeaderboardScoreUsers,
  QzmHighlightList,
  QzmHighlightListEpisode,
} from 'types/api/episodeApi';
import {
  QzmHighlightListEpisodeHistory,
  StatisticsEpisodeDetailsResultsItem,
  StatisticsEpisodeLeaderboardItems,
  StatisticsRegionFilterState,
  StatisticsShowFilterState,
} from 'types/statistics';
import { RegionNames } from 'types/user';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';
import { setFriendScores } from './socialActions';

const REGIONS_MAP = {
  vienna: 'Wien',
  tyrol: 'Tirol',
  lowerAustria: 'Niederösterreich',
  upperAustria: 'Oberösterreich',
  styria: 'Steiermark',
  carinthia: 'Kärnten',
  salzburg: 'Salzburg',
  vorarlberg: 'Vorarlberg',
  burgenland: 'Burgenland',
};

const regionTranslate = (regionName: RegionNames) => {
  return REGIONS_MAP[regionName];
};

export const fetchHighlightList =
  (): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    const state = getState();
    const episodesStored = hasEpisodes(state);

    if (!episodesStored) {
      dispatch(setLoading(true));
    }

    const { episodes } = await api.get<QzmHighlightList>(`qzm/episodes`);
    const isLoggedIn = getIsLoggedIn(state);

    const {
      user: { profile },
    } = state;

    // Updated statistics with player participatedEpisodes from user profile
    if (isLoggedIn && profile?.participatedEpisodes) {
      profile.participatedEpisodes.forEach((participatedEpisode) => {
        const { episodeCode, score } = participatedEpisode;

        if (episodes && episodeCode) {
          setParticipatedEpisodes(episodes, episodeCode, score);
        }
      });
    }

    dispatch(setStatisticsEpisodeList(episodes));
    dispatch(setLoading(false));
  };

const setParticipatedEpisodes = (
  episodes: QzmHighlightListEpisodeHistory[],
  episodeCode: string,
  score: number,
) => {
  const foundEpisodeCode = episodes.findIndex((episode) => episode.episodeCode === episodeCode);

  if (!foundEpisodeCode || !episodes[foundEpisodeCode]) {
    return;
  }

  episodes[foundEpisodeCode].personalScore = score;
  episodes[foundEpisodeCode].participated = true;
};

export const fetchEpisodeHighlights =
  (episodeCode: string): ThunkAction<Promise<void>> =>
  async (dispatch, _getState, { api }) => {
    try {
      const highlights = await api.get<QzmEpisodeHighlights>(
        `qzm/episodes/${episodeCode}/highlights`,
      );

      dispatch(
        setStatisticsEpisodeHighlights({
          ...highlights,
          episodeCode,
          fetchSucces: true,
        }),
      );
    } catch (error) {
      dispatch(setStatisticsEpisodeHighlightsNotFound(episodeCode));
    }
  };

export const fetchLeaderboard =
  (episode: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    const {
      statistics: { regionFilter, showFilter },
      social: { type },
    } = getState();

    const getLeaderboardType = () => {
      if (regionFilter) {
        return `LOCATION&typeValue=${regionTranslate(regionFilter)}`;
      }

      if (showFilter) {
        return `GENDER&typeValue=${showFilter}`;
      }

      return 'GLOBAL';
    };

    if (type === 'facebook' && showFilter === 'FRIENDS') {
      return dispatch(setFriendScores());
    }

    const leaderboard = await api.get<QzmEpisodeLeaderboard>(
      `qzm/leaderboard/${episode}?pageIndex=1&type=${getLeaderboardType()}`,
    );

    dispatch(
      setStatisticsEpisodeLoaderboard({
        leaderboard,
      }),
    );
  };

// Standard actions.
export const setStatisticsEpisodeList = (episodes: QzmHighlightListEpisode[]) =>
  createActionWithPayload('@statistics/SET_EPISODE_LIST', episodes);

export const setStatisticsEpisodeHighlights = (
  episodeHighlights: StatisticsEpisodeDetailsResultsItem,
) => createActionWithPayload('@statistics/SET_EPISODE_HIGHLIGHTS', episodeHighlights);

export const setStatisticsEpisodeHighlightsNotFound = (episodeCode: string) =>
  createActionWithPayload('@statistics/SET_EPISODE_HIGHLIGHTS_NOT_FOUND', episodeCode);

export const setStatisticsEpisodeLoaderboard = (
  episodeLeaderboard: StatisticsEpisodeLeaderboardItems | QzmEpisodeLeaderboardScoreUsers,
) => createActionWithPayload('@statistics/SET_EPISODE_LEADERBOARD', episodeLeaderboard);

export const updateShowFilter = (filterName: StatisticsShowFilterState) =>
  createActionWithPayload('@statistics/SHOW_FILTER_UPDATE', filterName);

export const updateRegionFilter = (filterName: StatisticsRegionFilterState) =>
  createActionWithPayload('@statistics/REGION_FILTER_UPDATE', filterName);

export const resetFilters = () => createAction('@statistics/FILTERS_RESET');

export const setLoading = (loading: boolean) =>
  createActionWithPayload('@statistics/SET_LOADING', loading);

export type StatisticsActions = ReturnType<
  | typeof setStatisticsEpisodeList
  | typeof setStatisticsEpisodeHighlights
  | typeof setStatisticsEpisodeHighlightsNotFound
  | typeof setStatisticsEpisodeLoaderboard
  | typeof updateShowFilter
  | typeof updateRegionFilter
  | typeof resetFilters
  | typeof setLoading
>;
