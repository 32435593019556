import Box from 'components/Box';
import CategoryIcon from 'components/CategoryIcon';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getNextEventTime } from 'selectors/eventsSelectors';
import './NextQuestionLoader.css';
import NextQuestionTimeBar from './NextQuestionTimeBar';

interface Props {
  categoryUrl: string;
  categoryText: string;
  startTime?: number;
}

const NextQuestionLoader: FC<Props> = ({ categoryUrl, categoryText, startTime: position }) => {
  const { startTime, endTime } = useSelector(getNextEventTime);

  return (
    <Box className={'NextQuestionLoader'}>
      {endTime && position && startTime && startTime !== -1 && (
        <NextQuestionTimeBar startTime={startTime} endTime={endTime} position={position} />
      )}
      <CategoryIcon url={categoryUrl} title={categoryText} size="large" color="red" />
    </Box>
  );
};

export default NextQuestionLoader;
