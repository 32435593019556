import Box from 'components/Box';
import Logo from 'components/Logo';
import SponsorLogo from 'public/images/servus.png';
import React from 'react';
import './TopBar.css';

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
  score?: boolean;
  logo?: boolean;
  title?: string;
}

const TopBar = ({
  left,
  title,
  logo,
  right = <img src={SponsorLogo} className="TopBar-sponsor" />,
}: Props) => {
  return (
    <Box className="TopBar" display="flex" paddingX="gutter">
      <div className="TopBar-side is-left">{left}</div>

      <Box
        component="span"
        flexGrow={1}
        color="white"
        fontSize="small"
        textAlign="center"
        textTransform="uppercase"
        paddingTop={title ? 'small' : undefined}
      >
        {logo ? <Logo /> : title}
      </Box>

      <div className="TopBar-side is-right">{right}</div>
    </Box>
  );
};

export default TopBar;
