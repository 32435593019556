import Box from 'components/Box';
import GlobalErrorDialog from 'components/GlobalErrorDialog';
import JackpotDialog from 'components/JackpotDialog';
import RemoveElement from 'components/RemoveElement';
import TrackRoute from 'components/TrackRoute';
import { useSelector } from 'hooks/redux';
import { useCheckSession } from 'hooks/useCheckSession';
import { useConfirmAuthenticationInformation } from 'hooks/useConfirmAuthenticationInformation';
import { useLoginFlow } from 'hooks/useLoginFlow';
import useVersionCheck from 'hooks/useVersionCheck';
import React, { useRef } from 'react';
import ApplicationScreen from 'screens/Application/ApplicationScreen';
import ApplicationSplashScreen from 'screens/Application/ApplicationSplashScreen';
import SignUpNotVerified from 'screens/SignUpNotVerified';
import { VerifyAccount } from 'screens/VerifyAccount';
import WelcomeScreen from 'screens/Welcome';
import { getMigrationStatus } from 'selectors/migrationSelectors';
import { isNativeReady } from 'selectors/nativeSelectors';
import { getRegistrationDone } from 'selectors/registrationSelectors';
import { getWaitForApple } from 'selectors/socialSelectors';
import {
  checkAuthenticationToken,
  getEmailInvalidForAuthentication,
  getIsLoggingOut,
  isAuthenticated,
} from 'selectors/userSelectors';
import './RootScreen.css';

const RootScreen = () => {
  const appBooted = useRef(false);
  const isLoggingOut = useSelector(getIsLoggingOut);
  const hasAuthenticationToken = useSelector(checkAuthenticationToken);
  const authenticationFinished = useSelector(isAuthenticated);
  const showWelcome = !useSelector(getRegistrationDone);
  const nativeReady = useSelector(isNativeReady);
  const disableAppInteraction = hasAuthenticationToken && !authenticationFinished;
  const migrating = useSelector(getMigrationStatus);
  const waitForApple = useSelector(getWaitForApple);
  const emailInvalidForAuthentication = useSelector(getEmailInvalidForAuthentication);

  useVersionCheck();
  useCheckSession();
  useLoginFlow();
  const { verifyChecked, showVerify } = useConfirmAuthenticationInformation();

  const showSplashScreen =
    !verifyChecked ||
    waitForApple ||
    isLoggingOut ||
    (!appBooted.current && (!nativeReady || migrating !== 'FINISHED' || !authenticationFinished));

  // Using ref to make sure the above statement kicks in only once..
  appBooted.current = true;

  let screen = <ApplicationScreen />;
  if (showVerify) {
    screen = <VerifyAccount />;
  } else if (emailInvalidForAuthentication) {
    screen = <SignUpNotVerified includeSignout />;
  } else if (showWelcome) {
    screen = <WelcomeScreen />;
  }

  return showSplashScreen ? (
    <ApplicationSplashScreen />
  ) : (
    <>
      <Box display="flex" flexDirection="column" height="full" overflow="hidden" role="main">
        {screen}
      </Box>
      {/* Remove splash screen on initial render */}
      <RemoveElement target="#app-splash" />
      <TrackRoute />
      {disableAppInteraction && <Box className="appDisabledWindow" />}
      <GlobalErrorDialog />
      <JackpotDialog />
    </>
  );
};

export default RootScreen;
