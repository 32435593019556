import { useEffect } from 'react';

type Props = {
  target: string;
  duration?: number;
};

const RemoveElement = ({ target, duration = 400 }: Props) => {
  useEffect(() => {
    const node = document.querySelector<HTMLDivElement>(target);

    if (!node) {
      return;
    }

    node.style.transitionDelay = '';
    node.style.transition = `visibility ${duration}ms, opacity ${duration}ms ease-out`;
    node.style.opacity = '0';
    node.style.visibility = 'hidden';

    setTimeout(() => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
      }
    }, duration + 50);
  }, []);

  return null;
};

export default RemoveElement;
