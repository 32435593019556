import { EpisodeActions } from 'actions/episodeActions';
import { Reducer } from 'redux';

export type EpisodeFriendScoresReducer = {
  userId: string;
  gigyaId: string;
  avatarUrl: string;
  displayName: string;
  score: number;
  episodeCode: string;
}[];

export const initialState: EpisodeFriendScoresReducer = [];

export const episodeFriendScoresReducer: Reducer<EpisodeFriendScoresReducer, EpisodeActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@episode/CLEAR_EPISODE':
      return initialState;

    case '@episode/SET_FRIEND_SCORES':
      return action.payload;

    default:
      return state;
  }
};
