import { cx } from 'cx';
import avatarPlaceholder from 'public/images/avatar-placeholder.svg';
import React, { useState } from 'react';
import './Avatar.css';

interface Props {
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'quizmaster';
  src?: string;
}

const Avatar = ({ className, size = 'medium', src = avatarPlaceholder }: Props) => {
  const [avatarFallback, setAvatarFallback] = useState(null);

  const onImageError = () => {
    setAvatarFallback(avatarPlaceholder);
  };

  return (
    <img
      data-testid="avatar"
      className={cx('Avatar', `size-${size}`, className)}
      src={avatarFallback || src || avatarPlaceholder}
      alt="Avatar"
      onError={onImageError}
    />
  );
};

export default Avatar;
