import { UserReducer } from 'reducers/user';
import { QzmProfile } from 'types/api/userApi';
import { Profile } from 'types/user';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';
import { RegisterWithEmailData } from './interUserApiActions';

export const joinEpisode = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { episode } = getState();

  if (!episode) {
    return;
  }

  const { episodeCode } = episode;

  dispatch(setEpisodeJoined(episodeCode));
};

export const updateRegistrationProfile = (payload: RegisterWithEmailData) =>
  createActionWithPayload('@user/UPDATE_REGISTRATION_PROFILE', payload);

export const setEpisodeJoined = (episodeCode: string) =>
  createActionWithPayload('@user/EPISODE_JOINED', episodeCode);

export const storeUser = (user: Partial<UserReducer>) =>
  createActionWithPayload('@user/STORE_USER', user);

export const storeUserProfile = (profile: Profile) =>
  createActionWithPayload('@user/STORE_USER_PROFILE', profile);

export const clearUser = () => createAction('@user/CLEAR_USER');

export const setProfileStatistics = (profileStatistics: QzmProfile) =>
  createActionWithPayload('@user/STORE_USER_PROFILE_STATISTICS', profileStatistics);

export const setSessionValidationState = (state: UserReducer['sessionValidationState']) =>
  createActionWithPayload('@user/SET_VALIDATION_STATE', state);

export const storePasswordResetToken = (passwordResetToken: string | undefined) =>
  createActionWithPayload('@user/STORE_PASSWORD_RESET_TOKEN', passwordResetToken);

export const setLoggedIn = (isLoggedIn: boolean) =>
  createActionWithPayload('@user/SET_LOGGED_IN', isLoggedIn);

export const setLoggingOut = (isLoggingOut: boolean) =>
  createActionWithPayload('@user/SET_LOGGING_OUT', isLoggingOut);

export const updateEmailFieldStorage = (payload?: string) =>
  createActionWithPayload('@user/UPDATE_EMAIL_FIELD_STORAGE', payload);

export type UserActions = ReturnType<
  | typeof updateRegistrationProfile
  | typeof storeUser
  | typeof setSessionValidationState
  | typeof clearUser
  | typeof storeUserProfile
  | typeof setProfileStatistics
  | typeof storePasswordResetToken
  | typeof setEpisodeJoined
  | typeof setLoggedIn
  | typeof setLoggingOut
  | typeof updateEmailFieldStorage
>;
