import Box from 'components/Box';
import Card from 'components/Card';
import CategoryIcon from 'components/CategoryIcon';
import ProgressBar from 'components/ProgressBar';
import Stack from 'components/Stack';
import Text from 'components/Text';
import CheckedImageUrl from 'public/images/checked.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getCategoryById } from 'selectors/categorySelectors';

interface BaseProps {
  categoryId: string;
  title: string;
  imageUrl?: string;
  points: number;
  categoryFinished?: boolean;
  categoryRunning?: boolean;
}

interface CardWithTotal extends BaseProps {
  totalPoints: number;
  percentage?: never;
}

interface CardWithPercentage extends BaseProps {
  totalPoints?: never;
  percentage: number;
}

type Props = CardWithTotal | CardWithPercentage;

const CategoryCard = ({
  categoryId,
  title,
  imageUrl,
  totalPoints,
  points,
  categoryRunning = false,
  categoryFinished = false,
  percentage,
}: Props) => {
  const category = useSelector((state: AppState) => getCategoryById(state, categoryId));
  const image = imageUrl || category?.imageUrl;
  const calculatedPercentage =
    totalPoints !== undefined ? (points / totalPoints) * 100 : percentage || 0;

  return (
    <Card
      className="CategoryCard"
      shadow={categoryRunning ? 'strong' : categoryFinished ? 'small' : 'medium'}
      borderRadius="medium"
      display="flex"
      padding="small"
    >
      <Box paddingRight="medium">
        <CategoryIcon
          color={categoryRunning ? 'red' : categoryFinished ? 'gray' : 'blue'}
          url={categoryFinished ? CheckedImageUrl : image}
          title={title}
        />
      </Box>
      <Stack spacing="xsmall" flexGrow={1}>
        <Text
          fontSize="large"
          color={categoryRunning ? 'secondary' : categoryFinished ? 'darkGray' : 'black'}
        >
          {title}
        </Text>
        <Stack spacing="xsmall" flexGrow={1} horizontal justifyContent="space-between">
          <Text color="darkGray" fontSize="xsmall">
            {`${points}${totalPoints !== undefined ? ` / ${totalPoints}` : ''} punkte`}
          </Text>
          {percentage !== undefined && (
            <Text color="darkGray" fontSize="xsmall">
              {`${percentage}%`}
            </Text>
          )}
        </Stack>
        <ProgressBar color="primary" value={calculatedPercentage} />
      </Stack>
    </Card>
  );
};

export default CategoryCard;
