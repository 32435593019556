// Version of normalize that filters out keys that hold
// a reference to an object or array.

type ValueOf<T> = T[keyof T];

type ValidKeyType = string | number | symbol;

type ValidKeyOf<T> = ValueOf<{
  [K in keyof T]: T[K] extends ValidKeyType ? K : never;
}>;

export const normalize = <T extends Record<string, any>>(
  from: T[] = [],
  key: ValidKeyOf<T>,
): Record<ValidKeyType, T> => {
  const target: Record<ValidKeyType, T> = {};

  for (const entity of from) {
    target[entity[key]] = entity;
  }

  return target;
};
