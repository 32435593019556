import { closeOverlay } from 'actions/overlayActions';
import { cx } from 'cx';
import { useAnimationState } from 'hooks/useAnimationState';
import { useGlobalCron } from 'hooks/useGlobalCron';
import { useLayer } from 'hooks/useLayer';
import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOverlayData } from 'selectors/overlaySelectors';
import { getClientTime } from 'utils/clientTime';
import './Overlay.css';
import PromoOverlay from './PromoOverlay';
import TextOverlay from './TextOverlay';

const Overlay: FC = () => {
  const dispatch = useDispatch();
  const overlay = useSelector(getOverlayData);
  const now = getClientTime();
  const isDialogOpen =
    !overlay.closed &&
    overlay.startTime !== -1 &&
    overlay.startTime <= now &&
    overlay.endTime > now;
  const [state, close] = useAnimationState(isDialogOpen);
  const layer = useLayer();
  const [count, setCount] = useState(0);

  // Close overlay when endTime is in the past
  useGlobalCron('hide-overlay', overlay.endTime, () => setCount(count + 1));

  const { leave, open } = state;
  const { text, title, imageUrl, isClosable } = overlay;

  if (!open) {
    return null;
  }

  const onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === 'Overlay-back-Leave') {
      close();
    }
  };

  const onClose = () => {
    if (isClosable) {
      dispatch(closeOverlay());
    }
  };

  const baseClass = 'Overlay';

  const dialog = (
    <div className={baseClass}>
      <div
        className={cx(`${baseClass}-back`, leave && 'is-leave')}
        onClick={onClose}
        onAnimationEnd={onAnimationEnd}
      />
      {imageUrl ? (
        <PromoOverlay imageUrl={imageUrl} isClosable={isClosable} leave={leave} onClose={onClose} />
      ) : (
        <TextOverlay text={text} title={title} isClosable={isClosable} leave={leave} />
      )}
    </div>
  );

  return createPortal(dialog, layer());
};

export default Overlay;
