import { history } from 'config/history';
import { ThunkAction } from './helpers';

export const push =
  (path: string): ThunkAction =>
  () => {
    history.push(path);
  };

export const replace = (path: string) => () => {
  history.replace(path);
};

export const back = () => () => {
  history.goBack();
};
