import { StatisticsActions } from 'actions/statisticsActions';
import { Reducer } from 'redux';
import { RehydrateAction } from 'redux-persist';
import {
  StatisticsEpisodeDetailsResultsItems,
  StatisticsEpisodeLeaderboardItems,
  StatisticsEpisodeResults,
  StatisticsRegionFilterState,
  StatisticsShowFilterState,
} from 'types/statistics';
import { normalize } from 'utils/normalize';

export interface StatisticsReducer {
  showFilter: StatisticsShowFilterState;
  regionFilter: StatisticsRegionFilterState;
  episodes: StatisticsEpisodeResults;
  episodeHighlights: StatisticsEpisodeDetailsResultsItems;
  episodeLeaderboard: StatisticsEpisodeLeaderboardItems;
  loading: boolean;
}

export const initialState: StatisticsReducer = {
  showFilter: null,
  regionFilter: null,
  episodes: {},
  episodeHighlights: {},
  episodeLeaderboard: {},
  loading: true,
};

export const statisticsReducer: Reducer<StatisticsReducer, StatisticsActions | RehydrateAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@statistics/SET_EPISODE_LIST': {
      return {
        ...state,
        episodes: normalize(action.payload, 'episodeCode'),
      };
    }
    case '@statistics/SET_EPISODE_HIGHLIGHTS': {
      return {
        ...state,
        episodeHighlights: {
          [action.payload.episodeCode]: {
            ...action.payload,
            fetchSucces: true,
          },
        },
      };
    }
    case '@statistics/SET_EPISODE_HIGHLIGHTS_NOT_FOUND': {
      return {
        ...state,
        episodeHighlights: {
          [action.payload]: {
            fetchSucces: false,
          },
        },
      };
    }
    case '@statistics/SET_EPISODE_LEADERBOARD': {
      return {
        ...state,
        episodeLeaderboard: {
          [action.payload.leaderboard.episodeCode]: action.payload.leaderboard,
        },
      };
    }
    case '@statistics/FILTERS_RESET': {
      return {
        ...state,
        showFilter: initialState.showFilter,
        regionFilter: initialState.regionFilter,
      };
    }

    case '@statistics/REGION_FILTER_UPDATE': {
      return {
        ...state,
        showFilter: null,
        regionFilter: action.payload,
      };
    }

    case '@statistics/SHOW_FILTER_UPDATE': {
      return {
        ...state,
        regionFilter: null,
        showFilter: action.payload,
      };
    }

    case '@statistics/SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
