import { cx } from 'cx';
import { useAnimationState } from 'hooks/useAnimationState';
import React, { ReactNode } from 'react';
import './paper.css';

export type Effects = 'fade' | 'slideUp';

interface Props {
  className?: string;
  effect?: Effects;
  fit?: boolean;
  open?: boolean;
  children?: ReactNode;
}

const animationLeaveExpression = /^Paper-.*?Leave$/;

const Paper = ({ open = false, effect = 'fade', className, fit, children }: Props) => {
  const [state, close] = useAnimationState(open);

  const onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (animationLeaveExpression.test(event.animationName)) {
      close();
    }
  };

  if (!state.open) {
    return null;
  }

  return (
    <div
      className={cx(
        'Paper',
        state.leave && 'is-leave',
        className,
        `effect-${effect}`,
        fit && 'is-fit',
      )}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  );
};

export default Paper;
