import { EpisodeActions } from 'actions/episodeActions';
import { EventActions } from 'actions/eventActions';
import { Reducer } from 'redux';
import { Event, InteractionEvent } from 'types/events';
import { normalize } from 'utils/normalize';

export interface EventsReducer {
  [key: string]: Event;
}

export const initialState: EventsReducer = {};

export const eventsReducer: Reducer<EventsReducer, EventActions | EpisodeActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@episode/CLEAR_EPISODE':
      return initialState;

    case '@events/SET_EVENT':
      return {
        ...state,
        [action.payload.eventId]: {
          ...state[action.payload.eventId],
          ...action.payload,
        },
      };

    case '@event/SET_EVENTS':
      return normalize(action.payload, 'eventId');

    case '@event/ADD_CORRECT_ANSWERS': {
      const correctAnswers = action.payload;
      const nextEvents: Record<string, Event> = {};

      Object.keys(correctAnswers).forEach((key) => {
        const event = state[key] as Event;
        const correctAnswer = correctAnswers[key];

        if (!event) {
          return;
        }

        if (
          event.type === 'ROUND' ||
          event.type === 'CATEGORYSTART' ||
          event.type === 'WAITINGFORLEADERBOARD' ||
          event.type === 'ENDOFSHOW'
        ) {
          return;
        }

        // Skip already set entries
        if (event.content.correctAnswer === correctAnswer) {
          return;
        }

        nextEvents[key] = {
          ...event,
          content: { ...event.content, correctAnswer },
        } as InteractionEvent;
      });

      return {
        ...state,
        ...nextEvents,
      };
    }

    default:
      return state;
  }
};
