import { useEffect, useRef } from 'react';

type RequestCallback = (time: number, done: () => void) => void;
type Deps = any[];

export const useRaf = (callback: RequestCallback, deps: Deps = []) => {
  const requestRef = useRef<number>(-1);
  const previousTimeRef = useRef<number>();

  function animate(time: number) {
    let done = false;

    function finish() {
      done = true;
    }

    callback(time, finish);

    if (done) {
      return;
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, deps);
};
