import Box from 'components/Box';
import { cx } from 'cx';
import React from 'react';
import './ToggleButton.css';

interface Props {
  children: React.ReactNode;
  checked?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  size?: 'medium';
}

const ToggleButton = ({ children, checked, onClick, icon, size }: Props) => {
  return (
    <Box
      component="button"
      fontSize="medium"
      color="white"
      type="button"
      className={cx(`ToggleButton size-${size}`, checked && 'is-checked', !!icon && 'has-icon')}
      onClick={onClick}
    >
      {children}
      {icon}
    </Box>
  );
};

export default ToggleButton;
