import { migrateUser } from 'actions/migrationActions';
import { AppState } from 'reducers';
import { Store } from 'redux';
import { PersistedState } from 'redux-persist';
import { getMigrationStatus } from 'selectors/migrationSelectors';

export const connectAuthentication = (store: Store<AppState & PersistedState, any>) => {
  store.subscribe(() => {
    const state = store.getState();

    if (!state._persist.rehydrated) {
      return;
    }

    const migrationStatus = getMigrationStatus(state);

    if (migrationStatus !== 'FINISHED') {
      if (migrationStatus === 'PENDING') {
        store.dispatch(migrateUser());
      }

      return;
    }
  });
};
