import Box from 'components/Box';
import ProgressBar from 'components/ProgressBar';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StepperItem from './BarStepperItem';

interface Props {
  children: React.ReactNode;
  startIndex?: number;
}

const BarStepper = ({ children, startIndex = 0 }: Props) => {
  const { t } = useTranslation();
  const nodes = React.Children.toArray(children);
  const elements = nodes.filter((node) =>
    React.isValidElement<ReturnType<typeof StepperItem>>(node),
  ) as ReactElement<Parameters<typeof StepperItem>[0]>[];
  const [index, setIndex] = useState(0);
  const total = elements.length;
  const progress = (index + 1) / total;

  const next = () => {
    const isLastStep = index === total - 1;

    if (isLastStep) {
      return;
    }

    setIndex(index + 1);
  };

  const prev = () => {
    if (index === 0) {
      return;
    }

    setIndex(index - 1);
  };

  useEffect(() => {
    if (startIndex === total || startIndex < 0) {
      return;
    }

    if (startIndex || startIndex === 0) {
      setIndex(startIndex);
    }
  }, [startIndex]);

  return (
    <>
      <Box paddingX="gutter">
        <Stack spacing="xsmall">
          <Text color="warning" fontWeight="extraBold" fontSize="xsmall" textAlign="center">
            {t('STEP {{index}} OF {{total}}', { index: index + 1, total })}
          </Text>
          <ProgressBar value={progress * 100} />
        </Stack>
      </Box>

      <Box key={index} flexGrow={1} overflow="auto">
        {elements[index].props.render({ next, prev })}
      </Box>
    </>
  );
};

export default BarStepper;
