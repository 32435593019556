import { AppState } from 'reducers';

export const getSchedule = (state: AppState) => state.episodeSchedule;

export const getScheduleEventPhase = (state: AppState) => {
  const { currentEventPhase } = getSchedule(state);

  return currentEventPhase && currentEventPhase.name;
};

export const isAboutToStart = (state: AppState) => getSchedule(state).aboutToStart;

export const getStartTime = (state: AppState) => {
  const { offset: averageOffset, syncTime } = getSchedule(state);

  const offset = averageOffset + (Date.now() - syncTime);

  return Date.now() - offset;
};

export const isEpisodeJoined = (state: AppState) => {
  const { user, episode } = state;

  if (!user || !episode) {
    return false;
  }

  if (episode && episode.broadcastWindowEndDate && Date.now() > episode.broadcastWindowEndDate) {
    return false;
  }

  const { episodeJoined } = user;
  const { episodeCode, state: episodeState } = episode;

  return episodeJoined === episodeCode && episodeState === 'BROADCAST';
};
