import Box from 'components/Box';
import Logo from 'components/Logo';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const LoadingScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="full"
    >
      <Stack spacing="medium">
        <Logo />
        <Text color="white" fontSize="large">
          {t('Loading')}
        </Text>
      </Stack>
    </Box>
  );
};

export default LoadingScreen;
