import { Route } from '@blockle/router';
import React from 'react';
import StatisticsEpisodeDetailsScreen from './StatisticsEpisodeDetailsScreen';
import StatisticsEpisodeListScreen from './StatisticsEpisodeListScreen';
import StatisticsEpisodeRankingsScreen from './StatisticsEpisodeRankingsScreen';

const StatisticsScreen = () => (
  <>
    <Route path="/" render={(match) => match && <StatisticsEpisodeListScreen />} exact />
    <Route
      path="/episode/:id"
      render={(match, params) => match && <StatisticsEpisodeDetailsScreen episodeId={params.id} />}
      exact
    />
    <Route
      path="/rankings/:id"
      render={(match, params) => match && <StatisticsEpisodeRankingsScreen episodeId={params.id} />}
      exact
    />
  </>
);

export default StatisticsScreen;
