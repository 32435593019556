import { useFieldState } from '@blockle/form';
import Warning from 'components/Warning';
import React from 'react';

interface Props {
  inputName: string;
  children: React.ReactNode;
}

/**
 * Show error message when form input is invalid
 *
 * <Input name="x1" required />
 * <FormWarning name="x1">Input `x1` required</FormWarning>
 */
const FormWarning = ({ children, inputName }: Props) => {
  const state = useFieldState(inputName, undefined);

  if (state.touched && state.invalid) {
    return <Warning>{children}</Warning>;
  }

  return null;
};

export default FormWarning;
