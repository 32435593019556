import Box from 'components/Box';
import { cx } from 'cx';
import { PickStyleProps } from 'hooks/useStyles';
import React, { forwardRef } from 'react';
import './text.css';

interface Props
  extends PickStyleProps<
    'textAlign' | 'textTransform' | 'fontSize' | 'fontWeight' | 'color' | 'display'
  > {
  component?: 'span' | 'p' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
  className?: string;
}

export type A = Props['textAlign'];

const Text = forwardRef(
  (
    {
      component = 'span',
      children,
      textAlign,
      textTransform,
      fontSize = 'medium',
      fontWeight,
      display = 'block',
      color,
      className,
    }: Props,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        component={component}
        textAlign={textAlign}
        textTransform={textTransform}
        fontSize={fontSize}
        fontWeight={fontWeight}
        display={display}
        className={cx('Text', className)}
        color={color}
      >
        {children}
      </Box>
    );
  },
);

Text.displayName = 'Text';

export default Text;
