import { getTotalPoints } from 'selectors/answersSelectors';
import { getEpisode } from 'selectors/episodeSelectors';
import { getGoodscoreSubmitted } from 'selectors/goodscoreSelectors';
import { getUserCountryCode } from 'selectors/userSelectors';
import { createActionWithPayload, ThunkAction } from './helpers';

export const submitGoodScore =
  (tZero: number): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    const appState = getState();
    const episode = getEpisode(appState);
    const countryCode = getUserCountryCode(appState);
    const isGoodscoreSubmitted = getGoodscoreSubmitted(appState);

    if (!episode || isGoodscoreSubmitted) return;

    const score = getTotalPoints(appState, episode.episodeCode);
    const scoreThreshold = episode.submitScoreThreshold || 2000;

    if (score > scoreThreshold) {
      const offset = episode ? Date.now() - tZero : -1;

      await api.put(`qzm/episodes/${episode.episodeCode}/goodscore`, {
        score,
        offset,
      });

      // show the jackpot overlay for AT liveshows, and for pre-recorded shows only if useThreshold is true
      if (countryCode === 'AT' && (episode.interactionTiming === 'LIVE' || episode.useThreshold)) {
        dispatch(triggerJackpotOverlay(episode.episodeCode));
      }

      // flag as submitted when submitted
      dispatch(setSubmittedGoodscore(episode.episodeCode));
    }
  };

export const setSubmittedGoodscore = (episodeCode?: string) =>
  createActionWithPayload('@goodscore/SET_SUBMITTED', episodeCode);

export const triggerJackpotOverlay = (episodeCode: string) =>
  createActionWithPayload('@episode/TRIGGER_JACKPOT_OVERLAY', episodeCode);

export type GoodscoreActions = ReturnType<
  typeof setSubmittedGoodscore | typeof triggerJackpotOverlay
>;
