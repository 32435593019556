import { SoundLib } from 'lib/SoundLib';
import React, { createContext, FC, useMemo } from 'react';

interface Context {
  load: (url: string) => void;
  play: (url: string) => void;
}

export const SoundContext = createContext<Context | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const SoundProvider: FC<Props> = ({ children }) => {
  const sound = useMemo(() => new SoundLib(), []);

  return <SoundContext.Provider value={sound}>{children}</SoundContext.Provider>;
};
