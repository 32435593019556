import { joinGameType, leaveGame } from 'actions/gameActions';
import { push } from 'actions/historyActions';
import { pause, resumePracticeGame } from 'actions/practiceScheduleActions';
import NextQuestionInteraction from 'components/Interactions/NextQuestionInteraction';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import InteractionScreen from 'screens/Interaction/InteractionScreen';
import LoadingScreen from 'screens/Loading';
import { canJoinEpisode } from 'selectors/episodeSelectors';
import { episodeHasEvents } from 'selectors/eventsSelectors';
import { getPracticeSchedule } from 'selectors/practiceScheduleSelectors';
import PracticeGameEnded from './PracticeGameEnded';

const PracticeGameScreen: FC = () => {
  const dispatch = useDispatch();
  const schedule = useSelector(getPracticeSchedule);
  const hasEvents = useSelector((state: AppState) => episodeHasEvents(state, 'practice'));
  const broadcastStarted = useSelector(canJoinEpisode);

  useEffect(() => {
    dispatch(joinGameType('practice'));

    if (!hasEvents) {
      dispatch(resumePracticeGame());
    }

    // For testing specific interactions: Date.now() - "interaction.offset"
    // dispatch(unpauseAt(Date.now() - 642000));

    return () => {
      dispatch(leaveGame());
      dispatch(pause());
    };
  }, []);

  useEffect(() => {
    if (broadcastStarted) dispatch(push('/'));
  }, [broadcastStarted]);

  if (schedule.startTime && hasEvents) {
    if (schedule.currentEventId && schedule.currentEventPhase) {
      return (
        <InteractionScreen
          eventId={schedule.currentEventId}
          eventPhase={schedule.currentEventPhase.name}
          startTime={schedule.startTime}
          episodeCode="practice"
        />
      );
    }

    if (schedule.nextEventId) {
      return <NextQuestionInteraction episodeCode="practice" eventId={schedule.nextEventId} />;
    }

    return <PracticeGameEnded />;
  }

  return <LoadingScreen />;
};

export default PracticeGameScreen;
