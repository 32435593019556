import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import './CountdownItem.css';

interface Props {
  label?: string;
  value: React.ReactNode | string;
}

const CountdownItem = ({ value, label }: Props) => {
  return (
    <Box className="CountdownItem" paddingX="small" paddingBottom="xsmall" textAlign="center">
      <Text fontSize="xlarge" fontWeight="bold" color="white" className="CountdownItem-digit">
        {value}
      </Text>
      {label && (
        <Text color="darkGray" fontSize="xsmall" textTransform="uppercase">
          {label}
        </Text>
      )}
    </Box>
  );
};

export default CountdownItem;
