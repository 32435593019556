import TriviaInteraction from 'components/Interactions/TriviaInteraction';
import React from 'react';
import { PhaseNames, TriviaEvent } from 'types/events';
import './PreviewScreen.css';

interface Props {
  episodeCode?: string;
  event?: TriviaEvent;
  previewPhase: PhaseNames;
}

const PreviewTriviaScreen = ({ episodeCode, event, previewPhase }: Props) => {
  if (!event || !episodeCode) return null;

  return (
    <TriviaInteraction
      event={event}
      eventPhase={previewPhase}
      startTime={Date.now()}
      episodeCode={episodeCode}
    />
  );
};

export default PreviewTriviaScreen;
