import { openBrowser } from 'actions/bridgeActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading/Heading';
import MenuToggle from 'components/MenuToggle';
import Points from 'components/Points';
import Spacer from 'components/Spacer';
import Stack from 'components/Stack';
import TopBar from 'components/TopBar';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { InterstitialEvent } from 'types/events';

interface Props {
  episodeCode: string;
  event: InterstitialEvent;
}

const InterstitialInteraction: FC<Props> = ({ episodeCode, event }) => {
  const dispatch = useDispatch();
  const { content } = event;

  return (
    <>
      <TopBar logo left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Spacer spacing="large" />

      <Box>
        <Stack spacing="xlarge">
          <Heading title={content.title} subTitle={content.text} />

          {content.imageUrl && (
            <img
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              src={content.imageUrl}
            />
          )}

          {content.url && content.eventProperties.BUTTONTEXT && (
            <Box display="flex" justifyContent="center">
              <Button onClick={() => dispatch(openBrowser(content.url as string))}>
                {content.eventProperties.BUTTONTEXT}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default InterstitialInteraction;
