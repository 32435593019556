import { AudioSyncReducer } from 'reducers/audioSync';
import { createAction, createActionWithPayload } from './helpers';

export const setAudioSync = (payload: Partial<AudioSyncReducer>) =>
  createActionWithPayload('@audioSync/SET_AUDIO_SYNC', payload);

export const startAudioSync = () => createAction('@audioSync/START_AUDIO_SYNC');

export const stopAudioSync = () => createAction('@audioSync/STOP_AUDIO_SYNC');

export const setMicrophoneEnabled = (enabled: boolean) =>
  createActionWithPayload('@audioSync/SET_MICROPHONE_ENABLED', enabled);

export const setMicrophoneEnabledErrored = (errored: boolean) =>
  createActionWithPayload('@audioSync/SET_MICROPHONE_ENABLED_ERRORED', errored);

export type AudioSyncActions = ReturnType<
  | typeof setAudioSync
  | typeof startAudioSync
  | typeof stopAudioSync
  | typeof setMicrophoneEnabled
  | typeof setMicrophoneEnabledErrored
>;
