import { resetFilters, updateRegionFilter, updateShowFilter } from 'actions/statisticsActions';
import Box from 'components/Box';
import FlatButton from 'components/FlatButton';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import ToggleButton from 'components/ToggleButton';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isFacebookUser } from 'selectors/socialSelectors';
import {
  getStatisticsSelectedRegionFilter,
  getStatisticsSelectedShowFilter,
} from 'selectors/statisticsSelectors';
import { StatisticsShowFilterLabels } from 'types/statistics';
import { RegionNames } from 'types/user';
import StatisticsRegionFilterItem from './StatisticsRegionFilterItem';
import StatisticsShowFilterItem from './StatisticsShowFilterItem';

const SHOW_FILTER_MAP: Array<StatisticsShowFilterLabels> = ['MALE', 'FEMALE', 'FRIENDS'];
const REGION_FILTER_MAP: Array<RegionNames> = [
  'vienna',
  'tyrol',
  'lowerAustria',
  'upperAustria',
  'styria',
  'carinthia',
  'salzburg',
  'vorarlberg',
  'burgenland',
];

const StatisticsFilter: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showFilterSelected, isShowFilterSelected] = useState<boolean>(false);
  const [regionFilterSelected, isRegionFilterSelected] = useState<boolean>(false);

  const selectedShowFilter = useSelector(getStatisticsSelectedShowFilter);
  const selectedRegionFilter = useSelector(getStatisticsSelectedRegionFilter);
  const facebookUser = useSelector(isFacebookUser);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <Stack spacing="medium" divider data-testid="statistics-filter">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ToggleButton
          checked={showFilterSelected || Boolean(selectedShowFilter)}
          onClick={() => {
            isShowFilterSelected(!showFilterSelected);
            isRegionFilterSelected(false);
            if (!showFilterSelected) dispatch(updateRegionFilter(null));
          }}
          size="medium"
          icon={<Icon name="arrow-down" color="white" size="large" />}
        >
          {t('Show')}
        </ToggleButton>
        <ToggleButton
          checked={regionFilterSelected || Boolean(selectedRegionFilter)}
          onClick={() => {
            isRegionFilterSelected(!regionFilterSelected);
            isShowFilterSelected(false);
            if (!selectedRegionFilter) dispatch(updateShowFilter(null));
          }}
          size="medium"
          icon={<Icon name="arrow-down" color="white" size="large" />}
        >
          {t('Region')}
        </ToggleButton>
      </Box>
      {(showFilterSelected || regionFilterSelected) && (
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
          {showFilterSelected &&
            SHOW_FILTER_MAP.map((key) => {
              if (key === 'FRIENDS' && !facebookUser) {
                return;
              }

              return (
                <StatisticsShowFilterItem
                  key={key}
                  active={selectedShowFilter === key}
                  rankFilterName={key}
                />
              );
            })}
          {regionFilterSelected &&
            REGION_FILTER_MAP.map((key) => (
              <StatisticsRegionFilterItem
                key={key}
                active={selectedRegionFilter === key}
                rankFilterName={key}
              />
            ))}
        </Box>
      )}
      {(showFilterSelected || regionFilterSelected) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ToggleButton
            onClick={() => {
              dispatch(resetFilters());
            }}
            icon={<Icon name="close" color="white" size="medium" />}
          >
            {t('Clear all')}
          </ToggleButton>
          <FlatButton
            onClick={() => {
              isShowFilterSelected(false);
              isRegionFilterSelected(false);
            }}
          >
            {t('Done')}
          </FlatButton>
        </Box>
      )}
    </Stack>
  );
};

export default StatisticsFilter;
