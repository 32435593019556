import { GatekeeperActions } from 'actions/gatekeeperActions';
import { Reducer } from 'redux';
import { GatekeeperReducer } from 'types/gatekeeper';

export const initialState: GatekeeperReducer = {
  status: 'GOOD',
};

export const gatekeeperReducer: Reducer<GatekeeperReducer, GatekeeperActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case '@gatekeeper/SET_STATUS':
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
};
