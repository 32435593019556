import { trackEvent } from 'actions/analyticsActions';
import { setPracticeAnswer } from 'actions/practiceAnswerActions';
import AnswerButton from 'components/AnswerButton';
import Box from 'components/Box';
import LogoProgress from 'components/LogoProgress';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import { useFlip } from 'hooks/useFlip';
import { useSound } from 'hooks/useSound';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getPracticeAnswer } from 'selectors/practiceAnswerSelectors';
import { PhaseNames, PracticeQuestionEvent } from 'types/events';
import correctSoundUrl from '../../public/audio/correct-answer.mp3';
import startQuestionSoundUrl from '../../public/audio/countdown-start.mp3';
import showQuestionOptionsSoundUrl from '../../public/audio/show-question-options.mp3';
import showQuestionSoundUrl from '../../public/audio/show-question.mp3';
import incorrectSoundUrl from '../../public/audio/wrong-answer.mp3';
import './PracticeQuestionInteraction.css';

interface Props {
  episodeCode: string;
  event: PracticeQuestionEvent;
  eventPhase: PhaseNames;
  startTime: number;
}

const PracticeQuestionInteraction: FC<Props> = ({ event, eventPhase, startTime }) => {
  const dispatch = useDispatch();
  const { setRef } = useFlip();
  const storedAnswer = useSelector((state: AppState) => getPracticeAnswer(state, event.eventId));
  const { text, answers, correctAnswer } = event.content;
  const disabled =
    eventPhase === 'SHOW' ||
    eventPhase === 'SHOW_QUESTION' ||
    eventPhase === 'STOP' ||
    eventPhase === 'RESULTS' ||
    eventPhase === 'FINISHED' ||
    !!storedAnswer;
  const showQuestion = eventPhase !== 'SHOW';
  const startPhase = event.phases.find(({ name }) => name === 'START');
  const stopPhase = event.phases.find(({ name }) => name === 'STOP');
  const showResult = eventPhase === 'RESULTS' || eventPhase === 'FINISHED';
  const showQuestionOptionsSound = useSound(showQuestionOptionsSoundUrl);
  const showQuestionSound = useSound(showQuestionSoundUrl);
  const startQuestionSound = useSound(startQuestionSoundUrl);
  const correctSound = useSound(correctSoundUrl);
  const incorrectSound = useSound(incorrectSoundUrl);

  useEffect(() => {
    switch (eventPhase) {
      case 'SHOW':
        return showQuestionOptionsSound.play();
      case 'SHOW_QUESTION':
        return showQuestionSound.play();
      case 'START':
        return startQuestionSound.play();
      case 'RESULTS': {
        if (correctAnswer === storedAnswer?.answerCode) {
          return correctSound.play();
        } else {
          return incorrectSound.play();
        }
      }
    }
  }, [eventPhase]);

  if (!startPhase || !stopPhase) {
    return null;
  }

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode="practice" />} />

      <Box
        className="PracticeQuestionInteraction-questionArea"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX="xlarge"
        paddingY="large"
      >
        <Stack spacing="medium" align="center">
          <Box display="inline-block" ref={setRef('logo')}>
            <LogoProgress
              startTime={startTime + startPhase.offset}
              endTime={startTime + stopPhase.offset}
            />
          </Box>

          {showQuestion && (
            <Text
              ref={setRef('questionText')}
              color="white"
              textAlign="center"
              fontSize="large"
              fontWeight="bold"
            >
              {text}
            </Text>
          )}
        </Stack>
      </Box>

      <Panel variant="grey">
        <Box paddingX="gutter">
          <Stack spacing="medium">
            {answers.map((answer) => (
              <AnswerButton
                key={answer.code}
                disabled={disabled}
                letter={answer.code}
                selected={storedAnswer?.answerCode === answer.code}
                correct={showResult && correctAnswer === answer.code}
                incorrect={
                  showResult &&
                  storedAnswer?.answerCode === answer.code &&
                  correctAnswer !== answer.code
                }
                onClick={() => {
                  dispatch(
                    setPracticeAnswer({
                      eventId: event.eventId,
                      answerCode: answer.code,
                      episodeCode: event.episodeCode,
                    }),
                  );

                  dispatch(trackEvent('answer', 'click'));
                }}
              >
                {answer.text}
              </AnswerButton>
            ))}
          </Stack>
        </Box>
      </Panel>
    </>
  );
};

export default PracticeQuestionInteraction;
