import { updateRegionFilter } from 'actions/statisticsActions';
import Box from 'components/Box';
import RegionTranslate from 'components/RegionTranslate';
import ToggleButton from 'components/ToggleButton';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RegionNames } from 'types/user';

interface Props {
  rankFilterName: RegionNames;
  active: boolean;
}

const StatisticsRegionFilterItem: FC<Props> = ({ rankFilterName, active }) => {
  const dispatch = useDispatch();

  const updateFilter = () => {
    const newShowFilterValue = active ? null : rankFilterName;

    dispatch(updateRegionFilter(newShowFilterValue));
  };

  return (
    <Box paddingRight="small" paddingBottom="small" data-testid="statistics-region-filter-item">
      <ToggleButton checked={active} onClick={updateFilter}>
        <RegionTranslate regionName={rankFilterName} />
      </ToggleButton>
    </Box>
  );
};

export default StatisticsRegionFilterItem;
