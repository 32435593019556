import Box from 'components/Box';
import { StyleProps } from 'hooks/useStyles';
import React from 'react';
import './ProgressBar.css';

interface Props {
  value: number;
  color?: StyleProps['backgroundColor'];
}

const ProgressBar = ({ value, color = 'warning' }: Props) => {
  return (
    <Box className="ProgressBar" backgroundColor="lightGrey">
      <Box
        className="ProgressBar-inner"
        backgroundColor={color}
        style={{ transform: `translateX(-${100 - value}%)` }}
        height="full"
        width="full"
      />
    </Box>
  );
};

export default ProgressBar;
