import { AppState } from 'reducers';

export const getStatisticsEpisodes = (state: AppState) => state.statistics.episodes;

export const hasEpisodes = (state: AppState) => Object.keys(state.statistics.episodes).length > 0;

export const getStatisticsEpisodesSorted = (state: AppState) => {
  const statistics = Object.values(state.statistics.episodes);

  return statistics.sort((a, b) => {
    if (a.broadcastWindowStartDate < b.broadcastWindowStartDate) {
      return 1;
    }

    if (a.broadcastWindowStartDate > b.broadcastWindowStartDate) {
      return -1;
    }

    return 0;
  });
};

export const getStatisticsByEpisodeCode = (state: AppState, statisticsEpisodeCode: string) =>
  state.statistics.episodeHighlights[statisticsEpisodeCode];

export const getEpisodeHost = (state: AppState, statisticsEpisodeCode: string) => {
  const statisticsByEpisode = getStatisticsByEpisodeCode(state, statisticsEpisodeCode);

  if (!statisticsByEpisode || !statisticsByEpisode.fetchSucces) return null;

  const { broadcastWindowStartDate, episodeWinner } = statisticsByEpisode;

  return {
    broadcastWindowStartDate,
    ...episodeWinner,
  };
};

export const getStatisticsRankListByEpisodeCode = (
  state: AppState,
  statisticsEpisodeCode: string,
) => state.statistics.episodeLeaderboard[statisticsEpisodeCode];

export const getStatisticsSelectedShowFilter = (state: AppState) => state.statistics.showFilter;

export const getStatisticsSelectedRegionFilter = (state: AppState) => state.statistics.regionFilter;

export const isLoadingStatistics = (state: AppState) => state.statistics.loading;
