import Box from 'components/Box';
import Logo from 'components/Logo';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const IdleScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Box paddingY="xlarge">
      <Box paddingTop="large" />

      <Stack spacing="xlarge">
        <Box paddingX="gutter">
          <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
            {t('Stand by for the next question')}
          </Text>
        </Box>

        <Box paddingX="gutter" display="flex" justifyContent="center">
          <Logo full />
        </Box>
      </Stack>
    </Box>
  );
};

export default IdleScreen;
