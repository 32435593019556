import { push } from 'actions/historyActions';
import { setNotVerified } from 'actions/registrationActions';
import Button from 'components/Button';
import FlatButton from 'components/FlatButton';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import SignOutDialog from 'components/SignOutDialog';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import MailSent from 'public/icons/mail-sent.svg';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getNotVerified } from 'selectors/registrationSelectors';

interface Props {
  isStep?: boolean;
  includeSignout?: boolean;
}

const SignUpNotVerified: FC<Props> = ({ isStep, includeSignout }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSignOutDialog, setShowSignOutDialog] = useState(false);
  const isNotVerified = useSelector(getNotVerified);

  useEffect(() => {
    if (!isNotVerified) {
      dispatch(setNotVerified(true));
    }
  }, [isNotVerified]);

  const form = (
    <Stack spacing="xlarge" paddingX="gutter">
      <Text component="h1" fontSize="xlarge" fontWeight="bold" color="secondary" textAlign="center">
        {t('Confirm account')}
      </Text>
      <Stack spacing="medium" align="center">
        <MailSent />
        <Text fontSize="medium" textAlign="center" color="black" fontWeight="semiBold">
          {t('Please confirm your email address by clicking the link in your inbox')}
        </Text>
      </Stack>
      <Stack spacing="large">
        {includeSignout ? (
          <Stack spacing="small" align="center">
            <Button onClick={() => window.location.reload()}>{t("I've confirmed my email")}</Button>
            <FlatButton onClick={() => setShowSignOutDialog(true)}>{t('Cancel')}</FlatButton>
          </Stack>
        ) : (
          <Stack spacing="small" align="center">
            <Button
              onClick={() => {
                dispatch(setNotVerified(false));
                dispatch(push('/sign-in'));
              }}
            >
              {t('Okay')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  return (
    <>
      {isStep ? (
        <>
          <Stack spacing="medium" paddingY="gutter">
            {form}
          </Stack>
        </>
      ) : (
        <>
          <TopBar
            title={t('Create a profile')}
            left={!includeSignout && <MenuToggle toggleType="back" />}
          />
          <Panel>{form}</Panel>
        </>
      )}
      <SignOutDialog
        showSignOutDialog={showSignOutDialog}
        setShowSignOutDialog={setShowSignOutDialog}
      />
    </>
  );
};

export default SignUpNotVerified;
