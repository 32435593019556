import { logout } from 'actions/usersApiActions';
import PromptDialog from 'components/PromptDialog';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  showSignOutDialog: boolean | undefined;
  setShowSignOutDialog: (dialogState: boolean) => void;
  showWarning?: boolean;
}

const SignOutDialog: FC<Props> = ({ showSignOutDialog, setShowSignOutDialog, showWarning }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <PromptDialog
      showIcon
      showCancel
      showDialog={!!showSignOutDialog}
      onDialogClose={() => setShowSignOutDialog(false)}
      title={t('Sign Out')}
      message={
        showWarning
          ? t('Note: Your game history will be reset when you log back in.')
          : t('Are you sure you want to sign out?')
      }
      promptText={t('Sign Out')}
      onPromptTrigger={() => dispatch(logout())}
    />
  );
};

export default SignOutDialog;
