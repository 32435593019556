// Ported from "talpa-lib"
const SECRET = '84m5Gdpu47D31aKebFIDTrND44NFWdolWFitpKwYis95FY0K1YY5D7jG96YirxQi';

function xor(a: string, b: string) {
  let result = '';
  const aLength = a.length;
  const bLength = b.length;
  const maxLength = Math.max(aLength, bLength);
  let i;
  for (i = 0; i < maxLength; i++) {
    result += String.fromCharCode(a.charCodeAt(i % aLength) ^ b.charCodeAt(i % bLength));
  }
  return result;
}

function utf8Decode(utftext: string) {
  let string = '';
  let i = 0;
  let c = 0,
    c2 = 0,
    c3 = 0;
  while (i < utftext.length) {
    c = utftext.charCodeAt(i);
    if (c < 128) {
      string += String.fromCharCode(c);
      i++;
    } else if (c > 191 && c < 224) {
      c2 = utftext.charCodeAt(i + 1);
      string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = utftext.charCodeAt(i + 1);
      c3 = utftext.charCodeAt(i + 2);
      string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
      i += 3;
    }
  }
  return string;
}

export const decrypt = (str: string, token: string) => {
  const key = xor(token, SECRET);

  let result = window.atob(str);
  result = xor(key, result);
  result = utf8Decode(result);

  return result;
};
