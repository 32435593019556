/* eslint-disable */
!(function() {
  'use strict';
  var t = [].slice,
    e = [].concat,
    r = Function.prototype;
  r.bind ||
    (r.bind = function(r) {
      var n = this;
      if (arguments.length > 1) {
        var i = t.call(arguments, 1);
        return function() {
          var t = e.apply(i, arguments);
          return n.apply(r, t);
        };
      }
      return function() {
        return n.apply(r, arguments);
      };
    });
  var n = String.prototype;
  if (!n.trim) {
    var i = /(^\s+)|(\s+)$/g;
    n.trim = function() {
      return this.replace(i, '');
    };
  }
  Date.now ||
    (Date.now = function() {
      return new Date().getTime();
    }),
    (function() {
      var t = Date.parse('2011-06-02T09:34:29+02:00'),
        e = Date.parse('2011-06-02T09:34:29+0200');
      if (t !== e || 1307000069e3 !== t) {
        var r = Date.parse;
        Date.parse = function(t) {
          var e,
            n,
            i = /^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/,
            a = i.exec(t) || [];
          if (a[1]) {
            e = a[1].split(/\D/);
            for (var c = 0, u = e.length; c < u; c++) e[c] = parseInt(e[c], 10) || 0;
            return (
              (e[1] -= 1),
              (e = new Date(Date.UTC.apply(Date, e))),
              e.getDate()
                ? (a[5] &&
                    ((n = 60 * parseInt(a[5], 10)),
                    a[6] && (n += parseInt(a[6], 10)),
                    '+' === a[4] && (n *= -1),
                    n && e.setUTCMinutes(e.getUTCMinutes() + n)),
                  e.getTime())
                : NaN
            );
          }
          return r(t);
        };
      }
    })();
  var a = Array.prototype;
  a.indexOf ||
    (a.indexOf = function(t, e) {
      var r,
        n = Object(this),
        i = n.length;
      for (r = e || 0; r < i; r++) if (r in n && n[r] === t) return r;
      return -1;
    }),
    a.lastIndexOf ||
      (a.lastIndexOf = function(t, e) {
        var r,
          n = Object(this),
          i = n.length;
        for (r = e || i - 1; r >= 0; r--) if (r in n && n[r] === t) return r;
        return -1;
      }),
    a.forEach ||
      (a.forEach = function(t, e) {
        var r,
          n = Object(this),
          i = n.length;
        for (r = 0; r < i; r++) r in n && t.call(e, n[r], r, n);
      }),
    a.map ||
      (a.map = function(t, e) {
        var r,
          n = [],
          i = Object(this),
          a = i.length;
        for (r = 0; r < a; r++) r in i && (n[r] = t.call(e, i[r], r, i));
        return n;
      }),
    a.filter ||
      (a.filter = function(t, e) {
        var r,
          n,
          i = [],
          a = Object(this),
          c = a.length;
        for (r = 0; r < c; r++) r in a && ((n = a[r]), t.call(e, n, r, a) && i.push(n));
        return i;
      }),
    a.some ||
      (a.some = function(t, e) {
        var r,
          n = Object(this),
          i = n.length;
        for (r = 0; r < i; r++) if (r in n && t.call(e, n[r], r, n)) return !0;
        return !1;
      }),
    a.every ||
      (a.every = function(t, e) {
        var r,
          n = Object(this),
          i = n.length;
        for (r = 0; r < i; r++) if (r in n && !t.call(e, n[r], r, n)) return !1;
        return !0;
      }),
    a.reduce ||
      (a.reduce = function(t, e) {
        var r = 0,
          n = Object(this),
          i = n.length;
        for (arguments.length <= 1 && ((e = n[0]), (r = 1)); r < i; r++)
          r in n && (e = t(e, n[r], r, n));
        return e;
      }),
    a.reduceRight ||
      (a.reduceRight = function(t, e) {
        var r = Object(this),
          n = r.length,
          i = n - 1;
        for (arguments.length <= 1 && ((e = r[i]), (i = n - 2)); i >= 0; i--)
          i in r && (e = t(e, r[i], i, r));
        return e;
      }),
    Object.keys ||
      (Object.keys = function(t) {
        var e,
          r = [],
          n = Object.prototype.hasOwnProperty;
        for (e in t) n.call(t, e) && r.push(e);
        return r;
      });
})();
!(function() {
  'use strict';
  Array.prototype.find ||
    (Array.prototype.find = function(r) {
      for (var t, n = this, i = n.length, o = arguments[1], f = 0; f < i; f++)
        if (((t = n[f]), r.call(o, t, f, n))) return t;
    });
})();
!(function() {
  'use strict';
  function t() {
    var t,
      n = 0,
      e = o.length;
    try {
      for (; n < e; n++) (t = o[n]), t.cb.apply(t.on, t.args);
    } finally {
      n < e && ++n < e && u(), o.splice(0, n);
    }
  }
  function n(t, n, e) {
    o.push({ cb: t, on: n, args: e || [] }), u();
  }
  var e = new Function('return this')(),
    i = e.window || {},
    r = i.MutationObserver || i.WebKitMutationObserver,
    o = [],
    u = r
      ? (function() {
          var n = document.createElement('div');
          return (
            new r(t).observe(n, { attributes: !0 }),
            function() {
              n.setAttribute('x', 'x');
            }
          );
        })()
      : e.setImmediate
      ? (function() {
          var n = !1;
          return function() {
            n ||
              (n = e.setImmediate(function() {
                (n = !1), t();
              }));
          };
        })()
      : i.addEventListener
      ? (function() {
          function n(n) {
            n.source === i && n.data === r && (n.stopPropagation(), (e = !1), t());
          }
          var e = !1,
            r = 'nextTick';
          return (
            i.addEventListener('message', n, !0),
            function() {
              e || (i.postMessage(r, '*'), (e = !0));
            }
          );
        })()
      : (function() {
          var n = !1;
          return function() {
            n ||
              (n = setTimeout(function() {
                (n = !1), t();
              }, 0));
          };
        })();
  e.nextTick = n;
})();
!(function() {
  'use strict';
  function t(t) {
    return '[object Array]' === Object.prototype.toString.call(t);
  }
  function e(t, e) {
    for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
  }
  var r = new Function('return this')();
  r.Promise ||
    (r.Promise = (function() {
      function t(t) {
        (this._callbacks = []),
          (this._errbacks = []),
          (this.promise = t),
          (this._status = 'pending'),
          (this._result = null);
      }
      function r(t, e, r, n) {
        return function(t) {
          var i;
          try {
            i = n(t);
          } catch (t) {
            return void r(t);
          }
          e(i);
        };
      }
      function n(e) {
        if (!(this instanceof n))
          throw new TypeError('Failed to construct "Promise": Please use the "new" operator.');
        if ('function' != typeof e)
          throw new TypeError('Promise constructor takes a function argument');
        var r = new t(this);
        this._resolver = r;
        try {
          e(
            function(t) {
              r.resolve(t);
            },
            function(t) {
              r.reject(t);
            },
          );
        } catch (t) {
          r.reject(t);
        }
      }
      return (
        e(t.prototype, {
          fulfill: function(t) {
            var e = this._status;
            ('pending' !== e && 'accepted' !== e) ||
              ((this._result = t), (this._status = 'fulfilled')),
              'fulfilled' === this._status &&
                (this._notify(this._callbacks, this._result),
                (this._callbacks = []),
                (this._errbacks = null));
          },
          reject: function(t) {
            t === this.promise && (t = new TypeError('You can not reject a promise with itself'));
            var e = this._status;
            ('pending' !== e && 'accepted' !== e) ||
              ((this._result = t), (this._status = 'rejected')),
              'rejected' === this._status &&
                (this._notify(this._errbacks, this._result),
                (this._callbacks = null),
                (this._errbacks = []));
          },
          resolve: function(t) {
            return t === this.promise
              ? void this.reject(new TypeError('You can not resolve a promise with itself'))
              : void (
                  'pending' === this._status &&
                  ((this._status = 'accepted'), (this._value = t), this._unwrap(this._value))
                );
          },
          _unwrap: function(t) {
            var e,
              r = this,
              n = !1;
            if (!t || ('object' != typeof t && 'function' != typeof t)) return void r.fulfill(t);
            try {
              (e = t.then),
                'function' == typeof e
                  ? e.call(
                      t,
                      function(t) {
                        n || ((n = !0), r._unwrap(t));
                      },
                      function(t) {
                        n || ((n = !0), r.reject(t));
                      },
                    )
                  : r.fulfill(t);
            } catch (t) {
              n || r.reject(t);
            }
          },
          _addCallbacks: function(t, e) {
            var r = this._callbacks,
              n = this._errbacks;
            switch ((r && r.push(t), n && n.push(e), this._status)) {
              case 'accepted':
                this._unwrap(this._value);
                break;
              case 'fulfilled':
                this.fulfill(this._result);
                break;
              case 'rejected':
                this.reject(this._result);
            }
          },
          _notify: function(t, e) {
            t.length &&
              nextTick(function() {
                var r, n;
                for (r = 0, n = t.length; r < n; ++r) t[r](e);
              });
          },
        }),
        (n.prototype.then = function(t, e) {
          var n = this._resolver,
            i = new this.constructor(function(s, o) {
              n._addCallbacks(
                'function' == typeof t ? r(i, s, o, t) : s,
                'function' == typeof e ? r(i, s, o, e) : o,
              );
            });
          return i;
        }),
        n
      );
    })()),
    (Promise.prototype.catch = function(t) {
      return this.then(void 0, t);
    }),
    e(Promise, {
      resolve: function(t) {
        return t && t.constructor === this
          ? t
          : new this(function(e) {
              e(t);
            });
      },
      reject: function(t) {
        return new this(function(e, r) {
          r(t);
        });
      },
      all: function(e) {
        if (!t(e)) throw new TypeError('Promise.all expects an array of values or promises');
        var r = this;
        return new r(function(t, n) {
          function i(e) {
            return function(r) {
              (u[e] = r), s--, s || t(u);
            };
          }
          var s = e.length,
            o = 0,
            c = e.length,
            u = [];
          if (c < 1) return t(u);
          for (; o < c; o++) r.resolve(e[o]).then(i(o), n);
        });
      },
      race: function(e) {
        if (!t(e)) throw new TypeError('Promise.race expects an array of values or promises');
        var r,
          n,
          i = this,
          s = e.length;
        for (r = 0; r < s; r++)
          if (((n = e[r]), !n || !n.then || 'function' != typeof n.then)) return i.resolve(n);
        return new i(function(t, n) {
          for (r = 0; r < s; r++) i.resolve(e[r]).then(t, n);
        });
      },
    }),
    (r.Promise = Promise);
})();
!(function() {
  'use strict';
  function t(t, e) {
    for (var r in e) e.hasOwnProperty(r) && (t[r] = e[r]);
  }
  t(Promise.prototype, {
    catchIf: function(t, e) {
      return this.catch(function(r) {
        if (t(r)) return e(r);
        throw r;
      });
    },
    catchClass: function(t, e) {
      return this.catch(function(r) {
        if (r instanceof t) return e(r);
        throw r;
      });
    },
    finally: function(t) {
      var e = this.constructor;
      return this.then(
        function(r) {
          return e.resolve(t()).then(function() {
            return r;
          });
        },
        function(r) {
          return e.resolve(t()).then(function() {
            throw r;
          });
        },
      );
    },
  }),
    t(Promise, {
      defer: function() {
        var t = {};
        return (
          (t.promise = new this(function(e, r) {
            (t.resolve = e), (t.reject = r);
          })),
          t
        );
      },
      delay: function(t) {
        return new this(function(e) {
          setTimeout(e, t);
        });
      },
      throttle: function(t, e, r) {
        function n() {
          !o ||
            f ||
            u ||
            ((f = s.resolve(t.apply(i, o))),
            (i = null),
            (o = null),
            f.then(c.resolve, c.reject).finally(function() {
              (f = null), n();
            }),
            (c = s.defer()),
            (u = setTimeout(function() {
              (u = null), n();
            }, e)));
        }
        if (r) throw new Error('TODO: Add support for throttle sharding');
        var i,
          o,
          u,
          f,
          s = this,
          c = s.defer();
        return function() {
          return (
            (i = this), o || f || u ? (o = arguments) : ((o = arguments), nextTick(n)), c.promise
          );
        };
      },
      limit: function(t, e, r) {
        function n() {
          var n = r ? r.apply(this, arguments) : '_',
            u = o[n] || (o[n] = { last: 0 }),
            f = Date.now();
          return (
            !u.busy &&
              f > u.last + e &&
              ((u.busy = !0),
              (u.last = f),
              (u.promise = i.resolve(t.apply(this, arguments)).finally(function() {
                u.busy = !1;
              }))),
            u.promise
          );
        }
        var i = this,
          o = {};
        return (n.getShard = r), n;
      },
      debounce: function(t, e, r) {
        function n() {
          var n = this,
            u = arguments,
            f = r ? r.apply(n, u) : '_',
            s = o[f] || (o[f] = {});
          return (
            s.deferred || (s.deferred = i.defer()),
            s.timeout && clearTimeout(s.timeout),
            (s.timeout = setTimeout(function() {
              (s.timeout = 0),
                i
                  .resolve(t.apply(n, u))
                  .then(s.deferred.resolve, s.deferred.reject)
                  .finally(function() {
                    s.deferred = 0;
                  });
            }, e)),
            s.deferred.promise
          );
        }
        var i = this,
          o = {};
        return (n.getShard = r), n;
      },
    });
})();
!(function() {
  'use strict';
  function e(e, r) {
    for (var t in r) r.hasOwnProperty(t) && (e[t] = r[t]);
    return e;
  }
  function r(r, n, o, i) {
    return e(n, r), e(n, o), (i = e(t(r.prototype), i)), (n.prototype = i), (i.constructor = n), n;
  }
  var t =
      Object.create ||
      function(e) {
        function r() {}
        return (r.prototype = e), new r();
      },
    n = r(
      Error,
      function(e) {
        (this.message = e || 'Cancelled'),
          Error.captureStackTrace
            ? Error.captureStackTrace(this, this.constructor)
            : (this.stack = new Error().stack);
      },
      null,
      { name: 'CancellationError' },
    ),
    o = r(
      n,
      function(e) {
        n.call(this, e || 'Timed out');
      },
      null,
      { name: 'TimeoutError' },
    ),
    i = r(
      Promise,
      function(e) {
        if (!(this instanceof i))
          throw new TypeError('Failed to construct "Cancellable": Please use the "new" operator.');
        if ('function' != typeof e)
          throw new TypeError('Cancellable constructor takes a function argument');
        var r, t;
        (this._promise = new Promise(function(e, n) {
          (r = e), (t = n);
        })),
          (this._parentPromises = []),
          (this._reject = t);
        try {
          e.call(this, r, t);
        } catch (e) {
          t(e);
        }
      },
      {
        resolve: Promise.resolve,
        reject: Promise.reject,
        defer: Promise.defer,
        all: function(e) {
          var r,
            t,
            n = Promise.all.call(this, e),
            o = n._parentPromises,
            s = e.length;
          for (r = 0; r < s; r++) (t = e[r]), t instanceof i && o.push(t);
          return n;
        },
        race: function(e) {
          var r,
            t,
            n = Promise.race.call(this, e),
            o = n._parentPromises,
            s = e.length;
          for (r = 0; r < s; r++) (t = e[r]), t instanceof i && o.push(t);
          return n;
        },
      },
      {
        then: function(e, r) {
          var t =
              'function' != typeof e
                ? e
                : function(r) {
                    var t = e(r);
                    if (t === s) throw new TypeError('You can not resolve a promise with itself');
                    return t instanceof i && c.push(t), t;
                  },
            n =
              'function' != typeof r
                ? r
                : function(e) {
                    var t = r(e);
                    if (t === s) throw new TypeError('You can not reject a promise with itself');
                    return t instanceof i && c.push(t), t;
                  },
            o = this._promise,
            s = new i(function(e) {
              e(o.then(t, n));
            }),
            c = s._parentPromises;
          return c.push(this), s;
        },
        cancel: function(e) {
          e = e || new n();
          var r = this._parentPromises,
            t = r.length;
          if (0 === t) this._reject(e);
          else for (var o = 0; o < t; o++) r[o].cancel(e);
        },
        setTimeout: function(e, r) {
          return (
            (r = r || new o('Timed out after ' + e + 'ms')),
            setTimeout(
              function() {
                this.cancel(r);
              }.bind(this),
              e,
            )
          );
        },
        toPromise: function() {
          return this._promise;
        },
      },
    );
  e(Promise, { Cancellable: i, CancellationError: n, TimeoutError: o });
})();
!(function() {
  'use strict';
  var n = new Function('return this')(),
    r = (n.playtotv = {}),
    t = [].slice,
    e = [].forEach,
    o = 1;
  (r.extend = function(n) {
    var r,
      t,
      e,
      o = arguments.length;
    for (e = 1; e < o; e++) {
      r = arguments[e];
      for (t in r) r.hasOwnProperty(t) && (n[t] = r[t]);
    }
    return n;
  }),
    r.extend(r, {
      toArray: function(n) {
        var r;
        if (n.toArray) r = n.toArray();
        else
          try {
            r = t.call(n, 0);
          } catch (t) {
            r = [];
            var e,
              o = n.length;
            for (e = 0; e < o; e++) r.push(n[e]);
          }
        return r;
      },
      getUID: function(n) {
        return (o += 1), n ? n + o : o;
      },
      each: function(n, r, t) {
        if (n)
          if (n.forEach) n.forEach(r, t);
          else if (n.length === +n.length) e.call(n, r, t);
          else {
            var o;
            for (o in n) n.hasOwnProperty(o) && r.call(t, n[o], o, n);
          }
      },
      find: function(n, r, t) {
        var e;
        if (n instanceof Array) {
          var o,
            i = n.length;
          for (o = 0; o < i; o++) if (((e = n[o]), r.call(t, e, o, n))) return e;
        } else {
          var c;
          for (c in n) if (n.hasOwnProperty(c) && ((e = n[c]), r.call(t, e, c, n))) return e;
        }
      },
      pluck: function(n, r) {
        return n.map(function(n) {
          return n[r];
        });
      },
      union: function() {
        var n = [];
        return (
          this.toArray(arguments).forEach(function(r) {
            r.forEach(function(r) {
              n.indexOf(r) < 0 && n.push(r);
            });
          }),
          n
        );
      },
      pick: function(n, r) {
        var t = {};
        return (
          r.forEach(function(r) {
            r in n && (t[r] = n[r]);
          }),
          t
        );
      },
      omit: function(n, r) {
        var t = {};
        for (var e in n) r.indexOf(e) === -1 && (t[e] = n[e]);
        return t;
      },
      shuffle: function(n) {
        for (var r, t, e = n.length, o = n.slice(0); e > 0; )
          (t = Math.floor(Math.random() * e--)), (r = o[e]), (o[e] = o[t]), (o[t] = r);
        return o;
      },
      throttle: function(n, r) {
        function t() {
          o &&
            !i &&
            (n.apply(e, o),
            (e = null),
            (o = null),
            (i = setTimeout(function() {
              (i = null), t();
            }, r)));
        }
        var e, o, i;
        return function() {
          (e = this), o || i ? (o = arguments) : ((o = arguments), nextTick(t));
        };
      },
      debounce: function(n, r) {
        var t;
        return function() {
          var e = this,
            o = arguments;
          clearTimeout(t),
            (t = setTimeout(function() {
              (t = null), n.apply(e, o), (e = null), (o = null);
            }, r));
        };
      },
      unstack: function(n) {
        function r() {
          n.apply(t, e), (t = null), (e = null);
        }
        var t, e;
        return function() {
          (t = this), e ? (e = arguments) : ((e = arguments), nextTick(r));
        };
      },
      once: function(n) {
        var r,
          t = !1;
        return function() {
          return t ? r : ((t = !0), (r = n.apply(this, arguments)));
        };
      },
      defer: function(n, r) {
        return function() {
          nextTick(n, r || this, arguments);
        };
      },
      AbstractFunction: function() {
        throw new ReferenceError('Abstract function not implemented.');
      },
      REQUIRED: '__required__',
      params: function(n, t) {
        return (
          (n = n || {}),
          r.each(t, function(t, e) {
            var o = n.hasOwnProperty(e);
            if (!o) {
              if (t === r.REQUIRED) throw new Error('Missing required parameter: ' + e);
              n[e] = t;
            }
          }),
          r.each(n, function(n, r) {
            if (!t.hasOwnProperty(r)) throw new Error('Invalid parameter: ' + r + ' = ' + n);
          }),
          n
        );
      },
      spliceParams: function(n, t) {
        n = n || {};
        var e = Object.keys(t),
          o = r.pick(n, e);
        return (
          e.forEach(function(r) {
            delete n[r];
          }),
          r.params(o, t)
        );
      },
      loadScript: function(n, r) {
        var t = Promise.defer(),
          e = document.createElement('script');
        (e.type = 'text/javascript'), (e.async = r !== !1), (e.src = n);
        var o = !1;
        return (
          (e.onload = e.onreadystatechange = function() {
            var n = this.readyState;
            o || (void 0 !== n && 'complete' !== n && 'loaded' !== n) || ((o = !0), t.resolve(e));
          }),
          (e.onerror = function() {
            t.reject(new Error('Error loading script: ' + n));
          }),
          document.head.appendChild(e),
          t.promise
        );
      },
      dispose: function(n) {
        Object.keys(n).forEach(function(r) {
          delete n[r];
        }),
          (n.dispose = function() {}),
          (n.disposed = !0);
      },
    });
})();
!(function() {
  'use strict';
  var t =
    Object.create ||
    function(t) {
      function n() {}
      return (n.prototype = t), new n();
    };
  (playtotv.Class = function(t) {
    var n,
      e,
      o,
      r,
      i = {},
      a = [];
    for (r in t)
      if (t.hasOwnProperty(r))
        switch (((o = t[r]), r)) {
          case 'initialize':
            n = o;
            break;
          case 'extend':
            e = o;
            break;
          case 'callbacks':
            a = o;
            break;
          default:
            i[r] = o;
        }
    if (e && !(e instanceof Function))
      throw new TypeError("Function expected using 'extend' with playtotv.Class");
    return (
      n ||
        (n = e
          ? function() {
              this._super.apply(this, arguments);
            }
          : function() {}),
      (e || a.length > 0) && (n = this.wrap(n, e, a)),
      playtotv.extend(n.prototype, i),
      n
    );
  }),
    (playtotv.Class.prototype = {
      wrap: function(n, e, o) {
        var r = function() {
          return (
            o.forEach(function(t) {
              var n = this[t];
              if (!(n instanceof Function))
                throw new ReferenceError('Callback "' + t + '" is undefined or not a Function');
              this[t] = n.bind(this);
            }, this),
            (this._super = e || void 0),
            n.apply(this, arguments)
          );
        };
        return e && ((r.prototype = t(e.prototype)), (r.prototype.constructor = r)), r;
      },
    }),
    (playtotv.Class.getInstance = function(t) {
      var n = new playtotv.Class(t);
      return new n();
    });
})();
!(function() {
  'use strict';
  if ('undefined' != typeof window) {
    var e = playtotv,
      t = window.document,
      n =
        t.webkitMatchesSelector ||
        t.mozMatchesSelector ||
        t.msMatchesSelector ||
        t.oMatchesSelector ||
        t.matchesSelector ||
        function(e) {
          var t,
            n = this.parentNode,
            r = n ? n.querySelectorAll(e) : [],
            i = r.length;
          for (t = 0; t < i; t++) if (r[t] === this) return !0;
          return !1;
        },
      r = function(e, t) {
        return n.call(t, e);
      },
      i = function(n, r) {
        return (r = r || t), e.toArray(r.querySelectorAll(n));
      },
      a = window.Node;
    a &&
      a.prototype &&
      !a.prototype.contains &&
      (a.prototype.contains = function(e) {
        return !!(16 & this.compareDocumentPosition(e));
      }),
      t.head || (t.head = t.getElementsByTagName('head')[0]);
    var o =
        'abbr article aside audio bdi canvas data datalist details figcaption figure footer header hgroup mark meter nav output progress section summary time video',
      s = (function(e) {
        return (e.innerHTML = '<x-custom></x-custom>'), !!e.childNodes.length;
      })(t.createElement('span')),
      u = (function() {
        var e = t.createDocumentFragment();
        return (
          !s &&
            e.createElement &&
            o.replace(/\w+/g, function(t) {
              e.createElement(t);
            }),
          e
        );
      })(),
      c = 'data-em-uid',
      l = {};
    if (
      ((e.dom = {
        find: i,
        matches: r,
        first: function(e, t) {
          return this.find(e, t)[0];
        },
        last: function(e, t) {
          var n = this.find(e, t),
            r = Math.max(0, n.length - 1);
          return n[r];
        },
        traverse: function(e, t, n) {
          for (; t; ) {
            if (this.matches(e, t)) return t;
            t = t[n];
          }
          return null;
        },
        next: function(e, t) {
          return this.traverse(e, t.nextSibling, 'nextSibling');
        },
        previous: function(e, t) {
          return this.traverse(e, t.previousSibling, 'previousSibling');
        },
        closest: function(e, t) {
          return this.traverse(e, t, 'parentNode');
        },
        any: function(e, t) {
          var n = this.find(e, t);
          return t && this.matches(e, t) && n.splice(0, 0, t), n;
        },
        filter: function(e, t) {
          var n,
            r,
            i = [],
            a = t.length;
          for (n = 0; n < a; n++) (r = t[n]), this.matches(e, r) && i.push(r);
          return i;
        },
        isNode: function(e) {
          return e && e.nodeType && 1 === e.nodeType;
        },
        isChild: function(e, t) {
          return !!this.isNode(t) && t.contains(e);
        },
        manip: ['appendTo', 'insertBefore', 'insertAfter', 'prependTo', 'replace'],
        create: function(n, r, i) {
          var a = t.createElement(n);
          if (
            (r &&
              e.each(r, function(e, t) {
                a.setAttribute(t, e);
              }),
            i)
          ) {
            void 0 !== i.html && this.write(a, i.html),
              this.manip.forEach(function(t) {
                var n = i[t];
                void 0 !== n && e.dom[t](a, n);
              });
            var o = i.text;
            void 0 !== o &&
              (/style/i.test(n) && a.styleSheet
                ? (a.styleSheet.cssText = o)
                : a.appendChild(t.createTextNode(o)));
          }
          return a;
        },
        remove: function(e) {
          return e.parentNode.removeChild(e);
        },
        appendTo: function(e, t) {
          return t.appendChild(e);
        },
        insertBefore: function(e, t) {
          return t.parentNode.insertBefore(e, t);
        },
        insertAfter: function(e, t) {
          return t.nextSibling ? this.insertBefore(e, t.nextSibling) : this.appendTo(e, t);
        },
        prependTo: function(e, t) {
          return t.firstChild ? this.insertBefore(e, t.firstChild) : this.appendTo(e, t);
        },
        replace: function(e, t) {
          return t.parentNode.replaceChild(e, t);
        },
        empty: function(e) {
          var t,
            n,
            r = e.children,
            i = r.length - 1;
          for (t = i; t >= 0; t--) (n = r[t]), this.isNode(n) && this.remove(n);
        },
        write: function(e, t) {
          if ((this.empty(e), s)) e.innerHTML = t;
          else {
            var n = this.parseHTML(t);
            this.appendTo(n, e);
          }
        },
        parseHTML: function(e) {
          var n = t.createElement('div');
          s || u.appendChild(n), (n.innerHTML = e);
          var r,
            i = t.createDocumentFragment(),
            a = n.childNodes.length;
          for (r = 0; r < a; r++) i.appendChild(n.firstChild);
          return i;
        },
        getIndex: function(e, t) {
          t = t || e.parentNode.childNodes;
          var n,
            r,
            i = -1,
            a = t.length;
          for (n = 0; n < a; n++)
            if (((r = t[n]), 1 === r.nodeType && ((i += 1), r === e))) return i;
          return -1;
        },
        hasClass: function(e, t) {
          var n = new RegExp('(^|\\s)' + t + '(\\s|$)');
          return n.test(e.className);
        },
        addClass: function(e, t) {
          this.hasClass(e, t) || (e.className = e.className + ' ' + t);
        },
        removeClass: function(e, t) {
          var n = new RegExp('(^|\\s)' + t + '(\\s|$)', 'g'),
            r = e.className.replace(n, ' ');
          e.className = r.trim();
        },
        toggleClass: function(e, t, n) {
          return (
            (n = n || 'boolean' == typeof n ? n : !this.hasClass(e, t)),
            n ? this.addClass(e, t) : this.removeClass(e, t),
            n
          );
        },
        getData: function(e, t) {
          var n = e[c],
            r = n ? l[n] : null;
          return r ? r[t] : null;
        },
        setData: function(t, n, r) {
          var i = t[c];
          i || ((i = e.getUID()), (t[c] = i));
          var a = l[i];
          return a || (l[i] = a = {}), (a[n] = r), r;
        },
        removeData: function(e, t) {
          var n = e[c],
            r = l[n];
          r && delete r[t];
        },
        getClosestAttr: function(e, t) {
          return (e = this.closest('[' + t + ']', e)), e ? e.getAttribute(t) : void 0;
        },
        getDataSet: function(t) {
          var n = t.dataset;
          if (!n)
            return e.toArray(t.attributes).reduce(function(e, t) {
              var n = t.name;
              return 0 !== n.indexOf('data-')
                ? e
                : ((n = n.substr(5).replace(/-([a-z])/g, function(e, t) {
                    return t.toUpperCase();
                  })),
                  (e[n] = t.value),
                  e);
            }, {});
          if (!n.hasOwnProperty) {
            var r,
              i = {};
            for (r in n) i[r] = n[r];
            n = i;
          }
          return n;
        },
      }),
      /msie\s?8/i.test(navigator.userAgent))
    ) {
      var d = e.dom,
        f = function(t, n, r) {
          return function() {
            var i = [],
              a = arguments;
            if (
              ('number' == typeof r && e.event.dispatchEvent(a[r], 'DOMNodeRemoved', !0, !1),
              'number' == typeof n)
            ) {
              var o = a[n];
              i = 11 === o.nodeType ? e.toArray(o.childNodes) : [o];
            }
            var s = t.apply(d, a);
            return (
              i.forEach(function(t) {
                e.event.dispatchEvent(t, 'DOMNodeInserted', !0, !1);
              }),
              s
            );
          };
        };
      (d.appendTo = f(d.appendTo, 0)),
        (d.insertBefore = f(d.insertBefore, 0)),
        (d.replace = f(d.replace, 0, 1)),
        (d.remove = f(d.remove, !1, 0));
    }
  }
})();
!(function() {
  'use strict';
  if ('undefined' != typeof window) {
    var e = window.document,
      t = window.Event,
      n = window.MouseEvent,
      r = window.CustomEvent,
      o = (function() {
        try {
          var e = new r('test');
          return e.preventDefault(), !0;
        } catch (e) {
          return !1;
        }
      })();
    if (!o) {
      var i = t.prototype;
      (t = function(t, n) {
        n = playtotv.params(n, { bubbles: !1, cancelable: !1 });
        var r = e.createEvent('HTMLEvents');
        return r.initEvent(t, n.bubbles, n.cancelable), r;
      }),
        (t.prototype = i),
        (r = function(t, n) {
          n = playtotv.params(n, { bubbles: !1, cancelable: !1, detail: null });
          var r = e.createEvent('CustomEvent');
          return r.initCustomEvent(t, n.bubbles, n.cancelable, n.detail), r;
        }),
        (r.prototype = i),
        (n = function(t, n) {
          n = playtotv.params(n, {
            bubbles: !1,
            cancelable: !1,
            screenX: 0,
            screenY: 0,
            clientX: 0,
            clientY: 0,
            ctrlKey: !1,
            altKey: !1,
            shiftKey: !1,
            metaKey: !1,
            button: 0,
            buttons: 0,
            relatedTarget: null,
          });
          var r = e.createEvent('MouseEvent');
          return (
            r.initMouseEvent(
              t,
              n.bubbles,
              n.cancelable,
              window,
              1,
              n.screenX,
              n.screenY,
              n.clientX,
              n.clientY,
              n.ctrlKey,
              n.altKey,
              n.shiftKey,
              n.metaKey,
              n.button,
              n.relatedTarget,
            ),
            r
          );
        }),
        (n.prototype = i);
    }
    var a = new playtotv.Class({
      initialize: function(e, t, n) {
        (this.node = e), (this.type = t), (this.listener = n);
      },
      dispose: function() {
        delete this.node, delete this.type, delete this.listener;
      },
    });
    (playtotv.event = {
      addListener: function(e, t, n) {
        if (!e) throw new ReferenceError('Node is undefined at event.addListener for "' + t + '"');
        return e.addEventListener(t, n, !1), new a(e, t, n);
      },
      removeListener: function(e, t, n) {
        var r = e;
        if ((e instanceof a && ((n = e.listener), (t = e.type), (r = e.node), e.dispose()), !r))
          throw new ReferenceError('Node is undefined at event.removeListener for "' + t + '"');
        r.removeEventListener(t, n, !1);
      },
      dispatchEvent: function(e, t, n, o, i) {
        return e.dispatchEvent(new r(t, { bubbles: n, cancelable: o, detail: i }));
      },
      triggerNative: function(e, r, o) {
        o = playtotv.extend({ bubbles: !0, cancelable: !0 }, o);
        var i = /mouse|click/.test(r) ? new n(r, o) : new t(r, o);
        return e.dispatchEvent(i);
      },
      preventDefault: function(e) {
        return e.preventDefault(), !1;
      },
      stopPropagation: function(e) {
        e.stopPropagation();
      },
      addWindowOnError: function(e) {
        var t = window.onerror;
        window.onerror = function(n, r, o, i, a) {
          var s;
          return (
            t && (s = t.apply(this, arguments)),
            a ||
              ((a = new Error((n.match(/Error: (.*)/) || [])[1] || n)),
              (a.name = (n.match(/\w+Error/) || [])[0] || 'Error'),
              (a.stack = r + ':' + o + (void 0 === i ? '' : ':' + i))),
            e(a) || s
          );
        };
      },
    }),
      playtotv.extend(playtotv.event, {
        CustomEvent: r,
        Event: t,
        EventListener: a,
        MouseEvent: n,
      });
  }
})();
!(function() {
  'use strict';
  function t(t) {
    var e = '';
    playtotv.each(r, function(n, r) {
      e || 0 !== t.indexOf(r) || ((e = n), (t = t.replace(r, '')));
    });
    var n =
      t
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .toLowerCase()
        .replace(/\./g, '/') + '.js';
    return e + n;
  }
  function e(t, e) {
    var r,
      o,
      a,
      i = n,
      u = t.split('.'),
      l = u.length,
      c = l - 1;
    for (r = 0; r < l; r++)
      (o = u[r]),
        (a = i[o]),
        r < c ? (i = a || (i[o] = {})) : (i[o] = a ? playtotv.extend(e, a) : e);
  }
  var n = new Function('return this')(),
    r = (playtotv.pkgURL = {}),
    o = {},
    a = (playtotv.definePromises = {}),
    i = (playtotv.modules = {});
  (playtotv.getModule = function(e) {
    var n = t(e) + '?nocache=' + Date.now();
    return (
      a[e] ||
      o[e] ||
      (o[e] = playtotv.loadScript(n).then(function() {
        if (!a[e]) throw new Error(e + ' was not defined by ' + n);
        return a[e];
      }))
    );
  }),
    (playtotv.define = function(t, n, r) {
      if (3 !== arguments.length) throw new Error('Three arguments required');
      return (
        a[t] ||
        (a[t] = Promise.all(n.map(playtotv.getModule)).then(function(o) {
          var a = { id: t, dependencies: n },
            u = r instanceof Function ? r.apply(a, o) : r;
          return (i[t] = u), e(t, u), u;
        }))
      );
    }),
    (playtotv.require = function(t) {
      return i[t];
    }),
    (playtotv.getModuleId = function(t) {
      return playtotv.find(Object.keys(i), function(e) {
        return i[e] === t;
      });
    }),
    (playtotv.importModules = function(t) {
      var e,
        n,
        r,
        o = /[^.]+$/i,
        a = {},
        u = t.length;
      for (e = 0; e < u; e++) (n = t[e]), (r = o.exec(n)[0]), (a[r] = i[n]);
      return a;
    });
})();
!(function() {
  'use strict';
  function e(e) {
    return window.decodeURIComponent(e.replace(/\+/g, ' '));
  }
  var t = playtotv;
  t.util = {
    EMAIL_REGEXP: new RegExp(
      "^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$",
    ),
    URL_REGEXP: /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gi,
    ORIGIN_REGEXP: /(\w+:)?\/{2,}[^\/]+/,
    escapeHtml: function(e) {
      return t.dom.create('div', null, { text: e }).innerHTML;
    },
    escapeRegEx: function(e) {
      return e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
    },
    globToRegEx: function(e) {
      return new RegExp('^' + this.escapeRegEx(e).replace(/\\\*/g, '(.*)') + '$');
    },
    glob: function(e, t) {
      return this.globToRegEx(e).test(t);
    },
    addURLParam: function(e, t, n) {
      var r = e.indexOf('?') !== -1 ? '&' : '?';
      return e.replace(/((#.*)?$)/, r + encodeURIComponent(t) + '=' + encodeURIComponent(n) + '$1');
    },
    addURLParams: function(e, n) {
      return (
        t.each(
          n,
          function(t, n) {
            e = this.addURLParam(e, n, t);
          },
          this,
        ),
        e
      );
    },
    getURLParams: function(t) {
      t = t || window.location.href;
      for (
        var n, r = t.replace(/[^\?]*\??([^#]*)(#.*)?/, '$1'), a = {}, i = /([^&=]+)=?([^&]*)/g;
        (n = i.exec(r));

      )
        a[e(n[1])] = e(n[2]);
      return a;
    },
    getOrigin: function(e, t) {
      var n = window.location;
      (e = e || n.href), (t = t || n.href);
      var r = e.match(this.ORIGIN_REGEXP),
        a = r ? r[0] : t.match(this.ORIGIN_REGEXP)[0];
      return (
        0 === a.indexOf('//') && (a = n.protocol + a),
        0 === a.indexOf('file://') && (a = 'file://'),
        a
      );
    },
    popup: function(e, t, n) {
      (t = t || '_blank'), (n = n || {});
      var r = n.width;
      if (r) {
        var a = window.screen.width;
        (n.width = r = Math.min(r, a)), (n.left = n.left || Math.round(a / 2 - r / 2));
      }
      var i = n.height;
      if (i) {
        var o = window.screen.height;
        (n.height = i = Math.min(i, o)), (n.top = n.top || Math.round(o / 2 - i / 2));
      }
      var u = Object.keys(n)
        .map(function(e) {
          return e + '=' + n[e];
        })
        .join(',');
      return window.open(e, t, u);
    },
    pad: function(e, t, n) {
      for (e = String(e), t = parseInt(t, 10) || 2, n = n || '0'; e.length < t; ) e = n + e;
      return e;
    },
    timeStr: function(e, t) {
      return (
        (e = e || new Date()),
        this.pad(e.getHours()) +
          ':' +
          this.pad(e.getMinutes()) +
          ':' +
          this.pad(e.getSeconds()) +
          (t ? '.' + this.pad(e.getMilliseconds(), 3) : '')
      );
    },
    toCurrency: function(e, t) {
      var n,
        r = parseFloat(e)
          .toFixed(2)
          .split('.'),
        a = r[0].split(''),
        i = a.length - 3;
      for (n = i; n >= 1; n -= 3) a.splice(n, 0, '.');
      return a.join('') + ',' + (t === !1 ? '-' : r[1]);
    },
    dashCase: function(e) {
      return e.replace(/\W+/g, '-').replace(/\w+/g, function(e) {
        return e.toLowerCase();
      });
    },
    titleCase: function(e) {
      return e.replace(/[\W_]+/g, ' ').replace(/(\w)(\w*)/g, function(e, t, n) {
        return t.toUpperCase() + n.toLowerCase();
      });
    },
    getPathHead: function(e, t) {
      var n = e.indexOf(t || '.');
      return n < 0 ? e : e.substr(0, n);
    },
    getPathTail: function(e, t) {
      var n = e.indexOf(t || '.');
      return n < 0 ? '' : e.substr(n + 1);
    },
    seekPath: function(e, t, n) {
      if (e) {
        var r = this.getPathHead(t, n),
          a = e[r];
        return r === t ? { object: e, key: r, value: a } : this.seekPath(a, this.getPathTail(t, n));
      }
    },
    getTimeZone: function(e) {
      e = void 0 === e ? -1 * (new Date().getTimezoneOffset() / 60) : e;
      var t = e < 0 ? '-' : '+';
      return (e = Math.abs(e)), 'UTC' + t + this.pad(Math.floor(e)) + ':' + this.pad((60 * e) % 60);
    },
  };
})();
!(function() {
  'use strict';
  var n = playtotv,
    e = n.util,
    t = {},
    o = { SPAM: 4, DEBUG: 3, INFO: 2, WARNING: 1, ERROR: 0, NONE: -1 },
    i = { SPAM: 'log', DEBUG: 'debug', INFO: 'info', WARNING: 'warn', ERROR: 'error' },
    l = 'undefined' != typeof window ? e.getURLParams(window.location.href) : {};
  l.fbl && n.loadScript('//getfirebug.com/firebug-lite.js#startOpened', !1);
  var r = l.weinre;
  void 0 !== r &&
    n.loadScript(
      'https://weinre.exmachina.nl/target/target-script-min.js#' + (r || 'anonymous'),
      !1,
    );
  var s = new n.Class({
    initialize: function(e, t) {
      (this.name = e || n.getUID('logger-')), (this.level = t);
    },
    isEnabled: function(n) {
      var e = this.level || s.level;
      return o[e] >= o[n];
    },
    log: function(t, o) {
      if (console && this.isEnabled(t)) {
        (o = n.toArray(o)),
          (o = o.map(function(n) {
            if (n instanceof Promise) {
              var e = ['[promise]'];
              return (
                n.then(
                  function(n) {
                    e.push('[resolved]', n), this.log(t, o);
                  }.bind(this),
                  function(n) {
                    e.push('[rejected]', n), this.log(t, o);
                  }.bind(this),
                ),
                e
              );
            }
            return n;
          }, this));
        var l = e.timeStr(new Date(), !0) + ' [' + t + '] ' + this.name + ' |',
          r = Array.prototype.concat.apply([l], o),
          a = console[i[t]] || console.log;
        'function' == typeof a.apply
          ? a.apply(console, s.join ? [r.join(' ')] : r)
          : a(r.join(' '));
      }
    },
    trace: function(n) {
      console && console.trace && (!n || this.isEnabled(n)) && console.trace();
    },
    s: function() {
      this.log('SPAM', arguments);
    },
    d: function() {
      this.log('DEBUG', arguments);
    },
    i: function() {
      this.log('INFO', arguments);
    },
    w: function() {
      this.log('WARNING', arguments);
    },
    e: function() {
      this.log('ERROR', arguments);
    },
  });
  n.extend(s, {
    level: l.logLevel || 'INFO',
    join: !!l.logJoin,
    get: function(n, e) {
      var o = n && t[n];
      return o ? (e && (o.level = e), o) : ((o = new s(n, e)), (t[n] = o), o);
    },
  }),
    (n.Logger = s);
})();

playtotv.define('playtotv.pubsub.Subscription', [], function() {
  'use strict';
  var t = playtotv,
    e = t.util;
  return new t.Class({
    initialize: function(t, n) {
      if (!('string' == typeof t || t instanceof RegExp))
        throw new TypeError('Invalid selector (not a string or RegExp)');
      if (!(n instanceof Function)) throw new TypeError('Invalid handler (not a function)');
      'string' == typeof t &&
        t.indexOf('*') >= 0 &&
        (t = new RegExp('^' + e.escapeRegEx(t).replace(/\\\*/g, '.*?') + '$')),
        (this.selector = t),
        (this.handler = n);
    },
    match: function(t) {
      var e = this.selector;
      return 'string' == typeof e ? e === t : e.test(t);
    },
    deliver: function(t, e) {
      this.handler && this.handler(t, e);
    },
    dispose: function() {
      t.dispose(this);
    },
  });
});
playtotv.define('playtotv.model.ModelSubscription', ['playtotv.pubsub.Subscription'], function(e) {
  'use strict';
  var t = playtotv.util;
  return new playtotv.Class({
    extend: e,
    selectorRegex: /^([\w\*]+:[\w\*\.]+(=.*)?)|\*$/,
    initialize: function(e, i) {
      if (!this.selectorRegex.test(e)) throw new Error('Invalid selector string: ' + e);
      if ('*' === e) e = /.*/;
      else {
        var n = e.indexOf('=') === -1;
        n && (e += '='),
          (n || e.indexOf('*') !== -1) &&
            ((e = t.escapeRegEx(e)),
            (e = e.replace(/\\\*/g, '[^:\\.=]+')),
            (e = new RegExp('^' + e)));
      }
      this._super(e, i);
    },
    deliver: function(e) {
      this.handler && this.handler(e.value, e.action, e.key);
    },
  });
});
playtotv.define('playtotv.pubsub.PubSub', ['playtotv.pubsub.Subscription'], function(s) {
  'use strict';
  function i(s, i) {
    var n = s.indexOf(i);
    return n !== -1 && (s.splice(n, 1), !0);
  }
  var n = playtotv;
  return new n.Class({
    initialize: function() {
      (this.subscriptions = []), (this.cancellables = []);
    },
    subscribe: function(i, n) {
      var t = new s(i, n);
      return this.subscriptions.push(t), t;
    },
    unsubscribe: function(s) {
      var n = this.subscriptions;
      return s.dispose(), !!n && i(n, s);
    },
    subscribeAll: function(s) {
      var i,
        n = s.length,
        t = [];
      for (i = 0; i < n; i++) t.push(this.subscribe.apply(this, s[i]));
      return t;
    },
    unsubscribeAll: function(s) {
      s = s || this.subscriptions;
      var i,
        n = s.length;
      for (i = 0; i < n; i++) this.unsubscribe(s[i]);
    },
    when: function(s) {
      var n = this.cancellables,
        t = this;
      return new Promise.Cancellable(function(u) {
        var e = this;
        n.push(e);
        var r = t.subscribe(s, function(s) {
          u(s);
        });
        e.finally(function() {
          t.unsubscribe(r), i(n, e);
        });
      });
    },
    publish: function(s, i, n) {
      var t,
        u,
        e,
        r = this.subscriptions,
        c = r.length;
      for (t = 0; t < c; t++)
        (u = r[t]), (e = u.match(s)), e && (n ? u.deliver(i, s) : nextTick(u.deliver, u, [i, s]));
    },
    dispose: function() {
      this.subscriptions.forEach(function(s) {
        s.dispose();
      }),
        this.cancellables.forEach(function(s) {
          s.cancel(new Promise.CancellationError('Disposed'));
        }),
        n.dispose(this);
    },
  });
});
playtotv.define(
  'playtotv.model.AbstractModel',
  ['playtotv.model.ModelSubscription', 'playtotv.pubsub.PubSub'],
  function(t, e) {
    'use strict';
    return new playtotv.Class({
      extend: e,
      initialize: function() {
        this._super();
      },
      toObject: playtotv.AbstractFunction,
      updateModel: playtotv.AbstractFunction,
      isRelatedModel: playtotv.AbstractFunction,
      getSelectorAction: function(t) {
        var e = t.indexOf(':');
        return e === -1 ? void 0 : t.substr(0, e);
      },
      getSelectorKey: function(t) {
        var e = t.indexOf(':');
        if (e !== -1) {
          var i = t.substring(e + 1),
            n = i.indexOf('=');
          return n === -1 ? i : i.substring(0, n);
        }
      },
      getSelectorValue: function(t) {
        var e = t.indexOf('=');
        return e === -1 ? void 0 : t.substring(e + 1);
      },
      getSelectorMatches: function(t) {
        var e = this.getSelectorAction(t) || '*',
          i = this.getSelectorKey(t) || '*',
          n = this.getSelectorValue(t);
        if ('remove' === e)
          throw new Error('Matching with remove action selector is not supported: ' + t);
        if (!i || i.indexOf('.') !== -1)
          throw new Error('Only basic key matching is supported: ' + t);
        var o = [];
        return (
          playtotv.each(this.toObject(), function(t, e) {
            ('*' !== i && e !== i) ||
              (void 0 !== n && String(t) !== n) ||
              o.push({ key: e, value: t });
          }),
          o
        );
      },
      subscribe: function(e, i, n) {
        var o = new t(e, i);
        return (
          n &&
            this.getSelectorMatches(e).forEach(function(t) {
              var e = t.key,
                i = t.value,
                n = 'init:' + e + '=' + i,
                r = { key: e, action: 'init', value: i };
              nextTick(o.deliver, o, [r, n]);
            }),
          this.subscriptions.push(o),
          o
        );
      },
      when: function(t) {
        if (void 0 !== this.getSelectorValue(t)) {
          var i = this.getSelectorMatches(t);
          if (i.length > 0) return Promise.Cancellable.resolve(i[0].value);
        }
        return e.prototype.when.call(this, t);
      },
      publish: function(t, i, n) {
        var o = i + ':' + t + '=' + n,
          r = { key: t, action: i, value: n };
        e.prototype.publish.call(this, o, r);
      },
    });
  },
);
playtotv.define('playtotv.model.ObjectModel', ['playtotv.model.AbstractModel'], function(i) {
  'use strict';
  return new playtotv.Class({
    extend: i,
    initialize: function(i) {
      this._super(),
        (this.propertyKeys = []),
        (this.childSubscriptionMap = {}),
        i && this.copyProperties(i);
    },
    toObject: function() {
      var i,
        t,
        e = {},
        r = this.propertyKeys,
        s = r.length;
      for (i = 0; i < s; i++) (t = r[i]), (e[t] = this[t]);
      return e;
    },
    updateModel: function(i) {
      var t = this.propertyKeys,
        e = Object.keys(i),
        r = [];
      return (
        t
          .filter(function(i) {
            return e.indexOf(i) === -1;
          })
          .forEach(
            function(i) {
              r.push(this.removeProperty(i));
            }.bind(this),
          ),
        e
          .filter(function(i) {
            return t.indexOf(i) === -1;
          })
          .forEach(
            function(t) {
              this.addProperty(t, i[t]);
            }.bind(this),
          ),
        e
          .filter(function(i) {
            return t.indexOf(i) !== -1;
          })
          .forEach(
            function(t) {
              r.push(this.changeProperty(t, i[t]));
            }.bind(this),
          ),
        r
      );
    },
    isRelatedModel: function(t) {
      if (this === t) return !0;
      var e,
        r,
        s = this.childSubscriptionMap,
        n = Object.keys(s),
        o = n.length;
      for (e = 0; e < o; e++)
        if (((r = s[n[e]].model), r instanceof i && r.isRelatedModel(t))) return !0;
      return !1;
    },
    subscribeChild: function(i, t) {
      var e = t.subscribe(
        '*',
        function(t, e, r) {
          this.publish(i + '.' + r, e, t);
        }.bind(this),
      );
      this.childSubscriptionMap[i] = { model: t, subscription: e };
    },
    unsubscribeChild: function(i) {
      var t = this.childSubscriptionMap,
        e = t[i];
      e.model.unsubscribe(e.subscription), delete t[i];
    },
    addProperty: function(t, e) {
      var r = this.propertyKeys,
        s = e instanceof i;
      if (r.indexOf(t) !== -1) throw new Error('Property with specified key already exists: ' + t);
      if (t in this) throw new Error('Specified key is reserved: ' + t);
      if (s && e.isRelatedModel(this))
        throw new Error('Value is a model in a cyclic relationship with this model');
      r.push(t), (this[t] = e), s && this.subscribeChild(t, e), this.publish(t, 'add', e);
    },
    changeProperty: function(t, e, r) {
      r = r || {};
      var s = this[t],
        n = e instanceof i;
      if (this.propertyKeys.indexOf(t) === -1)
        throw new Error('Property with specified key does not exist: ' + t);
      if (!r.force) {
        if (e === s || (r.json && JSON.stringify(e) === JSON.stringify(s))) return;
        if (n && e.isRelatedModel(this))
          throw new Error('Value is a model in a cyclic relationship with this model');
      }
      return (
        s instanceof i && this.unsubscribeChild(t),
        (this[t] = e),
        n && this.subscribeChild(t, e),
        this.publish(t, 'change', e),
        s
      );
    },
    removeProperty: function(t) {
      var e = this.propertyKeys,
        r = e.indexOf(t),
        s = this[t];
      if (r === -1) throw new Error('Property with specified key does not exist: ' + t);
      return (
        s instanceof i && this.unsubscribeChild(t),
        e.splice(r, 1),
        delete this[t],
        this.publish(t, 'remove', s),
        s
      );
    },
    setProperty: function(i, t, e) {
      return this.propertyKeys.indexOf(i) !== -1
        ? this.changeProperty(i, t, e)
        : void this.addProperty(i, t);
    },
    copyProperties: function(i) {
      var t = [];
      for (var e in i) i.hasOwnProperty(e) && t.push(this.setProperty(e, i[e]));
      return t;
    },
    dispose: function() {
      playtotv.each(this.childSubscriptionMap, function(i) {
        i.model.unsubscribe(i.subscription);
      }),
        i.prototype.dispose.call(this);
    },
  });
});
playtotv.define('playtotv.net.xhrProxy', [], function() {
  'use strict';
  function e(t) {
    (l = 'object' == typeof t.data), d.removeListener(window, 'message', e);
  }
  function t(e) {
    var t = s.create('iframe', { src: e });
    return (
      playtotv.extend(t.style, {
        width: 0,
        height: 0,
        visibility: 'hidden',
        overflow: 'hidden',
        position: 'absolute',
        display: 'none',
      }),
      t
    );
  }
  function n(e) {
    var n = p[e];
    if (n) return n.promise;
    i.d('getWindow(): ' + e);
    var a = (p[e] = Promise.defer()),
      r = !1;
    a.promise.then(function() {
      r = !0;
    });
    var o = e + '/xhr-proxy.html',
      u = t(o);
    return (
      d.addListener(u, 'load', function() {
        setTimeout(function() {
          r || ((p[e] = null), s.remove(u), a.reject(new Error('Failed to initialize: ' + o)));
        }, 1e3);
      }),
      s.appendTo(u, document.body),
      a.promise
    );
  }
  function a(e, t, n) {
    var a = c[n];
    if (a) {
      var r = a._batch;
      if (0 !== r.length) {
        r.indexOf(h) >= 0 && (delete c[n], r.length > 1 && (r = [h]));
        var o = { id: n, rpcs: r };
        t.postMessage(l ? o : JSON.stringify(o), e), (a._batch = []);
      }
    }
  }
  function r(e, t, n) {
    var a = c[e];
    if (!a) throw new Error('xhr undefined: ' + e);
    a._batch.push('abort' === t ? h : { method: t, args: [].slice.call(n, 0) }), a._execBatch();
  }
  function o(e, t) {
    var n = c[e],
      a = t ? n.upload : n;
    (a._eventListenersMap = {}),
      (a.addEventListener = function(n, o) {
        var i = a._eventListenersMap[n];
        i || ((i = a._eventListenersMap[n] = []), r(e, 'addEventListener', [t, n])), i.push(o);
      });
  }
  if ('undefined' != typeof window) {
    var i = playtotv.Logger.get(this.id),
      s = playtotv.dom,
      d = playtotv.event,
      u = ['open', 'setRequestHeader', 'send', 'abort'],
      p = {},
      c = {},
      f = 0,
      l = !1,
      h = { method: 'abort', args: [] };
    return (
      d.addListener(window, 'message', e),
      window.postMessage({}, '*'),
      d.addListener(window, 'message', function(e) {
        var t = p[e.origin];
        if (t) {
          var n = 'string' == typeof e.data ? JSON.parse(e.data) : e.data,
            a = n.type,
            r = c[n.id],
            o = 'onMessage: ' + e.origin + ': ' + a;
          if (((e.stopImmediatePropagation || e.stopPropagation).call(e), 'initialized' === a))
            i.s(o), t.resolve({ win: e.source });
          else if (r)
            if ('event' === a) {
              var s = n.upload,
                d = n.event,
                u = d.type;
              i.s(o + ': ' + (s ? 'upload: ' : '') + u + ': ' + d.loaded);
              var f = s ? r.upload : r,
                l = f._eventListenersMap[u];
              l.forEach(function(e) {
                e(d);
              });
            } else
              'readyStateChange' === a
                ? ((r.status = n.status),
                  (r.responseText = n.responseText),
                  r._readyStateChange(n.readyState))
                : i.w(o + ': unsupported type');
          else i.d(o + ': xhr: ' + n.id + ' is undefined');
        }
      }),
      {
        create: function(e) {
          var t = f++,
            i = (c[t] = {
              _batch: [],
              _readyStateChange: function(e) {
                (i.readyState = e),
                  i.onreadystatechange && i.onreadystatechange(),
                  4 === e && delete c[t];
              },
              _execBatch: playtotv.unstack(function() {
                n(e).then(
                  function(n) {
                    a(e, n.win, t);
                  },
                  function(e) {
                    (i.status = 'no-xhr-proxy'), (i.reason = e), i._readyStateChange(4);
                  },
                );
              }),
              upload: {},
              onreadystatechange: null,
              readyState: 0,
              status: 0,
              responseText: '',
            });
          return (
            u.forEach(function(e) {
              i[e] = function() {
                r(t, e, arguments);
              };
            }),
            o(t, !1),
            o(t, !0),
            i
          );
        },
        debug: { deferredWindows: p, xhrs: c },
      }
    );
  }
});
playtotv.define('playtotv.net.request', ['playtotv.net.xhrProxy'], function(e) {
  'use strict';
  var t = playtotv,
    a = t.Logger.get(this.id),
    r = t.util,
    n = function(o, s) {
      function i(e) {
        if (!c) {
          m && clearTimeout(m);
          var a = l.responseText;
          if ('json' === s.dataType)
            try {
              a = a ? JSON.parse(a) : {};
            } catch (e) {}
          if ((e >= 200 && e < 300) || [304, 1223].indexOf(e) !== -1) p.resolve(a);
          else {
            var r = new Error('Request ' + s.label + ' failed with status ' + e);
            p.reject(t.extend(r, { label: s.label, status: e, xhr: l, data: a }));
          }
          c = !0;
        }
      }
      var u = r.getOrigin(),
        d = r.getOrigin(o);
      s = t.params(s, {
        label: ((s && s.type) || 'GET') + ' ' + o,
        type: 'GET',
        urlParams: {},
        headers: {},
        username: void 0,
        password: void 0,
        data: {},
        onProgressDown: void 0,
        onProgressUp: void 0,
        dataType: 'text',
        xhrProxy: d !== u,
        cache: !0,
        timeout: 0,
      });
      var l,
        p = Promise.defer(),
        c = !1,
        m = null;
      a.s(s.label), (l = s.xhrProxy ? e.create(d) : new XMLHttpRequest());
      var v = 'onload' in l,
        f = 'onerror' in l,
        y = 'onabort' in l,
        g = 'ontimeout' in l;
      (o = r.addURLParams(o, s.urlParams)),
        s.cache || (o = r.addURLParam(o, 'nocache', Date.now())),
        l.open(s.type, o, !0, s.username, s.password),
        v
          ? l.addEventListener(
              'load',
              function() {
                i(l.status);
              },
              !1,
            )
          : (l.onreadystatechange = function() {
              4 === l.readyState && i(l.status);
            }),
        f &&
          l.addEventListener(
            'error',
            function() {
              i(l.status);
            },
            !1,
          ),
        y &&
          l.addEventListener(
            'abort',
            function() {
              i('abort');
            },
            !1,
          ),
        s.timeout &&
          (g
            ? (l.addEventListener(
                'timeout',
                function() {
                  i('timeout');
                },
                !1,
              ),
              (l.timeout = s.timeout))
            : (m = setTimeout(function() {
                i('timeout'), l.abort();
              }, s.timeout)));
      var h = s.onProgressDown;
      h &&
        l.addEventListener(
          'progress',
          function(e) {
            e.lengthComputable && h(e);
          },
          !1,
        );
      var P = s.onProgressUp;
      return (
        P &&
          l.upload.addEventListener(
            'progress',
            function(e) {
              e.lengthComputable && P(e);
            },
            !1,
          ),
        t.each(s.headers, function(e, t) {
          l.setRequestHeader(t, e);
        }),
        'PUT' === s.type || 'POST' === s.type
          ? (l.setRequestHeader('Content-type', 'application/json'), l.send(JSON.stringify(s.data)))
          : l.send(),
        n.taps.forEach(function(e) {
          e(o, s.label, p.promise);
        }),
        p.promise
      );
    };
  return (n.taps = []), n;
});
playtotv.define('playtotv.server.gatekeeperAPI', [], function() {
  'use strict';
  return { getInit: { label: 'gatekeeper.getInit', url: '1/gatekeeper/init' } };
});
playtotv.define('playtotv.storage.MockStorage', [], function() {
  'use strict';
  return new playtotv.Class({
    initialize: function() {
      (this.length = 0), (this._container = {});
    },
    key: function(t) {
      return Object.keys(this._container)[t];
    },
    getItem: function(t) {
      return Object.keys(this._container).indexOf(t) === -1 ? null : this._container[t];
    },
    setItem: function(t, e) {
      (this._container[t] = e), this._updateLength();
    },
    removeItem: function(t) {
      delete this._container[t], this._updateLength();
    },
    clear: function() {
      (this.length = 0), (this._container = {});
    },
    _updateLength: function() {
      this.length = Object.keys(this._container).length;
    },
  });
});
playtotv.define('playtotv.storage.Storage', ['playtotv.storage.MockStorage'], function(t) {
  'use strict';
  var e = playtotv.Logger.get(this.id),
    i = new playtotv.Class({
      initialize: function(e, i) {
        (this.storage = e || window.localStorage || new t()), (this.prefix = i || '');
      },
      get: function(t, e) {
        var i = this.storage.getItem(this.prefix + t);
        if (null === i || void 0 === i) return e;
        try {
          i = JSON.parse(i);
        } catch (t) {}
        return i;
      },
      set: function(t, e, i) {
        if (((i = i || this.quotaHandler), null === e || void 0 === e)) this.remove(t);
        else
          try {
            this.storage.setItem(this.prefix + t, JSON.stringify(e));
          } catch (o) {
            if (!(o instanceof window.DOMException && o.code === o.QUOTA_EXCEEDED_ERR)) throw o;
            i.call(this, o, t, e);
          }
      },
      quotaHandler: function(t, i, o) {
        e.e('Caught quota exception: ' + t.name + ', while setting: ' + i, o, t);
      },
      remove: function(t) {
        this.storage.removeItem(this.prefix + t);
      },
      clear: function() {
        for (var t = this.storage, e = 0; e < t.length; ) {
          var i = t.key(e);
          0 === i.indexOf(this.prefix) ? t.removeItem(i) : e++;
        }
      },
      subStorage: function(t) {
        return new i(this.storage, this.prefix + t);
      },
    });
  return i;
});
playtotv.define(
  'playtotv.server.Server',
  [
    'playtotv.server.gatekeeperAPI',
    'playtotv.model.ObjectModel',
    'playtotv.net.request',
    'playtotv.storage.Storage',
  ],
  function(e, t, r, i) {
    'use strict';
    var s = playtotv,
      n = s.REQUIRED,
      a = s.util,
      o = s.Logger.get(this.id),
      l = s.event,
      u = e.getInit;
    return (
      (u.gatekeeper = !0),
      new s.Class({
        extend: t,
        initialize: function(e) {
          this._super({
            urls: new t({}),
            status: void 0,
            timeOffset: void 0,
            clientMinimalVersion: {},
          }),
            (e = s.params(e, {
              url: '',
              badDelay: 5e3,
              onRetry: function(e, t) {
                o.d('Retry: ' + e + ' (' + t + ')');
              },
              gatekeeperData: void 0,
              gatekeeperDataTime: void 0,
            }));
          var r = e.url;
          r.length > 0 && /^localhost|.+\..+/.test(r) && r.indexOf('//') === -1 && (r = '//' + r),
            (this.badDelay = e.badDelay),
            (this.onRetry = e.onRetry),
            (this.serverUrl = r),
            (this.serviceUrls = {}),
            (this.urlPromises = {}),
            (this.gatekeeperUrl = this.getGatekeeperServiceUrl(u.service || 'API') + u.url),
            (this.gatekeeperData = e.gatekeeperData),
            (this.gatekeeperDataTime = e.gatekeeperDataTime),
            (this.settings = {
              cache: !1,
              dataType: 'json',
              timeout: 2e4,
              headers: {},
              urlParams: {},
            }),
            (this.retryDelay = 5e3),
            (this.resolveTaps = []),
            (this.rejectTaps = []),
            (this.storage = new i(window.localStorage, r + '/')),
            l.addListener(
              window,
              'offline',
              function() {
                o.d('Event: offline'), this.setStatus('BAD');
              }.bind(this),
            ),
            l.addListener(
              window,
              'online',
              function() {
                o.d('Event: online'), this.setStatus('GOOD');
              }.bind(this),
            ),
            this.getServiceUrl('API');
        },
        setStatus: (function() {
          function e() {
            t && (t.changeProperty('status', 'BAD'), (t = null));
          }
          var t = !1;
          return function(r) {
            'BAD' === r
              ? t || ((t = this), setTimeout(e, this.badDelay))
              : ((t = !1), this.changeProperty('status', r));
          };
        })(),
        urlKeys: function(e) {
          var t = e.match(/\{(\w+)\}/g) || [];
          return t.map(function(e) {
            return e.substr(1, e.length - 2);
          });
        },
        validateCall: function(e, t, r) {
          r = r || this.urlKeys(e.url);
          var i = e.label,
            s = e.dataKeys,
            n = Object.keys(t || {}),
            a = r.concat(e.queryKeys).concat(s),
            o = [];
          if (
            (n.forEach(function(e) {
              a.indexOf(e) === -1 && o.push(e);
            }),
            o.length > 0)
          )
            throw new Error(i + ': invalid parameters: ' + o.join(', '));
          var l = r.concat(s),
            u = [];
          if (
            (l.forEach(function(e) {
              void 0 === t[e] && u.push(e);
            }),
            u.length > 0)
          )
            throw new Error(i + ': required parameters are undefined: ' + u.join(', '));
        },
        _getServiceUrls: function() {
          var e,
            t = this.gatekeeperData,
            r = this.gatekeeperDataTime;
          return (
            t && r
              ? ((t.serverTime += Date.now() - r),
                (e = Promise.resolve(t)),
                delete this.gatekeeperData,
                delete this.gatekeeperDataTime)
              : (e = this.call(u)),
            e.then(
              function(e) {
                return e.status && 'AVAILABLE' !== e.status
                  ? (this.setStatus('BUSY'),
                    this.onRetry(this.gatekeeperUrl, 'busy-' + e.status.toLowerCase()),
                    Promise.delay(this.retryDelay).then(this._getServiceUrls))
                  : (this.setStatus('GOOD'),
                    this.changeProperty('timeOffset', Date.now() - e.serverTime),
                    this.changeProperty('clientMinimalVersion', e.clientMinimalVersion),
                    e.services.reduce(
                      function(e, t) {
                        return (
                          (e[t.serviceType] = s.shuffle(
                            t.serviceUrls.map(function(e) {
                              return e.replace(/\d+\/$/, '');
                            }),
                          )),
                          e
                        );
                      }.bind(this),
                      {},
                    ));
              }.bind(this),
              function(e) {
                return (
                  o.i('getServiceUrls: retry: ' + e.status),
                  this.setStatus('BAD'),
                  this.onRetry(this.gatekeeperUrl, 'status-' + e.status),
                  Promise.delay(this.retryDelay).then(this._getServiceUrls)
                );
              }.bind(this),
            )
          );
        },
        getServiceUrls: Promise.throttle(function() {
          return this._getServiceUrls();
        }, 2e3),
        getGatekeeperServiceUrl: function(e) {
          return this.serverUrl + '/' + e.toLowerCase() + '/';
        },
        getServiceUrl: function(e) {
          var t = this.urlPromises;
          if (!t[e]) {
            var r = this.serviceUrls,
              i = r[e] || [];
            i.length > 0
              ? (t[e] = Promise.resolve(i.shift()))
              : (t[e] = this.getServiceUrls().then(
                  function(t) {
                    s.each(t, function(e, t) {
                      r[t] = e;
                    });
                    var i = t[e] || [];
                    return 0 === i.length ? this.getGatekeeperServiceUrl(e) : r[e].shift();
                  }.bind(this),
                ));
          }
          return t[e];
        },
        invalidateServiceUrl: function(e, t) {
          var r = this.urlPromises;
          r[e] === t && (r[e] = null);
        },
        request: function(e, t, i) {
          i =
            i ||
            function() {
              return this.request(e, t, i);
            }.bind(this);
          var n = s.extend({}, this.settings, t);
          return r(e, n).then(
            function(e) {
              return t.label === u.label && e.status && 'AVAILABLE' !== e.status
                ? e
                : (this.setStatus('GOOD'), e);
            }.bind(this),
            function(t) {
              var r = t.status;
              return 0 === r || 'no-xhr-proxy' === r || (r > 12e3 && r < 13e3)
                ? (this.onRetry(e, 'status-' + r),
                  this.setStatus('BAD'),
                  Promise.delay(this.retryDelay).then(i))
                : 503 === r
                ? (this.onRetry(e, 'status-' + r), Promise.delay(this.retryDelay).then(i))
                : 'timeout' === r
                ? (this.onRetry(e, 'timeout'), this.setStatus('BAD'), i())
                : Promise.reject(t);
            }.bind(this),
          );
        },
        getUrlAndRequest: function(e, t, r) {
          var i = this.getServiceUrl(e);
          return i.then(
            function(s) {
              return (
                this.urls.setProperty(e, s),
                this.request(
                  s + t,
                  r,
                  function() {
                    return this.invalidateServiceUrl(e, i), this.getUrlAndRequest(e, t, r);
                  }.bind(this),
                )
              );
            }.bind(this),
          );
        },
        tap: function(e, t) {
          return (
            t.then(
              function(t) {
                this.resolveTaps.forEach(function(r) {
                  r.call(e, t);
                });
              }.bind(this),
              function(t) {
                this.rejectTaps.forEach(function(r) {
                  r.call(e, t);
                });
              }.bind(this),
            ),
            t
          );
        },
        call: function(e, t, r) {
          s.params(e, {
            label: void 0,
            service: 'API',
            gatekeeper: !1,
            type: 'GET',
            url: n,
            queryKeys: [],
            dataKeys: [],
          }),
            (t = t || {}),
            (r = s.extend({ label: e.label, type: e.type }, r));
          var i = e.url,
            o = this.urlKeys(i);
          this.validateCall(e, t, o),
            o.forEach(function(e) {
              i = i.replace('{' + e + '}', encodeURIComponent(t[e]));
            }),
            e.queryKeys.forEach(function(e) {
              void 0 !== t[e] && (i = a.addURLParam(i, e, t[e]));
            });
          var l = e.dataKeys;
          l.length > 0 &&
            ((r.data = {}),
            l.forEach(function(e) {
              r.data[e] = t[e];
            }));
          var u = e.service;
          if (e.gatekeeper) {
            var h = this.getGatekeeperServiceUrl(u) + i;
            return this.tap(e, this.request(h, r));
          }
          return this.tap(e, this.getUrlAndRequest(u, i, r));
        },
        toClientTime: function(e) {
          return e + this.timeOffset;
        },
        toServerTime: function(e) {
          return (e = e || Date.now()), e - this.timeOffset;
        },
        log: function(e, t, r, i) {
          var s = this.call(e, t, r);
          return o.log(i || 'DEBUG', [e.label, t, s]), s;
        },
        callbacks: ['_getServiceUrls'],
      })
    );
  },
);
