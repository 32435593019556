import { useRaf } from 'hooks/useRaf';
import React, { FC, useEffect, useRef, useState } from 'react';
import './AudioSync.css';

const DEVICE_RATIO = window.devicePixelRatio || 1;

interface UpdateCanvasOptions {
  ref: React.RefObject<HTMLCanvasElement>;
  startTime: number;
  duration: number;
  onDone: () => void;
  syncing: boolean;
}

const soundBars = [
  {
    y0: 0,
    y1: 0.1,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0,
    y1: 0.1,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
  {
    y0: 0.1,
    y1: 0.3,
    startedAt: 0,
    duration: 0,
  },
];

function createBar() {
  const y0 = Math.random() * 0.4;
  const y1 = y0 + Math.random() * (1 - y0);

  return {
    y0,
    y1,
    startedAt: Date.now(),
    duration: Math.random() * 1000,
  };
}

function updateCanvas({ ref, startTime, duration, onDone: done, syncing }: UpdateCanvasOptions) {
  const width = 182 * DEVICE_RATIO;
  const height = width;
  const circleWidth = 8 * DEVICE_RATIO;
  const barStrokeWidth = 6 * DEVICE_RATIO;
  const radius = width / 2 - circleWidth / 2;
  // const soundBars = [0.1, 0.3, 0.5, 0.4, 0.9, 0.8, 0.5, 0.4, 0.3, 0.2];

  const now = Date.now();
  const passedTime = now - startTime;
  const progress = syncing ? passedTime / duration : 0;

  if (!ref.current) {
    return;
  }

  const canvas = ref.current;
  const context = canvas.getContext('2d');

  if (!context) {
    return;
  }

  context.clearRect(0, 0, width, height);

  // Circle background
  context.beginPath();
  context.lineWidth = circleWidth;
  context.strokeStyle = '#C7C3D9';
  context.arc(width / 2, height / 2, radius, -0.5 * Math.PI, 1.5 * Math.PI);
  context.stroke();
  context.closePath();

  // Circle progress
  const r = -0.5 + 2 * progress;

  context.beginPath();
  context.lineWidth = circleWidth;
  context.strokeStyle = '#e75a6b';
  context.lineCap = 'round';
  // Start at `-0.5 * PI` ends at `1.5 * PI`
  context.arc(width / 2, height / 2, radius, -0.5 * Math.PI, r * Math.PI);
  context.stroke();
  context.closePath();

  // Sound bars
  const soundBarsLength = soundBars.length - 1;
  const barWidth = barStrokeWidth * 1.6;
  const barStartX = (width - soundBarsLength * barWidth) / 2;

  soundBars.forEach(({ startedAt, duration, y0, y1 }, index) => {
    if (!startedAt) {
      soundBars[index] = createBar();

      return;
    }

    const barProgress = (Date.now() - startedAt) / duration;
    const sinProgress = Math.sin(barProgress * Math.PI);

    const x = barStartX + index * (barStrokeWidth * 1.6);
    const barHeight = 160 * (y0 + y1 * sinProgress);
    const center = height / 2;

    context.beginPath();
    context.lineWidth = barStrokeWidth;
    context.strokeStyle = '#e75a6b';
    context.lineCap = 'round';
    context.moveTo(x, center - barHeight / 2);
    context.lineTo(x, center - barHeight / 2 + barHeight);
    context.stroke();
    context.closePath();

    if (barProgress >= 1) {
      soundBars[index] = createBar();
    }
  });

  // Animation done
  if (progress >= 1) {
    done();
  }
}

interface Props {
  syncing: boolean;
}

const AudioSync: FC<Props> = ({ syncing = false }) => {
  const [startedAt, setStartedAt] = useState(0);
  const duration = 30000; // 30 seconds

  const ref = useRef<HTMLCanvasElement>(null);

  // "Scale" canvas to device pixel ratio
  const width = 182 * DEVICE_RATIO;
  const height = width;

  useEffect(() => {
    setStartedAt(syncing ? Date.now() : 0);
  }, [syncing]);

  useRaf(
    (time, done) => updateCanvas({ ref, startTime: startedAt, duration, onDone: done, syncing }),
    [syncing, startedAt],
  );

  return <canvas ref={ref} className="AudioSync" width={width} height={height} />;
};

export default AudioSync;
