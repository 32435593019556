import { NativeActions } from 'actions/nativeActions';
import { Reducer } from 'redux';

export type NativeReducer = {
  ready: boolean;
  env: 'web' | 'native';
  suspended: boolean;
  storedAuthenticationToken: {
    status: 'NOT_FETCHED' | 'FETCHED';
    token?: string;
  };
};

export const initialState: NativeReducer = {
  ready: false,
  env: 'web',
  suspended: false,
  storedAuthenticationToken: {
    status: 'NOT_FETCHED',
  },
};

export const nativeReducer: Reducer<NativeReducer, NativeActions> = (
  state = initialState,
  action,
): NativeReducer => {
  switch (action.type) {
    case '@native/READY':
      return {
        ...state,
        ready: true,
        env: action.payload,
      };

    case '@native/SUSPENDED':
      return {
        ...state,
        suspended: action.payload,
      };

    case '@native/STORE_AUTHENTICATION_TOKEN':
      return {
        ...state,
        storedAuthenticationToken: {
          status: 'FETCHED',
          token: action.payload,
        },
      };

    case '@native/SET_AUTHENTICATION_TOKEN_FETCH':
      return {
        ...state,
        storedAuthenticationToken: {
          status: 'NOT_FETCHED',
          token: undefined,
        },
      };

    default:
      return state;
  }
};
