import { cx } from 'cx';
import { StyleProps } from 'hooks/useStyles';
import Apple from 'public/icons/apple.svg';
import ArrowBack from 'public/icons/arrow-back.svg';
import ArrowDowm from 'public/icons/arrow-down.svg';
import ArrowDropDown from 'public/icons/arrow-drop-down.svg';
import ArrowForward from 'public/icons/arrow-forward.svg';
import ArrowUp from 'public/icons/arrow-up.svg';
import Check from 'public/icons/check.svg';
import Close from 'public/icons/close.svg';
import Crown from 'public/icons/crown.svg';
import Edit from 'public/icons/edit.svg';
import Facebook from 'public/icons/facebook.svg';
import Female from 'public/icons/female.svg';
import Google from 'public/icons/google.svg';
import Hamburger from 'public/icons/hamburger.svg';
import MailSent from 'public/icons/mail-sent.svg';
import Mail from 'public/icons/mail.svg';
import Male from 'public/icons/male.svg';
import NonBinary from 'public/icons/non-binary.svg';
import OpenInNew from 'public/icons/open-in-new.svg';
import PlayCircle from 'public/icons/play-circle.svg';
import Share from 'public/icons/share.svg';
import Twitter from 'public/icons/twitter.svg';
import Warn from 'public/icons/warn.svg';
import Whatsapp from 'public/icons/whatsapp.svg';
import React from 'react';
import Box from '../Box';
import './Icon.css';

export const ICON_MAP = {
  'arrow-back': ArrowBack,
  'arrow-down': ArrowDowm,
  'arrow-dropdown': ArrowDropDown,
  'arrow-forward': ArrowForward,
  'non-binary': NonBinary,
  'open-in-new': OpenInNew,
  'play-circle': PlayCircle,
  apple: Apple,
  arrowUp: ArrowUp,
  check: Check,
  close: Close,
  crown: Crown,
  edit: Edit,
  facebook: Facebook,
  female: Female,
  google: Google,
  hamburger: Hamburger,
  mail: Mail,
  mailSent: MailSent,
  male: Male,
  share: Share,
  warn: Warn,
  twitter: Twitter,
  whatsapp: Whatsapp,
};

export type IconNames = keyof typeof ICON_MAP;

type Props = {
  label?: string;
  name: IconNames;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  color?: StyleProps['color'] | 'none';
};

const Icon = ({ label, name, size = 'small', color = 'black' }: Props) => {
  if (!(name in ICON_MAP)) {
    throw new Error(`Icon name "${name}" not found`);
  }

  return (
    <Box
      display="inline-block"
      color={color !== 'none' ? color : undefined}
      className={cx(`Icon size-${size}`, color === 'none' && 'is-default')}
      title={label}
    >
      {React.createElement(ICON_MAP[name], {
        className: 'icon-svg',
        'data-testid': 'icon-svg',
      })}
    </Box>
  );
};

export default Icon;
