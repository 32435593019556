import { fetchEpisodeHighlights, fetchLeaderboard } from 'actions/statisticsActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Stack from 'components/Stack';
import RankPlayerScore from 'components/Statistics/RankPlayerScore';
import StatisticsFilter from 'components/Statistics/StatisticsFilter';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import {
  getStatisticsByEpisodeCode,
  getStatisticsRankListByEpisodeCode,
  getStatisticsSelectedRegionFilter,
  getStatisticsSelectedShowFilter,
} from 'selectors/statisticsSelectors';
import StatisticsLoading from './StatisticsLoading';
import StatisticsNotFound from './StatisticsNotFound';

interface Props {
  episodeId: string;
}

const StatisticsEpisodeRankingsScreen: FC<Props> = ({ episodeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statisticsEpisode = useSelector((state: AppState) =>
    getStatisticsByEpisodeCode(state, episodeId),
  );

  const statisticsEpisodeRankList = useSelector((state: AppState) =>
    getStatisticsRankListByEpisodeCode(state, episodeId),
  );

  const showFilter = useSelector(getStatisticsSelectedShowFilter);
  const regionFilter = useSelector(getStatisticsSelectedRegionFilter);

  useEffect(() => {
    dispatch(fetchLeaderboard(episodeId));
  }, []);

  useEffect(() => {
    dispatch(fetchLeaderboard(episodeId));
  }, [showFilter, regionFilter]);

  useEffect(() => {
    if (!statisticsEpisodeRankList) {
      dispatch(fetchEpisodeHighlights(episodeId));
    }
  }, [statisticsEpisodeRankList]);

  if (!statisticsEpisode) return <StatisticsLoading />;
  if (!statisticsEpisode.fetchSucces) return <StatisticsNotFound />;

  const { broadcastWindowStartDate } = statisticsEpisode;

  return (
    <>
      <TopBar title={t('Rankings')} left={<MenuToggle toggleType="back" />} />
      <Box paddingX="small">
        <Stack spacing="medium" paddingBottom="small">
          <Text color="white" textAlign="center">
            {t('{{date, day-date}}', {
              date: new Date(broadcastWindowStartDate),
            })}
          </Text>
          <StatisticsFilter />
        </Stack>
      </Box>
      <Panel>
        <Box display="flex" flexDirection="column">
          <Stack spacing="xsmall" divider>
            {statisticsEpisodeRankList && statisticsEpisodeRankList.entries ? (
              statisticsEpisodeRankList.entries.map((entry, index) => {
                const { score, avatarUrl, displayName, rank } = entry;

                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingRight="medium"
                  >
                    <Stack
                      spacing="medium"
                      paddingLeft="medium"
                      horizontal
                      align="center"
                      paddingRight="large"
                    >
                      <Text color="black" fontWeight="medium">
                        {rank}
                      </Text>
                      <Avatar src={avatarUrl} size="small" />
                      <Text color="black" fontWeight="medium">
                        {displayName}
                      </Text>
                    </Stack>
                    <Text color="black" fontWeight="semiBold">
                      {t('{{score}} pts', { score })}
                    </Text>
                  </Box>
                );
              })
            ) : (
              <Text textAlign="center">{t('No entries found')}</Text>
            )}
          </Stack>
        </Box>
        <RankPlayerScore episodeId={episodeId} />
      </Panel>
    </>
  );
};

export default StatisticsEpisodeRankingsScreen;
