import { facebookSignIn, getFacebookUserGraph, setFriendScores } from 'actions/socialActions';
import { AppState } from 'reducers';
import { Store } from 'redux';
import { FriendsFetchState, SocialSignInState } from 'types/social';

export const connectSocial = (store: Store<AppState, any>) => {
  let signInState: SocialSignInState | null;
  let friendsFetch: FriendsFetchState | null;

  store.subscribe(() => {
    const state = store.getState();

    const { token, type, socialSignInState, friendIds, friendsFetchStatus } = state.social;

    if (friendsFetchStatus === 'WAITING') {
      friendsFetch = friendsFetchStatus;
    }

    if (socialSignInState === 'WAITING') {
      signInState = socialSignInState;
    }

    if (friendIds && friendsFetchStatus === 'SUCCESSFUL' && friendsFetchStatus !== friendsFetch) {
      friendsFetch = friendsFetchStatus;
      store.dispatch(setFriendScores());
    }

    if (signInState === socialSignInState || !type) {
      return;
    }

    switch (type) {
      case 'facebook': {
        if (socialSignInState === 'NO_USER_FOUND') {
          signInState = socialSignInState;
          store.dispatch(facebookSignIn(true));
        }

        if (!token) {
          return;
        }

        if (socialSignInState === 'GET_GRAPH') {
          signInState = socialSignInState;
          store.dispatch(getFacebookUserGraph());
        }

        break;
      }
    }
  });
};
