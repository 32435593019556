import Avatar from 'components/Avatar';
import Card from 'components/Card';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getStudioPlayer } from 'selectors/studioPlayerSelectors';
import './StudioPlayerCard.css';

interface Props {
  playerId: string;
  selected?: boolean;
  onClick: () => void;
}

const StudioPlayerCard: FC<Props> = ({ playerId, selected, onClick }) => {
  const player = useSelector((state: AppState) => getStudioPlayer(state, playerId));

  if (!player) {
    return null;
  }

  return (
    <Card
      className="StudioPlayerCard"
      shadow={selected ? 'small' : 'heavy'}
      backgroundColor={selected ? 'primary' : 'white'}
      onClick={onClick}
    >
      <Stack spacing="medium" align="center">
        <Avatar src={player.imageUrl} />
        <Text fontSize="large" color="black" textAlign="center">
          {player.name}
        </Text>
      </Stack>
    </Card>
  );
};

export default StudioPlayerCard;
