import Box from 'components/Box';
import Stack from 'components/Stack';
import { cx } from 'cx';
import React from 'react';
import './AnswerButton.css';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  letter: 'A' | 'B' | 'C' | 'D';
  correct?: boolean;
  incorrect?: boolean;
  disabled?: boolean;
}

const AnswerButton = ({
  children,
  disabled,
  onClick,
  selected,
  letter,
  correct,
  incorrect,
}: Props) => {
  return (
    <Box
      component="button"
      className={cx(
        'AnswerButton',
        selected && 'is-selected',
        correct && 'variant-correct',
        incorrect && 'variant-incorrect',
        selected && 'is-selected',
      )}
      fontSize="medium"
      fontWeight="medium"
      disabled={disabled}
      onClick={onClick}
      type="button"
      tabIndex={0}
      width="full"
      textAlign="left"
      paddingX="medium"
      paddingY="small"
    >
      <Stack spacing="large" horizontal>
        <span>{letter}</span>
        {children}
      </Stack>
    </Box>
  );
};

export default AnswerButton;
