import Box from 'components/Box';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import { cx } from 'cx';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import './Stepper.css';
import StepperItem from './StepperItem';

interface Props {
  children: React.ReactNode;
  onSkip: () => void;
}

const Stepper = ({ children, onSkip }: Props) => {
  const { t } = useTranslation();
  const nodes = React.Children.toArray(children);
  const elements = nodes.filter((node) =>
    React.isValidElement<ReturnType<typeof StepperItem>>(node),
  ) as ReactElement<Parameters<typeof StepperItem>[0]>[];
  const [index, setIndex] = useState(0);
  const isLastStep = index === elements.length - 1;
  const onNext = () => {
    if (isLastStep) {
      return onSkip();
    }

    setIndex(index + 1);
  };
  const swipeHandlers = useSwipeable({
    onSwipedRight() {
      if (index > 0) {
        setIndex(index - 1);
      }
    },
    onSwipedLeft() {
      if (!isLastStep) {
        setIndex(index + 1);
      }
    },
  });

  return (
    <Box height="full" display="flex" flexDirection="column" flexGrow={1} padding="gutter">
      <Box display="flex" justifyContent="flex-end" paddingBottom="gutter">
        <FlatButton variant="white" onClick={onSkip} padding="medium">
          {t('Skip')}
        </FlatButton>
      </Box>
      <Box
        key={index}
        {...swipeHandlers}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="auto"
      >
        {elements[index].props.render()}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack spacing="xsmall" horizontal paddingLeft="medium">
          {elements.map((_, i) => (
            <div
              role="button"
              key={i}
              className={cx('Stepper-dot', i === index && 'is-active')}
              onClick={() => setIndex(i)}
            />
          ))}
        </Stack>

        <FlatButton variant="white" onClick={onNext} padding="medium">
          {isLastStep ? t('Finish') : t('Next')}
        </FlatButton>
      </Box>
    </Box>
  );
};

export default Stepper;
