import { ErrorActions } from 'actions/errorActions';
import { Reducer } from 'redux';

export type ErrorReducer = {
  errorType?: string;
};

export const initialState: ErrorReducer = {};

export const errorReducer: Reducer<ErrorReducer, ErrorActions> = (state = initialState, action) => {
  switch (action.type) {
    case '@error/GLOBAL': {
      return {
        ...state,
        errorType: action.payload,
      };
    }

    default:
      return state;
  }
};
