import { setIsVerifying, setSocialErrored, signInSocial } from 'actions/socialActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import GoogleLogo from 'components/GoogleLogo';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialErrored, getSocialTriggered } from 'selectors/socialSelectors';
import { isIOS } from 'utils/platform';
import toTitleCase from 'utils/toTitleCase';

type Dialog = {
  dialogTitle: string;
  dialogContent: string;
};

type SocialName = 'facebook' | 'apple' | 'google';

type LoginProviders = SocialProviders | 'email';

interface Props {
  firstItem?: LoginProviders;
  emailTrigger?: () => void;
}

export const Logins: FC<Props> = ({ firstItem, emailTrigger }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [firstItemLocked, setFirstItemLocked] = useState<LoginProviders>();
  const [dialog, setDialog] = useState<Dialog | false>(false);

  const socialTriggered = useSelector(getSocialTriggered);
  const socialErrored = useSelector(getSocialErrored);

  useEffect(() => {
    if (!firstItem || firstItemLocked) {
      return;
    }

    setFirstItemLocked(firstItem);
  }, [firstItem]);

  useEffect(() => {
    if (socialErrored) {
      switch (socialErrored) {
        case 'GOOGLE_ID_NOT_UNIQUE': {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Email is already in use.'),
          });
        }
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }
  }, [socialErrored]);

  const triggerSignIn = async (loginType: LoginProviders) => {
    if (loginType === 'email') {
      if (!!emailTrigger) {
        emailTrigger();
      }
    } else {
      if (!!firstItemLocked) {
        dispatch(setIsVerifying(true));
      }

      dispatch(signInSocial({ socialType: loginType }));
    }
  };

  const handleDialogClose = () => {
    setDialog(false);
    dispatch(setSocialErrored(null));
  };

  const order: LoginProviders[] = ['apple-id', 'google'];

  if (!!emailTrigger) {
    order.push('email');
  }

  const buildItem = (type: LoginProviders) => {
    if (type === 'apple-id' && !isIOS) {
      return null;
    }

    const isLoggingIn = socialTriggered === type;
    let variant: SocialName | 'email' | undefined = undefined;
    let icon;

    switch (type) {
      case 'facebook':
        variant = type;
        icon = <Icon name={type} color={isLoggingIn ? 'black' : 'white'} label="" size="medium" />;
        break;
      case 'apple-id':
        variant = 'apple';
        icon = <Icon name={variant} color="black" label="" size="medium" />;
        break;
      case 'google':
        variant = type;
        icon = <GoogleLogo />;
        break;
      case 'email':
        variant = 'email';
        break;
    }

    return (
      <Button
        key={type}
        disabled={isLoggingIn}
        width="full"
        variant={variant === 'email' ? 'default' : variant}
        icon={icon}
        onClick={() => triggerSignIn(type)}
      >
        {isLoggingIn
          ? t('Logging in with {{socialName}}', { socialName: toTitleCase(variant) })
          : t('Continue with {{socialName}}', { socialName: toTitleCase(variant) })}
      </Button>
    );
  };

  return (
    <>
      <Stack spacing="medium">{order.sort((a) => (a === firstItem ? -1 : 0)).map(buildItem)}</Stack>
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={handleDialogClose}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="large">
              <Stack align="center" spacing="medium">
                <Text color="secondary" fontSize="large" fontWeight="medium">
                  {dialog && dialog.dialogTitle}
                </Text>
                <Text color="black" fontSize="small">
                  {dialog && dialog.dialogContent}
                </Text>
              </Stack>
              <Button onClick={handleDialogClose}>{t('Confirm')}</Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};
