import { push } from 'actions/historyActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const MissingRouteScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Redirect home, don't let the user get stuck
    dispatch(push('/'));
  }, []);
  return null;
};

export default MissingRouteScreen;
