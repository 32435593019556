import { push } from 'actions/historyActions';
import { getFriendIds } from 'actions/socialActions';
import { fetchEpisodeHighlights } from 'actions/statisticsActions';
import Box from 'components/Box';
import Button from 'components/Button';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import PieChart from 'components/PieChart';
import RegionMap from 'components/RegionMap';
import Stack from 'components/Stack';
import EpisodeHost from 'components/Statistics/EpisodeHost';
import GenderRepresentation from 'components/Statistics/GenderRepresentation';
import TopTierPlayer from 'components/Statistics/TopTierPlayer';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { isFacebookUser } from 'selectors/socialSelectors';
import { getStatisticsByEpisodeCode } from 'selectors/statisticsSelectors';
import StatisticsLoading from './StatisticsLoading';
import StatisticsNotFound from './StatisticsNotFound';

interface Props {
  episodeId: string;
}

const StatisticsEpisodeDetailsScreen: FC<Props> = ({ episodeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const facebookUser = useSelector(isFacebookUser);

  useEffect(() => {
    if (!episodeId) {
      return;
    }

    dispatch(fetchEpisodeHighlights(episodeId));

    if (facebookUser) {
      dispatch(getFriendIds(episodeId));
    }
  }, [episodeId]);

  const statisticsEpisode = useSelector((state: AppState) =>
    getStatisticsByEpisodeCode(state, episodeId),
  );

  if (!statisticsEpisode) return <StatisticsLoading />;
  if (!statisticsEpisode.fetchSucces) return <StatisticsNotFound />;

  const { percentageOfMen, percentageOfWomen, top3, bestRegion } = statisticsEpisode;

  const graphValue = (percentage: number) => 100 - percentage;

  return (
    <>
      <TopBar title={t('ShowStatistics')} left={<MenuToggle toggleType="back" />} />
      <Panel paddingTop={false}>
        <EpisodeHost episodeId={episodeId} />
        <Stack spacing="medium" divider>
          <Stack spacing="medium">
            <Box display="flex" justifyContent="center">
              <Stack spacing="large" horizontal paddingTop="medium">
                <Stack spacing="medium" horizontal>
                  <Stack spacing="medium" align="center">
                    <Text color="black" fontSize="medium" fontWeight="semiBold" textAlign="center">
                      {t('Men')}
                    </Text>
                    <PieChart
                      variant="chart-primary"
                      values={[percentageOfMen, graphValue(percentageOfMen)]}
                    />
                    <GenderRepresentation
                      percentageOfGender={percentageOfMen}
                      color="black"
                      icon="male"
                    />
                  </Stack>
                  <Stack spacing="medium" align="center">
                    <Text color="black" fontSize="medium" fontWeight="semiBold" textAlign="center">
                      {t('Women')}
                    </Text>
                    <PieChart
                      variant="chart-secondary"
                      values={[percentageOfWomen, graphValue(percentageOfWomen)]}
                    />
                    <GenderRepresentation
                      percentageOfGender={percentageOfWomen}
                      color="secondary"
                      icon="female"
                    />
                  </Stack>
                  <Stack spacing="medium" align="center">
                    <Text color="black" fontSize="medium" fontWeight="semiBold">
                      {t('Best Region')}
                    </Text>
                    <RegionMap region={bestRegion} />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Stack spacing="medium">
            <Text color="black" textAlign="center" fontWeight="semiBold" fontSize="medium">
              {t("App - Day's winner")}
            </Text>
            <Box display="flex" justifyContent="center">
              <Stack spacing="large" horizontal align="center">
                {top3.map((topEntry, index) => (
                  <TopTierPlayer
                    key={index}
                    avatarUrl={topEntry.avatarUrl}
                    displayName={topEntry.displayName}
                    rank={topEntry.rank}
                    score={topEntry.score}
                  />
                ))}
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={() => {
                  dispatch(push(`/statistics/rankings/${episodeId}`));
                }}
              >
                {t('View rankings')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Panel>
    </>
  );
};

export default StatisticsEpisodeDetailsScreen;
