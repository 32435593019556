import { Form } from '@blockle/form';
import AnswerButton from 'components/AnswerButton';
import AudioSync from 'components/AudioSync';
import Avatar from 'components/Avatar';
import AvatarSelect from 'components/AvatarSelect';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import CategoryCard from 'components/CategoryCard';
import Checkbox from 'components/Checkbox';
import Coin from 'components/Coin';
import Dialog from 'components/Dialog';
import Dropdown from 'components/Dropdown';
import FlatButton from 'components/FlatButton';
import Icon from 'components/Icon';
import { ICON_MAP } from 'components/Icon/Icon';
import Logo from 'components/Logo';
import LogoProgress from 'components/LogoProgress';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import PieChart from 'components/PieChart';
import ProgressBar from 'components/ProgressBar';
import RadioButton from 'components/RadioButton';
import ShareButton from 'components/ShareButton/ShareButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import ToggleButton from 'components/ToggleButton';
import Tooltip from 'components/Tooltip';
import TopBar from 'components/TopBar';
import { useSound } from 'hooks/useSound';
import React, { useState } from 'react';
import correctAnswerSound from '../../public/audio/correct-answer.mp3';

const StyleGuideScreen = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const logger = (name: string) => () => console.log(name);
  const sound = useSound(correctAnswerSound);

  return (
    <>
      <TopBar title="Style Guide" left={<MenuToggle />} />
      <Panel>
        <Box paddingX="gutter">
          <Stack spacing="medium" divider>
            {/* Font */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Text
            </Text>

            <Stack spacing="small">
              <Text component="h1" fontSize="xlarge" color="black" fontWeight="medium">
                H1/Heading/xlarge
              </Text>
              <Text component="h2" fontSize="xlarge" color="black" fontWeight="bold">
                H2/Heading/xlarge
              </Text>
              <Text component="h3" fontSize="large" color="black" fontWeight="medium">
                H3/Heading/large
              </Text>
              <Text
                component="h4"
                fontSize="xsmall"
                color="darkGray"
                fontWeight="extraBold"
                textTransform="uppercase"
              >
                H4/Heading/xsmall
              </Text>
              <Text fontSize="medium" color="black" fontWeight="medium">
                Body 1/medium
              </Text>
              <Text fontSize="small" color="black">
                Body 2/small
              </Text>
              <Text fontSize="xsmall" color="primary">
                Caption/xsmall
              </Text>
              <Text fontSize="small" color="black" fontWeight="semiBold">
                Label/small
              </Text>
            </Stack>

            <Stack spacing="small">
              <Text fontSize="xsmall" fontWeight="extraBold">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Text>
              <Text component="p" fontSize="xsmall">
                Donec condimentum, velit eget hendrerit facilisis, felis ante eleifend magna, in
                hendrerit tortor dui eget lacus. Mauris dui arcu, maximus vel hendrerit sed, rutrum
                non risus. Duis elementum arcu eu neque auctor blandit. Sed orci orci, pharetra non
                magna vel, pharetra pellentesque massa. Aenean porttitor sed neque et tempus.
                Quisque sollicitudin finibus scelerisque. Sed ac elit eget massa cursus tincidunt
                quis eget nisl.
              </Text>
              <Text component="p" fontSize="xsmall">
                Aenean consequat lacinia leo id consequat. Quisque eu eros diam. Cras pretium ipsum
                sed quam molestie, quis sagittis ligula aliquet. Aenean sagittis felis vel libero
                vulputate porttitor. Nunc at interdum dui. Nullam in consectetur erat, eu imperdiet
                diam. Pellentesque interdum vulputate ligula nec eleifend.
              </Text>
            </Stack>

            {/* Buttons */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Buttons
            </Text>

            <Stack spacing="small" align="center">
              {/* Button */}
              <Button onClick={logger('Button')}>Button</Button>
              <Button onClick={logger('Button + disabled (should not fire)')} disabled>
                Button + disabled
              </Button>
              <Button
                onClick={logger('Button + icon')}
                icon={<Icon name="share" label="" color="white" size="medium" />}
              >
                Button + icon
              </Button>
              <Button
                onClick={logger('Button')}
                variant="white"
                icon={<Icon name="share" label="" color="primary" size="medium" />}
              >
                Button + white + icon
              </Button>
              <Button onClick={logger('Button')} variant="white">
                Button + white
              </Button>
              <Button onClick={logger('Button')} variant="white" disabled>
                Button + white + disabled
              </Button>

              <Button
                onClick={logger('Button')}
                variant="facebook"
                icon={<Icon name="facebook" label="" color="white" size="medium" />}
                width="full"
              >
                Facebook
              </Button>

              <Button
                onClick={logger('Button')}
                variant="apple"
                icon={<Icon name="apple" label="" color="black" size="medium" />}
              >
                Apple
              </Button>

              {/* FlatButton */}
              <FlatButton onClick={logger('FlatButton')}>FlatButton</FlatButton>
              <FlatButton onClick={logger('FlatButton + disabled (should not fire)')} disabled>
                FlatButton + disabled
              </FlatButton>
            </Stack>

            <Box backgroundColor="lightGrey" paddingBottom="medium" paddingX="gutter">
              <Stack spacing="medium">
                <AnswerButton disabled letter="A">
                  Neuenberg Disabled
                </AnswerButton>
                <AnswerButton letter="A">Neuenberg</AnswerButton>
                <AnswerButton letter="A" selected>
                  Neuenberg Selected
                </AnswerButton>
                <AnswerButton letter="A" correct>
                  Neuenberg Correct
                </AnswerButton>
                <AnswerButton letter="A" incorrect>
                  Neuenberg Incorrect
                </AnswerButton>
              </Stack>
            </Box>

            {/* Logo */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Logo
            </Text>

            <Box backgroundColor="black" padding="xsmall">
              <Logo />
            </Box>

            {/* Card */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Card
            </Text>

            <Card>Card</Card>
            <Card backgroundColor="primary">Card</Card>

            {/* Avatars */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Avatars
            </Text>

            <Box overflow="auto">
              <Stack horizontal spacing="small" align="center">
                <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="small" />
                <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="medium" />
                <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="large" />
                <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="xlarge" />
              </Stack>
            </Box>

            {/* Dialog trigger */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Dialog
            </Text>

            <Button onClick={() => setDialogOpen(true)}>Open dialog</Button>

            {/* AudioSync */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              AudioSync
            </Text>

            <Box display="flex" justifyContent="center">
              <AudioSync syncing />
            </Box>

            {/* LogoProgress */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              LogoProgress
            </Text>

            <Box
              display="flex"
              justifyContent="center"
              style={{ backgroundColor: '#1F1168' }}
              paddingY="gutter"
            >
              <LogoProgress startTime={Date.now()} endTime={Date.now() + 30000} />
            </Box>

            {/* Stack + divider */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Stack + Divider
            </Text>

            <Stack spacing="xsmall" divider>
              <Text>Frank</Text>
              <Text>Elsi</Text>
              <Text>Pedro</Text>
            </Stack>

            {/* Tooltip */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Tooltip
            </Text>

            <Stack spacing="small" align="center">
              <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="medium" />
              <Tooltip>
                <Stack spacing="xsmall" horizontal align="center">
                  <Text fontSize="small" color="black">
                    Your avatar
                  </Text>
                  <Avatar src="https://api.adorable.io/avatars/285/abcde.png" size="small" />
                </Stack>
              </Tooltip>
            </Stack>

            {/* Icon */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Icon
            </Text>

            <Stack horizontal spacing="medium" align="center">
              <Icon name="apple" label="icon" size="xsmall" />
              <Icon name="apple" label="icon" size="small" />
              <Icon name="apple" label="icon" size="medium" />
              <Icon name="apple" label="icon" size="large" />
            </Stack>

            {/* Coin */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Coin
            </Text>

            <Stack horizontal spacing="medium" align="center">
              <Coin value={150} />
              <Coin size="large" value={150} />
              <Coin size="xlarge" value={150} />
            </Stack>

            {/* ProgressBar */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              ProgressBar
            </Text>

            <Stack spacing="xsmall">
              <Text fontSize="xsmall" color="darkGray">
                Default
              </Text>
              <ProgressBar value={25} />
              <Text fontSize="xsmall" color="darkGray">
                Primary color
              </Text>
              <ProgressBar value={50} color="primary" />
            </Stack>

            {/* Icons */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Icons
            </Text>

            <Box overflow="auto">
              <Stack spacing="xsmall" horizontal>
                {Object.keys(ICON_MAP).map((key) => (
                  <Stack key={key} spacing="small" align="center">
                    <Icon name={key as keyof typeof ICON_MAP} label={key} size="large" />
                    <Text color="darkGray" textAlign="center">
                      {key}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </Box>

            {/* Pie chart */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Pie chart
            </Text>

            <Box overflow="auto">
              <PieChart values={[0.6, 0.4]} />
            </Box>

            {/* Sound */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Sound
            </Text>

            <Box overflow="auto">
              <Button onClick={sound.play}>Play sound</Button>
            </Box>

            {/* Dropdown */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Form elements
            </Text>

            <Form
              onSubmit={(data) => console.log(data)}
              render={() => (
                <Stack spacing="small">
                  <Box display="flex" justifyContent="center">
                    <AvatarSelect name="avatar" />
                  </Box>

                  <TextField name="text" label="Label" placeholder="Placeholder" />

                  <Dropdown label="Pick a letter" name="dropdown" placeholder="Placeholder">
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                    <option>D</option>
                  </Dropdown>

                  <Stack spacing="small" horizontal>
                    <RadioButton name="radio" value="male" label="Male" />
                    <RadioButton name="radio" value="female" label="Female" />
                  </Stack>

                  <Stack spacing="small" horizontal align="center">
                    <Checkbox name="checkbox_1" checked />
                    <Text color="black" fontSize="small">
                      A
                    </Text>
                  </Stack>
                  <Stack spacing="small" horizontal align="center">
                    <Checkbox name="checkbox_2" />
                    <Text color="black" fontSize="small">
                      B
                    </Text>
                  </Stack>

                  <Box display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Box>
                </Stack>
              )}
            />

            {/* CategoryCard */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              Category cards
            </Text>

            <Stack spacing="small">
              <CategoryCard
                categoryId="1a"
                imageUrl="https://api.adorable.io/avatars/285/abcdt.png"
                title="Category A"
                points={150}
                totalPoints={750}
              />
              <CategoryCard
                categoryId="1b"
                imageUrl="https://api.adorable.io/avatars/285/abcdz.png"
                title="Category B"
                points={300}
                percentage={50}
              />
            </Stack>

            {/* ToggleButton */}
            <Text fontSize="large" fontWeight="medium" color="secondary">
              ToggleButton
            </Text>

            <Box backgroundColor="black">
              <Stack spacing="small" paddingX="small" paddingBottom="small">
                <ToggleButton checked icon={<Icon name="arrow-down" color="white" size="medium" />}>
                  Show
                </ToggleButton>
                <ToggleButton icon={<Icon name="arrow-down" color="white" size="medium" />}>
                  Region
                </ToggleButton>
                <ToggleButton>Frauen</ToggleButton>
                <ToggleButton icon={<Icon name="close" color="white" size="medium" />}>
                  Frauen
                </ToggleButton>
              </Stack>
            </Box>
          </Stack>

          {/* Share */}

          <ShareButton
            message={
              'I scored 100 points in Quizmaster! Download the app now and become the next Quizmaster!'
            }
          />

          {/* Dialog */}
          <Dialog
            open={dialogOpen}
            onRequestClose={() => setDialogOpen(false)}
            render={() => (
              <Stack spacing="large">
                <Text color="secondary" fontSize="large" fontWeight="medium" textAlign="center">
                  Dialog
                </Text>

                <Stack spacing="xsmall" align="center">
                  <Button>Foto maschen</Button>
                  <FlatButton onClick={() => setDialogOpen(false)}>Abbrechen</FlatButton>
                </Stack>
              </Stack>
            )}
          />
        </Box>
      </Panel>
    </>
  );
};

export default StyleGuideScreen;
