import { clearEpisode, updateBlock, updateEpisode } from 'actions/episodeActions';
import { recalculateTiming } from 'actions/episodeScheduleActions';
import { setEvent } from 'actions/eventActions';
import { leaveGame } from 'actions/gameActions';
import { setOverlay } from 'actions/overlayActions';
// 'Long poll' adapter
import { api } from 'api/pttv';
import { AppState } from 'reducers';
import { Store } from 'redux';
import { getEpisodeCode, getEpisodeVersion, isEpisodeClosed } from 'selectors/episodeSelectors';
import { getOverlayData } from 'selectors/overlaySelectors';
import { getPttvSession, getSessionValidationState } from 'selectors/userSelectors';
import { QzmCurrentEpisode } from 'types/api/episodeApi';
// import { TalpaEpisodePollState } from 'types/api/pollApi';

export type PushEvent = {
  id: string;
  type: string;
  [key: string]: any;
};

type State = {
  started: boolean;
  startedAt: number;
  episodeVersion: number;
  timingVersion: number | undefined;
};

export function connectLongpoll(store: Store<AppState, any>) {
  const state: State = {
    started: false,
    startedAt: -1,
    episodeVersion: 0,
    timingVersion: undefined,
  };

  const fetch = () => {
    if (!state.started) {
      return;
    }

    const startedAt = Date.now();

    state.startedAt = startedAt;

    const storeState = store.getState();
    const episodeCode = getEpisodeCode(storeState);
    const requestedSession = getPttvSession(storeState);

    if (!episodeCode) {
      return;
    }

    api
      .get<QzmCurrentEpisode>(`talpa/episodes/${episodeCode}/pollstate/${state.episodeVersion}`)
      .then(
        (response) => {
          if (!state.started) {
            return;
          }

          const storeState = store.getState();
          const episodeVersion = getEpisodeVersion(storeState);
          const isAuthenticated = getSessionValidationState(storeState) === 'VALIDATED';
          const session = getPttvSession(storeState);
          const prevOverlay = getOverlayData(storeState);

          if (requestedSession !== session) {
            console.log('Ignore longpoll response, other session');
            // Yeey with this backend session hell / inception :the_horns:
            setTimeout(fetch, 5000);
            return;
          }

          // Do not handle request from previous session
          if (!isAuthenticated || startedAt < state.startedAt) {
            return;
          }

          const { overlay, event, timingVersion, ...episode } = response;

          if (timingVersion !== state.timingVersion) {
            if (state.timingVersion) {
              try {
                store.dispatch(recalculateTiming());
              } catch (error) {
                console.log(error);
              }
            }
            state.timingVersion = response.timingVersion;
          }

          // leaderboardPerCountry isn't versioned
          if (response.leaderboardDone) {
            store.dispatch(updateEpisode({ leaderboardPerCountry: episode.leaderboardPerCountry }));
          }

          if (response.version !== episodeVersion) {
            if (event) {
              store.dispatch(setEvent(event));
            }

            if (overlay && overlay.version > prevOverlay.version) {
              store.dispatch(setOverlay(overlay));
            }

            // Reset the episode when CLOSED or deleted.
            if (response.version === 0 || response.state === 'CLOSED') {
              store.dispatch(leaveGame());
              store.dispatch(clearEpisode());
            } else {
              if (episode.block) {
                // Response can be total episode or block it seems.
                store.dispatch(updateBlock(episode.block));
              } else {
                // Update the episode with poll state data.
                store.dispatch(updateEpisode(episode));
              }
            }
            // Leave the game when the episode closes.
            if (!isEpisodeClosed(storeState) && response.state === 'CLOSED') {
              store.dispatch(leaveGame());
              store.dispatch(clearEpisode());
            }
          }

          state.episodeVersion = response.version;
          fetch();
        },
        (error) => {
          if (error.statusCode === 463) {
            // Account is already logged in to another device.
            // TODO logout user on this instance & redirect to /

            __DEV__ && console.log('pollState(): rejected with 463, giving up', error);
            return;
          }

          __DEV__ && console.warn('Received an error. Retrying in 5 seconds', error);

          setTimeout(fetch, 5000);
        },
      );
  };

  const start = () => {
    if (state.started) {
      return;
    }

    state.started = true;

    fetch();
  };

  const stop = () => {
    state.started = false;
  };

  //
  store.subscribe(() => {
    const state = store.getState();
    const isReady = getSessionValidationState(state) === 'VALIDATED';
    const episodeCode = getEpisodeCode(state);
    const episodeVersion = getEpisodeVersion(state);
    const { suspended } = { suspended: false }; //state.native;

    if (episodeCode && episodeVersion && isReady && !suspended) {
      start();
    } else {
      stop();
    }
  });
}
