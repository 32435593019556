import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionNames } from 'types/user';

interface Props {
  regionName: RegionNames;
}

const RegionTranslate: FC<Props> = ({ regionName }) => {
  const { t } = useTranslation();

  const REGION_MAP = {
    vienna: t('Vienna'),
    lowerAustria: t('Lower Austria'),
    upperAustria: t('Upper Austria'),
    styria: t('Styria'),
    tyrol: t('Tyrol'),
    carinthia: t('Carinthia'),
    salzburg: t('Salzburg'),
    vorarlberg: t('Vorarlberg'),
    burgenland: t('Burgenland'),
  };

  if (!(regionName in REGION_MAP)) {
    throw new Error(`Region Name "${regionName}" not found`);
  }

  return <>{REGION_MAP[regionName]}</>;
};

export default RegionTranslate;
