import { getPttvSession } from 'selectors/userSelectors';
import { QzmScoreUsers } from 'types/api/episodeApi';
import { HasFacebookPermission, SocialSignInState } from 'types/social';
import { graphFacebook, signInFacebook, signInGoogle, signOutGoogle } from './bridgeActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';
import { setStatisticsEpisodeLoaderboard } from './statisticsActions';

// ------ Sign In Calls ------

export const signInSocial =
  ({ socialType }: { socialType: SocialProviders }): ThunkAction<void> =>
  async (dispatch) => {
    const socialAdapter = {
      facebook: facebookSignIn,
      google: googleSignIn,
      'apple-id': appleSignIn,
    };

    const socialSignIn = () => {
      if (socialType !== 'apple-id') dispatch(setSocialTriggered(socialType));
      dispatch(socialAdapter[socialType]());
    };

    dispatch(signInSocialType(socialType));
    socialSignIn();
  };

export const googleSignIn = (): ThunkAction<void> => async (dispatch) => {
  const clientId = window.emconfig.settings.googleClientId;
  await dispatch(signInGoogle({ clientId }));
};

export const googleSignOut = (): ThunkAction<void> => async (dispatch) => {
  const clientId = window.emconfig.settings.googleClientId;
  await dispatch(signOutGoogle({ clientId }));
};

export const appleSignIn = (): ThunkAction<void> => async (dispatch, getState) => {
  const session = getPttvSession(getState());
  const { clientId, redirectUri, redirectState } = window.emconfig.settings.apple;

  const urlParams = [];
  urlParams.push(`client_id=${clientId}`);
  urlParams.push('response_type=code');
  urlParams.push(`redirect_uri=${redirectUri}`);
  urlParams.push('scope=name email');
  urlParams.push('response_mode=form_post');
  urlParams.push(`state=${btoa(JSON.stringify({ session, redirect: redirectState }))}`);

  window.location.href = encodeURI(
    `https://appleid.apple.com/auth/authorize?${urlParams.join('&')}`,
  );
};

interface SignInSocialResponse {
  userId?: string;
  token?: string;
  type?: SocialProviders;
}

// ------ Facebook -------

export const facebookSignIn =
  (interactive = false): ThunkAction<void> =>
  async (dispatch) =>
    dispatch(
      signInFacebook({
        interactive,
        permissions: ['public_profile', 'user_friends', 'email'],
      }),
    );

export const getFacebookUserGraph = (): ThunkAction<void> => (dispatch) => {
  dispatch(setSocialSignInState('GET_GRAPH'));
  dispatch(graphFacebook('first_name, last_name'));
};

// Check permissions data returned by Facebook
export const hasFacebookPermission = ({ permissions, permission }: HasFacebookPermission) =>
  permissions.data.find(
    (permissionData) =>
      permissionData.permission === permission && permissionData.status === 'granted',
  )
    ? true
    : false;

export const getFriendIds =
  (episodeCode: string): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    if (!episodeCode) {
      return;
    }

    dispatch(storeFriendEpisode(episodeCode));
    return dispatch(graphFacebook('friends, permissions'));
  };

export const setFriendScores =
  (): ThunkAction<Promise<any>> =>
  async (dispatch, getState, { api }) => {
    const {
      social: { friendScoreEpisode, friendIds },
    } = getState();

    if (!friendScoreEpisode || !friendIds || friendIds.length === 0) {
      return;
    }

    const friendScores = await api.get<QzmScoreUsers>(
      `qzm/leaderboard/${friendScoreEpisode}/users?gigyaUids=${friendIds?.join(',') || ''}`,
    );

    let records = friendScores.entries || [];

    // Filter out friends without scores (but leave friends with score 0)
    records = records.filter((record) => typeof record.score === 'number');

    // Sort them descending in case server does not do that already
    records.sort((a, b) => {
      if (a.score < b.score) {
        return 1;
      }

      if (a.score > b.score) {
        return -1;
      }

      return 0;
    });

    // Add a friend rank property
    records.forEach((record, i) => {
      record.rank = i + 1;
    });

    dispatch(
      setStatisticsEpisodeLoaderboard({
        leaderboard: {
          entries: records,
          episodeCode: friendScoreEpisode,
        },
      }),
    );
  };

// ------ Facebook ------- end

export const setExternalUserId = (id: string) =>
  createActionWithPayload('@social/SET_EXTERNAL_USER_ID', id);

export const setSocialSignInState = (state: SocialSignInState) =>
  createActionWithPayload('@social/SET_SOCIAL_SIGN_IN_STATE', state);

export const storeFriendIds = (friendIds: string[]) =>
  createActionWithPayload('@social/STORE_FRIEND_IDS', friendIds);

export const storeFriendEpisode = (friendEpisode: string) =>
  createActionWithPayload('@social/STORE_FRIEND_EPISODE', friendEpisode);

export const clearSocialState = () => createAction('@social/CLEAR');

export const signInSocialType = (payload: SocialProviders) =>
  createActionWithPayload('@social/ADD_SIGN_IN_TYPE', payload);

export const signInSocialResponse = (payload: SignInSocialResponse) =>
  createActionWithPayload('@social/ADD_SIGN_IN_RESPONSE', payload);

export const setSocialTriggered = (payload: SocialProviders | null) =>
  createActionWithPayload('@social/SET_SOCIAL_TRIGGERED', payload);

export const setSocialErrored = (payload: string | null) =>
  createActionWithPayload('@social/SET_SOCIAL_ERRORED', payload);

export const waitForApple = (payload: boolean) =>
  createActionWithPayload('@social/WAIT_FOR_APPLE', payload);

export const setIsVerifying = (payload: boolean) =>
  createActionWithPayload('@social/SET_IS_VERIFYING', payload);

export type SocialActions = ReturnType<
  | typeof signInSocialType
  | typeof setExternalUserId
  | typeof setSocialSignInState
  | typeof signInSocialResponse
  | typeof storeFriendIds
  | typeof storeFriendEpisode
  | typeof clearSocialState
  | typeof setSocialTriggered
  | typeof setSocialErrored
  | typeof waitForApple
  | typeof setIsVerifying
>;
