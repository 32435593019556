import { getStoredProfile } from 'selectors/userSelectors';
import { Genders, Profile, ProfilePrivateProperties } from 'types/user';
import { ThunkAction } from './helpers';
import { storeUserProfile } from './userActions';

export interface CreateProfilePayload {
  ageGroup?: AgeGroups;
  firstName?: string;
  gender?: Genders;
  location?: RegionNamesInGerman;
}

export interface GetProfileResponse {
  userId: string;
  firstName?: string;
  gender?: Genders;
  location?: RegionNamesInGerman;
  ageGroup?: AgeGroups;
  participatedEpisodes: any[];
}

const getCreateProfileFields: (profile: Profile) => CreateProfilePayload = (profile) => ({
  ageGroup: profile.ageGroup,
  firstName: profile.firstName,
  gender: profile.gender,
  location: profile.location,
});

export const createProfile =
  (profile: ProfilePrivateProperties): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    try {
      const appState = getState();
      const storedProfile = getStoredProfile(appState);
      const profileFields = getCreateProfileFields(profile);
      const storedFields = getCreateProfileFields(storedProfile);

      await api.post('talpa/users', {
        ...storedFields,
        ...profileFields,
      });

      await dispatch(getProfile());
    } catch (error) {
      console.log('err', error);
    }
  };

export const getProfile =
  (): ThunkAction<Promise<GetProfileResponse | undefined>> =>
  async (dispatch, getState, { api }) => {
    try {
      const response = await api.get<GetProfileResponse>('talpa/users', true);

      dispatch(storeUserProfile(response));

      return response;
    } catch (error) {
      console.log('err', error);
    }

    return;
  };
