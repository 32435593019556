import { useLayoutEffect, useRef } from 'react';

// Disable scroll when animating (improves fps)
export const usePanelScroll = (scrollEnabled: boolean, duration = 420) => {
  const timer = useRef(-1);

  useLayoutEffect(() => {
    const panels = document.querySelectorAll<HTMLDivElement>('.Panel-content');

    clearTimeout(timer.current);

    if (!scrollEnabled) {
      timer.current = window.setTimeout(() => {
        panels.forEach((panel) => {
          panel.style.overflow = '';
        });
      }, duration);
    } else {
      panels.forEach((panel) => {
        panel.style.overflow = 'hidden';
      });
    }
  }, [scrollEnabled]);
};
