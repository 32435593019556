import { AppState } from 'reducers';

export const getSocialType = (state: AppState) => state.social.type;
export const isFacebookUser = (state: AppState) => getSocialType(state) === 'facebook';
export const isGoogleUser = (state: AppState) => getSocialType(state) === 'google';
export const isAppleUser = (state: AppState) => getSocialType(state) === 'apple-id';
export const isEmailUser = (state: AppState) => !getSocialType(state);
export const getFacebookFriendIds = (state: AppState) => state.social.friendIds || [];
export const getSocialTriggered = (state: AppState) => state.social.socialTriggered;
export const getSocialErrored = (state: AppState) => state.social.socialErrored;
export const getWaitForApple = (state: AppState) => state.social.waitForApple;
export const getIsVerifying = (state: AppState) => state.social.isVerifying;
