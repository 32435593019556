import Dropdown from 'components/Dropdown';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
}

const RegionDropdown: FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      required
      name="location"
      label={t('Region')}
      placeholder={t('Select a Region')}
      errorMessages={{
        required: t('REQUIRED'),
      }}
      value={value}
    >
      <option value="Burgenland">Burgenland</option>
      <option value="Kärnten">Kärnten</option>
      <option value="Niederösterreich">Niederösterreich</option>
      <option value="Oberösterreich">Oberösterreich</option>
      <option value="Wien">Wien</option>
      <option value="Salzburg">Salzburg</option>
      <option value="Steiermark">Steiermark</option>
      <option value="Tirol">Tirol</option>
      <option value="Vorarlberg">Vorarlberg</option>
    </Dropdown>
  );
};

export default RegionDropdown;
