import { ThunkAction } from './helpers';
import { updateProfile } from './usersApiActions';

interface JsUploadPayload {
  mimeType: string;
  data: string;
}

interface JsUploadResponse {
  url: string;
}

export const jsUpload =
  (payload: JsUploadPayload): ThunkAction<Promise<void>> =>
  async (dispatch, getState, { api }) => {
    try {
      const response = await api.post<JsUploadResponse, JsUploadPayload>(
        'inter/file/jsupload',
        payload,
      );

      if (response.url) {
        dispatch(updateProfile({ avatarUrl: response.url }));
      }
    } catch (error) {
      throw error;
    }
  };
